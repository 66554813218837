import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    valueInput: {

    },
    colon: {
        display: 'inline-block',
        padding: '8px',
    },
    labelInput: {

    },
});

interface LabeledEnumOptionInputProps {
    value: any;
    onChange: (option: any) => void;
    onDelete: () => void;
}

const LabeledEnumOptionInput: React.FC<LabeledEnumOptionInputProps> = props => {
    const { value, onChange, onDelete } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div>
            <TextField
                className={classes.valueInput}
                placeholder={translate('general.value')}
                value={value.value}
                onChange={(e: any) => onChange({ ...value, value: e.target.value })}
            />
            <div className={classes.colon}>:</div>
            <TextField
                className={classes.labelInput}
                placeholder={translate('general.label')}
                value={value.label}
                onChange={(e: any) => onChange({ ...value, label: e.target.value })}
            />
            <Button onClick={() => onDelete()}>
                <DeleteIcon/>
            </Button>
        </div>
    );
};

interface LabeledEnumInputProps {
    value?: any[];
    onChange?: (options: any[]) => void;
    label?: string;
    style?: React.CSSProperties;
}

export const LabeledEnumInput: React.FC<LabeledEnumInputProps> = props => {
    const { value: defaultValue, onChange, label, style } = props;
    const classes = useStyles();
    const [options, setOptions] = useState<any[]>(defaultValue || [{value: '', label: ''}]);

    const updateOptions = (newOptions: any[]) => {
        onChange(newOptions);
        setOptions(newOptions);
    };

    const addOption = () => {
        updateOptions([
            ...options,
            { value: '', label: '' },
        ]);
    };

    const deleteOption = (index: number) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        updateOptions(newOptions);
    };

    const changeOption = (index: number, value: any) => {
        const newOptions = [...options];
        newOptions.splice(index, 1, value);
        updateOptions(newOptions);
    };

    return (
        <div style={style}>
            {
                options.map((option: any, index: number) => {
                    return (
                        <LabeledEnumOptionInput
                            key={index}
                            value={option}
                            onChange={newValue => changeOption(index, newValue)}
                            onDelete={() => deleteOption(index)}
                        />
                    );
                })
            }
            <Button onClick={() => addOption()}><AddIcon/>Add</Button>
        </div>
    );
};