import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import PieChart from '../../../reusable-components/charts/PieChart';
import ChartContainer from './ChartContainer';
import { alarmSeverityColors } from './constants/constants';

interface AlarmsCountBySeverityHistoryPieChartProps {
    label?: string;
    data: any;
		locale?: string;
}


const AlarmsCountBySeverityHistoryPieChart = (props: AlarmsCountBySeverityHistoryPieChartProps) => {
    const { label, data } = props;
    const translate = useTranslate();
    const locale = useLocale();
    const labels = [
        translate('alarm.severity.info'),
        translate('alarm.severity.minor'),
        translate('alarm.severity.major'),
        translate('alarm.severity.critical'),
    ];

    return (<ChartContainer label={label}>
        {data && (
            <PieChart
                label={translate('alarm.alarmSeverities')}
                labels={labels}
                backgroundColor={alarmSeverityColors}
                data={[data.info, data.minor, data.major, data.critical]}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default AlarmsCountBySeverityHistoryPieChart;