/* eslint-disable @typescript-eslint/no-empty-function */
import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { default as MaterialTextField } from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React, { useCallback, useEffect, useState } from 'react';
/**
 * react-admin wrapper
 */
import { useInput, useTranslate } from 'react-admin';
import { Control, Controller, useFieldArray, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
// NX-TODO: import { AttributeSpecification, LabeledEnumOption } from '../../../backend/src/model/attribute-specification';
import { EnumInput } from './EnumInput';
import { LabeledEnumInput } from './LabeledEnumInput';


const useStyles = makeStyles((theme: Theme) => ({
    editRow: {
        '& > td': {
            borderBottom: 'none',
        },
    },
    detailsInput: {
        marginRight: '10px',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    editRowCell: {
        paddingTop: 0,
    },
}));


interface AttributeDefinitionsTableRowDetailsProps {
    name: string;
    register: UseFormRegister<any>;
    value: any;
    control: Control<any>;
}

const AttributeDefinitionsTableRowDetailsNumber = (props: AttributeDefinitionsTableRowDetailsProps) => {
    const { name, register } = props;
    
    const translate = useTranslate();
    const classes = useStyles();

    return <div>
        <MaterialTextField
            {...register(`${name}.defaultValue`)}
            label={translate('attributeTable.defaultValue')}
            type='number'
            className={classes.detailsInput}
        />
        <MaterialTextField
            {...register(`${name}.min`)}
            label='Min'
            type='number'
            className={classes.detailsInput}
        />
        <MaterialTextField
            {...register(`${name}.max`)}
            label='Max'
            type='number'
            className={classes.detailsInput}
        />
    </div>;
};

const AttributeDefinitionsTableRowDetailsString = (props: AttributeDefinitionsTableRowDetailsProps) => {
    const { name, register } = props;
    const translate = useTranslate();

    return <MaterialTextField
        {...register(`${name}.defaultValue`)}
        label={translate('attributeTable.defaultValue')}
    />;
};

const AttributeDefinitionsTableRowDetailsBoolean = (props: AttributeDefinitionsTableRowDetailsProps) => {
    const { name, register } = props;

    return <Switch {...register(`${name}.defaultValue`)} />;
};

const AttributeDefinitionsTableRowDetailsEnum = (props: AttributeDefinitionsTableRowDetailsProps) => {
    const { name, register, value, control } = props;

    const translate = useTranslate();

    const selectId = `default-value-select${Math.random()}`;

    return <div>
        <Controller
            render={({ field }) => (<EnumInput
                {...field}
                label={translate('attributeTable.options')}
                style={{display: 'block'}}
            />)}
            name={`${name}.options`}
            control={control}
        />
        
        <FormControl style={{display: 'block', marginTop: '10px'}}>
            <InputLabel htmlFor={selectId}>{translate('attributeTable.defaultValue')}</InputLabel>
            <Select
                {...register(`${name}.defaultValue`)}
                label={translate('attributeTable.defaultValue')}
                inputProps={{
                    id: selectId
                }}
            >
                <MenuItem key={''} value={''}>
                    <em>-</em>
                </MenuItem>
                {
                    (value.options ?? []).map((option: string) => <MenuItem key={option} value={option}>{option}</MenuItem>)
                }
            </Select>
      </FormControl>
    </div>;
};

const AttributeDefinitionsTableRowDetailsLabeledEnum = (props: AttributeDefinitionsTableRowDetailsProps) => {
    const { name, register, value, control } = props;

    const translate = useTranslate();

    const selectId = `default-value-select${Math.random()}`;

    return <div>
        <Controller
            render={({ field }) => (<LabeledEnumInput
                {...field}
                label={translate('attributeTable.options')}
                style={{display: 'block'}}
            />)}
            name={`${name}.options`}
            control={control}
        />
        <FormControl style={{display: 'block', marginTop: '10px'}}>
            <InputLabel htmlFor={selectId}>{translate('attributeTable.defaultValue')}</InputLabel>
            <Select
                {...register(`${name}.defaultValue`)}
                label={translate('attributeTable.defaultValue')}
                inputProps={{
                    id: selectId
                }}
            >
                <MenuItem key={''} value={''}>
                    <em>-</em>
                </MenuItem>
                {
                    (value.options ?? []).map((option: any) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
                }
            </Select>
      </FormControl>
    </div>;
};

interface AttributeDefinitionsTableRowProps {
    name: string;
    register: UseFormRegister<any>;
    control: Control<any>
    value: any;
    onDelete: () => void;
    readonly?: boolean;
    showMandatory?: boolean;
    showDeviceAttribute?: boolean;
}

const AttributeDefinitionsTableRow: React.FC<AttributeDefinitionsTableRowProps> = props => {
    const { name, register, control, value, onDelete, readonly, showMandatory, showDeviceAttribute } = props;

    const translate = useTranslate();
    const classes = useStyles();
    const [editing, setEditing] = useState(!value?.name && !readonly);

    return <>
        <TableRow className={ editing ? classes.editRow : null } hover={true}>
            <TableCell>
            {
                editing
                ? <MaterialTextField {...register(`${name}.name`)} placeholder={translate('general.name')} />
                : value?.name
            }
            </TableCell>
            <TableCell>
            {
                editing
                ? <MaterialTextField {...register(`${name}.displayName`)} placeholder={translate('attributeTable.displayName')} />
                : value?.displayName
            }
            </TableCell>
            <TableCell>
            {
                editing
                ? <Controller
                    control={control}
                    name={`${name}.type`}
                    render={({ field }) => (<Select {...field}>
                        <MenuItem value='number'>{translate('attributeTable.attributeType.number')}</MenuItem>
                        <MenuItem value='string'>{translate('attributeTable.attributeType.string')}</MenuItem>
                        <MenuItem value='boolean'>{translate('attributeTable.attributeType.boolean')}</MenuItem>
                        <MenuItem value='enum'>{translate('attributeTable.attributeType.enum')}</MenuItem>
                        <MenuItem value='labeled_enum'>{translate('attributeTable.attributeType.labeledEnum')}</MenuItem>
                    </Select>)}
                />
                : value?.type
            }
            </TableCell>
            <TableCell>
                <Controller
                  control={control}
                  name={`${name}.readOnly`}
                  render={({ field: { onChange, ref, value } }) => (
                    <Switch
                      inputRef={ref}
                      checked={value}
                      onChange={onChange}
                      disabled={!editing}
                    />
                  )}
                />
            </TableCell>
            {
            showMandatory && <TableCell>
                <Controller
                  control={control}
                  name={`${name}.mandatory`}
                  render={({ field: { onChange, ref, value } }) => (
                    <Switch
                      inputRef={ref}
                      checked={value}
                      onChange={onChange}
                      disabled={!editing}
                    />
                  )}
                />
            </TableCell>
            }
            {
            showDeviceAttribute && <TableCell>
                <Switch {...register(`${name}.deviceAttribute`)} disabled={!editing} />
            </TableCell>
            }
            {
                !readonly && <TableCell>
                    <Button
                        startIcon={editing ? <SaveIcon/> : <EditIcon/>}
                        size="small"
                        color="primary"
                        onClick={() => {
                            if (editing) {
                                // formChanged();
                            }
                            setEditing(!editing);
                        }}>
                        { editing ? translate('ra.action.save') : translate('ra.action.edit') }
                    </Button>
                </TableCell>
            }
            {
                !readonly &&  <TableCell>
                    <Button
                        startIcon={<DeleteIcon/>}
                        size="small"
                        className={classes.deleteButton}
                        onClick={onDelete}>
                        Delete
                    </Button>
                </TableCell>
            }
        </TableRow>
        {
            editing
            ? <TableRow>
                <TableCell colSpan={100} className={classes.editRowCell}>
                { value?.type === 'number' && <AttributeDefinitionsTableRowDetailsNumber
                    register={register}
                    name={name}
                    value={value}
                    control={control} /> }
                { value?.type === 'string' && <AttributeDefinitionsTableRowDetailsString
                    register={register}
                    name={name}
                    value={value}
                    control={control} /> }
                { value?.type === 'boolean' && <AttributeDefinitionsTableRowDetailsBoolean
                    register={register}
                    name={name}
                    value={value}
                    control={control} /> }
                { value?.type === 'enum' && <AttributeDefinitionsTableRowDetailsEnum
                    register={register}
                    name={name}
                    value={value}
                    control={control} /> }
                { value?.type === 'labeled_enum' && <AttributeDefinitionsTableRowDetailsLabeledEnum
                    register={register}
                    name={name}
                    value={value}
                    control={control} /> }
                </TableCell>
            </TableRow>
            : null
        }
    </>;
};


interface AttributeDefinitionsTableProps {
    defaultValue: any[];
    onChange: (a: any[]) => void;
    readonly?: boolean;
    showMandatory?: boolean;
    showDeviceAttribute?: boolean;
}

const AttributeDefinitionsTable = (props: AttributeDefinitionsTableProps) => {

    const { defaultValue, onChange, readonly, showMandatory, showDeviceAttribute } = props;
    
    const { control, watch, register, getValues } = useForm({
        defaultValues: { rows: defaultValue }
    });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: 'rows',
    });
    const [value, setValue] = useState(defaultValue);  // HACK: react-hook-form watch is terrible so we have to use value
    
    const translate = useTranslate();

	const createAttribute = useCallback((): any => {
        const attribute = {
            name: '',
            displayName: '',
            type: 'number',
            readOnly: false,
            defaultValue: '',
        } as any;
        if (showMandatory) attribute.mandatory = false;
        if (showDeviceAttribute) attribute.deviceAttribute = false;

        return attribute;
	}, [showMandatory, showDeviceAttribute]);

    useEffect(() => {
        const rows = getValues().rows;
        onChange(rows);
        setValue(rows);
    }, [watch('rows')]);

    const addAttribute = () => {
        // TODO: add flag for not saved attribute
        append(createAttribute());
    };

    const deleteAttribute = (index: number) => {
        remove(index);
    };

	return (
		<div>
			<TableContainer component={Paper} elevation={0}>
				<Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('general.name')}</TableCell>
                            <TableCell>{translate('attributeTable.displayName')}</TableCell>
                            <TableCell>{translate('attributeTable.attributeType')}</TableCell>
                            <TableCell>{translate('general.readonly')}</TableCell>
                            { showMandatory && <TableCell>{translate('attributeTable.mandatory')}</TableCell> }
                            { showDeviceAttribute && <TableCell>{translate('attributeTable.deviceAttribute')}</TableCell> }
                            { !readonly && <TableCell></TableCell> }
                            { !readonly && <TableCell></TableCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, index) => {
                            return <AttributeDefinitionsTableRow
                                key={field.id}
                                name={`rows.${index}`}
                                control={control}
                                register={register}
                                value={value[index]}
                                onDelete={() => deleteAttribute(index)}
                                readonly={readonly}
                                showMandatory={showMandatory}
                                showDeviceAttribute={showDeviceAttribute}
                            />;
                        })}
                    </TableBody>
				</Table>
			</TableContainer>
            {
                !readonly && <Button onClick={addAttribute} style={{marginTop: '10px'}}>
                    <AddIcon/> {translate('attributeTable.addAttribute')}
                </Button>
            }
		</div>
	);
};


interface AttributeDefinitionsTableRaProps {
    source: string;
    record?: { attributeDefinitions: any[] };
    showMandatory?: boolean;
    showDeviceAttribute?: boolean;
}

export const AttributeDefinitionsTableRa = (props: AttributeDefinitionsTableRaProps) => {
    const { showMandatory, showDeviceAttribute } = props;
    const { 
        input: { value, onChange },
    } = useInput(props);

    return (
        <AttributeDefinitionsTable
            defaultValue={value || []}
            onChange={onChange}
            showMandatory={showMandatory}
            showDeviceAttribute={showDeviceAttribute}
        />
    );
};

export const AttributeDefinitionsTableRaField = (props: AttributeDefinitionsTableRaProps) => {
    const { record, showMandatory, showDeviceAttribute } = props;
    const { attributeDefinitions: value } = record;
    return (
        <AttributeDefinitionsTable
            defaultValue={value || []}
            onChange={() => {}}
            readonly={true}
            showMandatory={showMandatory}
            showDeviceAttribute={showDeviceAttribute}
        />
    );
};