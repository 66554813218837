import { useState, useEffect } from 'react';
import { DateRangeOutput } from '../../reusable-components/DateRangePicker';
import axios from '../../api/axios-client';

type TimeSeriesData = Array<{
    t: string;
    y: number;
}>;

interface DeviceShowStats {
    firstSeen?: string | null;
    lastSeen?: string | null;
    avgMessagesPerDay?: number;

    messagesHistory?: TimeSeriesData;
    rssiHistory?: TimeSeriesData;
    snrHistory?: TimeSeriesData;
    alarmsCountHistory?: TimeSeriesData;
    alarmsCountBySeverityHistory?: {
        info: TimeSeriesData;
        minor: TimeSeriesData;
        major: TimeSeriesData;
        critical: TimeSeriesData;
    };
}

const useDeviceShowStats = (deviceId: string) => {
    const [dateRange, setDateRange] = useState<DateRangeOutput | undefined>(undefined);
    const [stats, setStats] = useState<DeviceShowStats>({});

    useEffect(() => {
        if (!dateRange || !deviceId) return;
        axios.get(
            `/api/device-stats/${deviceId}`,
            {
                params: {
                    from: dateRange?.dateRange.from.getTime(),
                    to: dateRange?.dateRange.to.getTime(),
                    resolution: dateRange?.timeResolution
                },
            },
        )
            .then(({data}: any) => {
                setStats(data);
            });
    }, [dateRange]);

    return {
        stats,
        dateRange,
        setDateRange,
    };
};

export default useDeviceShowStats;
