import React from 'react';
import { List, Create, Edit, Show, SimpleShowLayout, SimpleForm, Datagrid, Labeled, TextField, TextInput, DateField, ShowButton, EditButton, DeleteButton, Filter, required, useLocale, BulkDeleteButton, ReferenceInput, SelectInput } from 'react-admin';
import { AttributeDefinitionsTableRa, AttributeDefinitionsTableRaField } from '../reusable-components/AttributeDefinitionsTable';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { PaginationProps } from './helpers/paginationProps';
import { makeStyles } from '@material-ui/core';
import { ApiRoutes } from '../constants';
import { useHasRights, useIsOrg } from '../reusable-components/permissions-hooks';
import { useBlankReferenceFieldHelper } from './helpers/blank-reference-field-helper';
import { OrgField } from './helpers/resource-helper';

const title = <ResourceTitle translationKey='attributeSetDefinitions' />;

// ! not setting it on table because for some reason it doesn't work in Edit view
const useStyles = makeStyles({
	orgInput: {
		marginBottom: '20px',
	},
	createdAtInput: {
		marginTop: '20px',
	},
});

const AttributeSetDefinitionFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};


const AttributeSetDefinitionList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.AttributeSets);
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<AttributeSetDefinitionFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField source='name' label='general.name' />
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				{
					!isOrg &&
					<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};

const AttributeSetDefinitionCreate = (props: any) => {
	const isOrg = useIsOrg();
	const classes = useStyles();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Create {...props} title={title}>
			<SimpleForm redirect='show'>
				<TextInput source='name' label='general.name' validate={required()} />
				{
					!isOrg &&
					<ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						className={classes.orgInput}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<AttributeDefinitionsTableRa
					source='attributeDefinitions'
					showMandatory={true}
				/>
			</SimpleForm>
		</Create>
	);
};

const AttributeSetDefinitionShow = (props: any) => {
	const classes = useStyles();
	const locale = useLocale();
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField
					source='id'
					label='general.id'
				/>
				<TextField
					source='name'
					label='general.name'
				/>
				{
					!isOrg &&
					<Labeled label='resource.organization' className={classes.orgInput}>
						<OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
					</Labeled>
				}
				<AttributeDefinitionsTableRaField
					source='attributeDefinitions'
					showMandatory={true}
				/>
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} className={classes.createdAtInput} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
			</SimpleShowLayout>
		</Show>
	);
};

const AttributeSetDefinitionEdit = (props: any) => {
	const classes = useStyles();
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Edit {...props} title={title}>
			<SimpleForm>
				<TextInput
					source='name'
					label='general.name'
					validate={required()}
				/>
				{
					!isOrg &&
					<ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						className={classes.orgInput}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<AttributeDefinitionsTableRa
					source='attributeDefinitions'
					showMandatory={true}
				/>
			</SimpleForm>
		</Edit>
	);
};

export const attributeSetDefinitionViews = {
	list: AttributeSetDefinitionList,
	create: AttributeSetDefinitionCreate,
	show: AttributeSetDefinitionShow,
	edit: AttributeSetDefinitionEdit
};
