import React from 'react';
import { BulkDeleteButton, Datagrid, DateField, DeleteButton, Filter, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useLocale } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { ConditionalLink, OrgField } from '../helpers/resource-helper';
import { MessageType } from './messageType';

const title = <ResourceTitle translationKey='messages' />;

const MessageFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};


const RowDetails = ({ record }: any) => {
	return (
		<span>
			{typeof record.content === 'string' ? record.content : JSON.stringify(record.content, null, 2)}
		</span>
	);
};

const MessageList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Messages);
	const isOrg = useIsOrg();
	
	useBlankReferenceFieldHelper(ApiRoutes.Integration);
	useBlankReferenceFieldHelper(ApiRoutes.Device);
	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<MessageFilter />}
			title={title}
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid expand={<RowDetails />}>
				<ReferenceField label='resource.device' source='deviceId' reference='devices' link={ConditionalLink('devices')}>
					<TextField source='name' />
				</ReferenceField>
				<ReferenceField label='resource.integration' source='integrationId' reference='integrations' link={ConditionalLink('integrations')}>
					<TextField source='name' />
				</ReferenceField>
				<FunctionField
					source='type'
					label='message.type'
					render={(record: any) => <MessageType record={record} />}
				/>
				{
					!isOrg &&
					<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				{ writeRights && <DeleteButton undoable={false} /> }
			</Datagrid>
		</List>
	);
};

export const messageViews = {
	list: MessageList
};
