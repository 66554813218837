import { useTranslate } from 'react-admin';

interface ResourceTitleProps {
	translationKey: string;
	record?: any;
	property?: string;
}

const ResourceTitle = (props: ResourceTitleProps) => {
	const {record, translationKey} = props;
	const property = props.property || 'name';
	const translate = useTranslate();

	const translation = translate(`resource.${translationKey}`);

	if (record && record[property]) {
		return <span>{translation}: {record[property]}</span>;
	}

	return <span>{translation}</span>;
};

export default ResourceTitle;
