import { useEffect, useState } from 'react';
import { authProvider, Permissions } from '../authProvider';
import { ApiRoutes } from '../constants';

export const useHasRights = (resource: ApiRoutes) => {
	const permissions: Permissions = authProvider.getPermissionsNow();
	const [readRights, setReadRights] = useState(false);
	const [writeRights, setWriteRights] = useState(false);

	useEffect(() => {
		if (!permissions) return;
		setReadRights(permissions.hasReadRight(resource));
		setWriteRights(permissions.hasWriteRight(resource));
	}, [permissions]);

	return {
		readRights,
		writeRights,
	};
};

export const useIsOrg = () => {
	const permissions: Permissions = authProvider.getPermissionsNow();
	const [isOrg, setIsOrg] = useState(true);

	useEffect(() => {
		if (!permissions) return;
		setIsOrg(permissions.isOrg());
	}, [permissions]);

	return isOrg;
};

export const getUserRoleId = () => {
	const permissions: Permissions = authProvider.getPermissionsNow();
	return permissions.getUserRoleId();
};

export const getUserOrgId = () => {
	const permissions: Permissions = authProvider.getPermissionsNow();
	return permissions ? permissions.getOrgId() : '';
};