import React from 'react';
import usePlatformSettings from '../resources/platform-settings/usePlatformSettings';

const Logo = () => {
    const platformSettings = usePlatformSettings();

    if (!platformSettings.headerImage) return null;

    return <img
        src={platformSettings.headerImage}
        height={40}
    />;
};

export default Logo;