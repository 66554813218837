import { Card, CircularProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Axios from '../../../api/axios-client';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { authProvider } from '../../../authProvider';
import AlarmsCountBySeverityHistoryPieChart from '../../../resources/device/charts/AlarmsCountBySeverityHistoryPieChart';
import AlarmsCountByTypeChart from '../../../resources/device/charts/AlarmsCountByTypeChart';
import TimeSeriesSingleChart from '../../../reusable-components/charts/TimeSeriesSingleChart';
import DateRangePicker, { DateRangeOutput } from '../../../reusable-components/DateRangePicker';
import { OverviewCard } from '../../components/OverviewCard';

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
            padding: '10px 20px',
			color: theme.palette.text.primary,
		},
		sectionTitle: {
			display: 'flex',
			justifyContent: 'space-between',

			'& > span': {
				fontSize: '1.3rem',
				fontWeight: 200,
				textTransform: 'uppercase',
				marginBlockStart: '0.83em',
				marginBlockEnd: '0.83em',
			},
		},
		alarmChartsContainer: {
			display: 'flex',
			width: '100%',
			'& > *': {
				flexBasis: 1,
				flexGrow: 1,
			}
		},
	};
});

interface Props {
    dashboard: any;
    device: any;
}

export const SmartMeteringDeviceStatistics = (props: Props) => {
	const { dashboard, device } = props;
	const classes = useStyles();
	const translate = useTranslate();
	const [dateRange, setDateRange] = useState<DateRangeOutput | undefined>(undefined);
	const [alarmsData, setAlarmsData] = useState<any>({});
	const [consumptionData, setConsumptionData] = useState<any>({});
	const [loadingStats, setLoadingStats] = useState(true);

	useEffect(() => {
        if (!dateRange || !dashboard || !device) return;

		setLoadingStats(true);
		// stats
		Axios({
			method: 'GET',
			url: `/api/dashboard-info/metering/${dashboard.id}/device/${device.id}/stats`,
			headers: {
				Authorization: `Bearer ${authProvider.getAccessToken()}`
			},
			params: {
				from: dateRange.dateRange.from.getTime(),
				to: dateRange.dateRange.to.getTime(),
				resolution: dateRange.timeResolution,
			},
		})
			.then(({ data }: any) => {
				setConsumptionData(data);
				setLoadingStats(false);
			});

		// alarms
		Axios({
			method: 'GET',
			url: `/api/dashboard-info/metering/${dashboard.id}/device/${device.id}/alarms`,
			headers: {
				Authorization: `Bearer ${authProvider.getAccessToken()}`
			},
			params: {
				from: dateRange.dateRange.from.getTime(),
				to: dateRange.dateRange.to.getTime(),
				resolution: dateRange.timeResolution,
			},
		})
			.then(({ data }: any) => {
				setAlarmsData(data);
			});
	}, [dateRange, dashboard, device]);

	return (
		<div className={classes.container}>
			<Title title={dashboard.name} />
			<div className={classes.sectionTitle}>
				<span>{translate('smartMetering.consumption')}</span>
				<DateRangePicker
					label={translate('datePeriod.period')}
					onChange={value => setDateRange(value)}
					disableCustom={true}
				/>
			</div>
			{
				loadingStats ?
					<Card elevation={0} style={{ position: 'relative', height: 550, display: 'flex', justifyContent: 'center' }}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<CircularProgress />
						</div>
					</Card> // height hack to keep the same height
					:
					<>
						<OverviewCard stats={consumptionData} />
						<TimeSeriesSingleChart
							label={translate('smartMetering.consumptionHistory')}
							data={consumptionData?.data}
							timeResolution={dateRange?.timeResolution}
							fill={true}
						/>
					</>
			}
			<div className={classes.sectionTitle}>
				<span>{translate('resource.alarms')}</span>
			</div>
			<OverviewCard stats={alarmsData?.stats} />
			<TimeSeriesSingleChart
				label={translate('smartMetering.alarmsHistory')}
				data={alarmsData?.data}
				timeResolution={dateRange?.timeResolution}
				fill={true}
			/>
			<div className={classes.alarmChartsContainer}>
				<AlarmsCountBySeverityHistoryPieChart
					label={translate('alarm.alarmSeverities')}
					data={alarmsData?.severities}
				/>
				<AlarmsCountByTypeChart
					labels={alarmsData?.types && Object.entries(alarmsData.types).map(([label, data]: any) => label)}
					backgroundColors={'#53CBCB'}
					data={alarmsData?.types && Object.entries(alarmsData.types).map(([label, data]: any) => data)}
				/>
			</div>
		</div>
	);
};
