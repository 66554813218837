import { useEffect } from 'react';
import { useDataProvider } from 'react-admin';

export function useBlankReferenceFieldHelper(resource: string) {
	const dataProvider = useDataProvider();

	useEffect(() => {
		dataProvider.getList(resource, {sort: undefined, pagination: { page: 1, perPage: 10000 }, filter: {} });
	}, []);
}
