import { DeviceList } from './DeviceList';
import { DeviceCreate } from './DeviceCreate';
import { DeviceShow } from './DeviceShow';
import { DeviceEdit } from './DeviceEdit';

export const deviceViews = {
	list: DeviceList,
	create: DeviceCreate,
	show: DeviceShow,
	edit: DeviceEdit,
};