import { Divider, Drawer, Tab, Tabs, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useParams } from 'react-router';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ApiRoutes } from '../../../constants';
import { SmartMeteringDeviceDownlink } from './SmartMeteringDeviceDownlink';
import { SmartMeteringDeviceOverview } from './SmartMeteringDeviceOverview';
import { SmartMeteringDeviceStatistics } from './SmartMeteringDeviceStatistics';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            width: '1000px',
            height: '100%',
            overflow: 'auto',
        },
    };
});

type TabName = 'overview' | 'statistics' | 'downlink';

interface SmartMeteringExportTabProps {
    open: boolean;
    onClose: () => void;
    dashboard: any;
}

export const SmartMeteringDeviceDrawer = (props: SmartMeteringExportTabProps) => {
    const { deviceId } = useParams() as any;
    const { open, onClose, dashboard } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [device, setDevice] = useState<any>();
    const [activeTab, setActiveTab] = useState('');
    const history = useHistory();
    const match = useRouteMatch();

    const fetchDevice = useCallback(() => {
        if (!deviceId) return;
        dataProvider.getOne(ApiRoutes.Device, { id: deviceId })
            .then(({ data }: any) => {
                if (data) {
                    setDevice(data);
                }
            });
    }, [deviceId]);
    
    useEffect(() => {
        fetchDevice();
    }, [deviceId]);

    return (
        <Drawer anchor='right' open={!!device && open} onClose={onClose} variant='temporary'>
            {
                device ? (
                    <div className={classes.container}>
                        <Tabs
                            value={activeTab}
                            indicatorColor='primary'
                            onChange={(event, value) => {
                                if (value === '') {
                                    history.push(`${match.url}`);
                                } else {
                                    history.push(`${match.url}/${value}`);
                                }
                            }}
                        >
                            <Tab
                                label={translate('smenu.overview')}
                                value={``}
                            />
                            <Tab
                                label={translate('smartMetering.statistics')}
                                value={`statistics`}
                            />
                            <Tab
                                label={translate('device.downlink')}
                                value={`downlink`}
                            />
                        </Tabs>
                        <Divider />
                        <Switch>
                            <Route
                                path={`${match.path}/statistics`}
                                render={props => {
                                    if (activeTab !== 'statistics') setActiveTab('statistics');
                                    return <SmartMeteringDeviceStatistics device={device} dashboard={dashboard} />;
                                }}
                            />
                            <Route
                                path={`${match.path}/downlink`}
                                render={props => {
                                    if (activeTab !== 'downlink') setActiveTab('downlink');
                                    return <SmartMeteringDeviceDownlink device={device} />;
                                }}
                            />
                            <Route
                                path={`${match.path}`}
                                render={props => {
                                    if (activeTab !== '') setActiveTab('');
                                    return <SmartMeteringDeviceOverview
                                        device={device}
                                        dashboard={dashboard}
                                        displaySyncAttributesButton={!!dashboard.settings.attributesSyncUrl}
                                        refreshDevice={() => fetchDevice()}
                                    />;
                                }}
                            />
                        </Switch>
                    </div>
                ) : 
                null
            }
        </Drawer>
    );
};