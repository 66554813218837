import { AuthProvider } from 'ra-core';
import jwtDecode from 'jwt-decode';
import {API_URL} from "./_config";

/**
 * If `blacklist` is true `read` and `write` objects represent stuff to which user HAS NO rights.
 * Otherwise `read` and `write` objects represent stuff to which user HAS rights.
 */
export type UserRights = {
	blacklist: boolean;
	read: { [key: string]: boolean },
	write: { [key: string]: boolean }
}

export class Permissions {

	private decodedJwtToken: any; //ToDo expose some common directory for usage on front and backend and set the type for decodedJwtToken

	constructor(jwtToken: string) {
		this.decodedJwtToken = jwtDecode(jwtToken);
	}

	isOrg() {
		return this.decodedJwtToken.orgId === null ? false : true;
	}

	getOrgId() {
		return this.decodedJwtToken.orgId;
	}

	getUserRoleId() {
		return this.decodedJwtToken.userRole.id;
	}

	getUserId() {
		return this.decodedJwtToken.id;
	}

	hasReadRight(resource: string) {
		const userRights: UserRights = this.decodedJwtToken.userRole.userRights;
		return userRights.blacklist ? !userRights.read[resource] : userRights.read[resource];
	}

	hasWriteRight(resource: string) {
		const userRights: UserRights = this.decodedJwtToken.userRole.userRights;
		return userRights.blacklist ? !userRights.write[resource] : userRights.write[resource];
	}

	getUsername() {
		return this.decodedJwtToken.email;
	}
}

// since react-admin AuthProvider type is weakly typed (doesn't have generics and uses any types)
// we create our own with proper typing (mainly return types of methods)
export class TiaAuthProvider implements AuthProvider {
	private readonly AccessTokenKey = 'access_token';

	private permissions!: Permissions;

	isValid(accessToken?: string | null) {
		if (!accessToken) return false;
		const decoded = jwtDecode(accessToken) as any;
		if (!decoded) return false;
		if (new Date(decoded.exp * 1000) < new Date()) return false;
		return true;
	}

	constructor() {
		const accessToken = localStorage.getItem(this.AccessTokenKey);
		if (!this.isValid(accessToken)) {
			localStorage.removeItem(this.AccessTokenKey);
			return;
		};

		if (accessToken) {
			try {
				this.permissions = new Permissions(accessToken);
			} catch (e) {
				localStorage.removeItem(this.AccessTokenKey);
			}
		}
	}

	login({ email, password }: { email: string, password: string }) {
		const body = {
			username: email,
			password,
		};

		return fetch(`${API_URL}/api/auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
				return response.json();
			})
			.then(({ access_token }: { access_token: string }) => {
				localStorage.setItem(this.AccessTokenKey, access_token);
				try {
					this.permissions = new Permissions(access_token);
				} catch (e) {
					localStorage.removeItem(this.AccessTokenKey);
				}
			});
	}

	logout() {
		localStorage.removeItem(this.AccessTokenKey);
		return Promise.resolve();
	}

	checkError() {
		return Promise.resolve();
	}

	checkAuth() {
		return localStorage.getItem(this.AccessTokenKey) ? Promise.resolve() : Promise.reject();
	}

	getPermissions(): Promise<Permissions> {
		return Promise.resolve(this.permissions);
	}

	getPermissionsNow() {
		return this.permissions;
	}

	getAccessToken() {
		const jwtToken = localStorage.getItem(this.AccessTokenKey);
		return jwtToken ? jwtToken : undefined;
	}
}

export const authProvider = new TiaAuthProvider();
