import React from 'react';
import { List, Datagrid, TextField, DateField, ShowButton, SimpleShowLayout, Show, Filter, TextInput, useLocale } from 'react-admin';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { PaginationProps } from './helpers/paginationProps';

const title = <ResourceTitle translationKey='logs' property='level' />;

const SystemLogFilter = (props: any) => {
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
		</Filter>
	);
};

const SystemLogList = (props: any) => {
	const locale = useLocale();

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<SystemLogFilter/>}
			title={title}
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={false}
		>
			<Datagrid>
				<DateField label='system_log.timestamp' source='createdAt' showTime locales={locale} />
				<TextField label='system_log.level' source='level' />
				<TextField label='system_log.message' source='message' />
				<ShowButton />
			</Datagrid>
		</List>
	);
};

export const SystemLogShow = (props: any) => {
	const locale = useLocale();

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<DateField label='system_log.timestamp' source='createdAt' showTime locales={locale} />
				<TextField label='system_log.level' source='level' />
				<TextField label='system_log.message' source='message' />
			</SimpleShowLayout>
		</Show>
	);
};

export const systemLogViews = {
	list: SystemLogList,
	show: SystemLogShow,
};
