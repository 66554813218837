import React from 'react';
import { BulkDeleteButton, Create, Datagrid, DateField, DeleteButton, Edit, EditButton, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, useLocale, useTranslate } from 'react-admin';
import { authProvider } from '../../authProvider';
import { ApiRoutes } from '../../constants';
import { JsonEditorRa, JsonEditorRaField } from '../../reusable-components/monaco-editor/JsonEditor';
import { useHasRights } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { OrgField } from '../helpers/resource-helper';

const title = <ResourceTitle translationKey='dashboards' />;

const DashboardList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Dashboard);
	// TODO: refactor to hooks
	const isOrg = authProvider.getPermissionsNow().isOrg();

	const translate = useTranslate();

	useBlankReferenceFieldHelper(ApiRoutes.DashboardType);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	if (isOrg) useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			title={title}
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField label='general.name' source='name' />
				<ReferenceField label='resource.dashboardType' source='dashboardTypeId' reference='dashboard-types' link={null} >
					<FunctionField render={({ translationKey }: any) => translate(translationKey)} />
				</ReferenceField>
				{
					isOrg ? undefined :
					<OrgField label = 'resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};

const DashboardCreate = (props: any) => {
	// TODO: refactor to hooks
	const isOrg = authProvider.getPermissionsNow().isOrg();

	const translate = useTranslate();

	return (
		<Create {...props} title={title}>
			<SimpleForm label='general.name' redirect='show'>
				<TextInput label='general.name' source='name' />
				{
					!isOrg && <ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<ReferenceInput
					source='dashboardTypeId'
					reference='dashboard-types'
					label={'resource.dashboardType'}
					perPage={10000}
				>
					<SelectInput optionText={({ translationKey }: any) => translate(translationKey)} />
				</ReferenceInput>
				<JsonEditorRa label='dashboard.settings' source='settings' />
			</SimpleForm>
		</Create>
	);
};

const DashboardShow = (props: any) => {
	const locale = useLocale();
	const translate = useTranslate();

	useBlankReferenceFieldHelper(ApiRoutes.DashboardType);

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField label='general.id' source='id' />
				<TextField label='general.name' source='name' />
				<ReferenceField label='resource.dashboardType' source='dashboardTypeId' reference='dashboard-types' link={null} >
					<FunctionField render={({ translationKey }: any) => translate(translationKey)} />
				</ReferenceField>
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<JsonEditorRaField label='dashboard.settings' source='settings' />
			</SimpleShowLayout>
		</Show>
	);
};

const DashboardEdit = (props: any) => {
	// TODO: refactor to hooks
	const isOrg = authProvider.getPermissionsNow().isOrg();

	const translate = useTranslate();

	return (
		<Edit {...props} title={title}>
			<SimpleForm>
				<TextInput label='general.name' source='name' />
				{
					!isOrg && <ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<ReferenceInput
					source='dashboardTypeId'
					reference='dashboard-types'
					label={'resource.dashboardType'}
					perPage={10000}
				>
					<SelectInput optionText={({ translationKey }: any) => translate(translationKey)} />
				</ReferenceInput>
				<JsonEditorRa label='dashboard.settings' source='settings' />
			</SimpleForm>
		</Edit>
	);
};

export const dashboardViews = {
	list: DashboardList,
	create: DashboardCreate,
	show: DashboardShow,
	edit: DashboardEdit
};
