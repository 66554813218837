import { makeStyles } from '@material-ui/core';
import { ControlledEditor } from '@monaco-editor/react';
import React, { useState } from 'react';
import { Labeled, useInput } from 'react-admin';
import { useCustomTheme } from '../../theme';

interface JsonEditorProps {
	value?: string;
	onChange?: (value: string) => void;
	readonly?: boolean;
	style?: any;
}

export const JsonEditor = ({ value: defaultValue, onChange, readonly, style }: JsonEditorProps) => {
	const theme = useCustomTheme();
	const [value] = useState<string>(JSON.stringify(defaultValue || '', null, 4));
	
	const updateValue = (newValue: string | undefined) => {
		if (newValue == undefined) return;
		try {
			if (onChange) {
				onChange(JSON.parse(newValue));
			}
		} catch(e) {
			//
		}
	};

	return (
		<div style={{ height: '300px', padding: '5px', ...style }}>
			<ControlledEditor
				theme={theme.settings.dark ? 'dark' : 'light'}
				language='json'
				value={value}
				onChange={(e: any, value: string | undefined) => updateValue(value)}
				options={{readOnly: readonly}}
			/>
		</div>
	);
};

interface JsonEditorRaProps {
    source: string;
    record?: any;
	validate?: any;
	style?: any;
	label?: string;
}

const useStyles = makeStyles({
	// dirty hack because Labeled just passes style to its child
	label: {
		'& > *': {
			width: '100%',
		},
	},
});

export const JsonEditorRa = (props: JsonEditorRaProps) => {
    const { 
        input: { value, onChange },
	} = useInput(props);
	const classes = useStyles();
    
    return (
		<div className={classes.label}>
			<Labeled label={props.label || ' '}>
				<JsonEditor
					value={value || ''}
					onChange={onChange}
					style={props.style}
				/>
			</Labeled>
		</div>
    );
};

export const JsonEditorRaField = (props: JsonEditorRaProps) => {
	const { record, source, label } = props;
	const classes = useStyles();

    return (
		<div className={classes.label}>
			<Labeled label={label || ' '}>
				<JsonEditor
					value={record[source] || ''}
					readonly={true}
					style={props.style}
				/>
			</Labeled>
		</div>
    );
};