import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { BulkDeleteButton, Create, Datagrid, DatagridBody, DateField, DeleteButton, Edit, Filter, FormDataConsumer, FunctionField, List, ReferenceInput, SelectInput, Show, ShowButton, SimpleForm, Tab, TabbedShowLayout, TextField, TextInput, useLocale, useTranslate } from 'react-admin';
import { authProvider } from '../../authProvider';
import { ApiRoutes } from '../../constants';
import { AttributeDefinitionsTableRa, AttributeDefinitionsTableRaField } from '../../reusable-components/AttributeDefinitionsTable';
import { JsEditorRa, JsEditorRaField } from '../../reusable-components/monaco-editor/JsEditor';
import { JsonEditorRa, JsonEditorRaField } from '../../reusable-components/monaco-editor/JsonEditor';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { TiaCloneButton } from '../../reusable-components/TiaCloneButton';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { OrgField } from '../helpers/resource-helper';

const title = <ResourceTitle translationKey='deviceTypes' />;

const useStyles = makeStyles({
    inputField: {
        width: '256px',
        '& .MuiInputBase-formControl': {
            width: '256px',
        }
    },
});

const DeviceTypeFilter = (props: any) => {
	const orgId = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!orgId &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};

const CustomDeviceTypeRow = ({ children, record, resource, id, onToggleItem, selected, basePath }: any) => {
	const { writeRights } = useHasRights(ApiRoutes.DeviceType);

	return (
		<TableRow key={id} hover={true}>
			{
				writeRights &&
				<TableCell padding='none'>
					<Checkbox
						style={{ marginLeft: 7 }}
						checked={selected}
						onClick={() => onToggleItem(id)}
					/>
				</TableCell>
			}
			{React.Children.map(children, field => {
				if (!field) return null;

				const shouldDisplay = field.props.displayFn ? field.props.displayFn(record) : true;
				if (!shouldDisplay) return <TableCell />;

				return (
					<TableCell key={`${id}-${field.props.source}`}>
						{React.cloneElement(field, {
							record,
							basePath,
							resource,
						})}
					</TableCell>
				);
			}
			)}
		</TableRow>
	);
};

const CustomDeviceTypeDatagridBody = (props: any) => <DatagridBody {...props} row={<CustomDeviceTypeRow />} />;
const CustomDeviceTypeDatagrid = (props: any) => <Datagrid {...props} body={<CustomDeviceTypeDatagridBody />} />;

const DeviceTypeList = (props: any) => {
	const translate = useTranslate();
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.DeviceType);
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	interface ConditionalButtonProps {
		displayFn: (record: any) => boolean;
		[key: string]: any;
	}

	const ConditionalEditAndDeleteButton = (props: ConditionalButtonProps) => {
		const { record, displayFn } = props;
		if (!displayFn) throw new Error('displayFn not provided');
		if (displayFn(record)) {
			return <DeleteButton {...props} undoable={false} />;
		}
		return null;
	};

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<DeviceTypeFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<CustomDeviceTypeDatagrid>
				<TextField source='name' label='general.name' />
				<TextField source='manufacturer' label='deviceType.manufacturer' />
				<FunctionField
					source='supported'
					label='deviceType.type'
					render={(record: any) => translate(record.supported ? 'deviceType.catalog' : 'deviceType.custom')}
				/>
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				{
					!isOrg &&
					<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<ShowButton />
				{
					writeRights &&
					<TiaCloneButton
						cloneFn={(record: any) => {
							const { id, supported, orgId, org, ...cloned } = record;
							return cloned;
						}}
					/>
				}
				{writeRights && <ConditionalEditAndDeleteButton displayFn={(record: any) => !record.supported} />}
			</CustomDeviceTypeDatagrid>
		</List>
	);
};

export const DeviceTypeCreate = (props: any) => {
	const translate = useTranslate();
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Create {...props} title={title}>
			<SimpleForm redirect='show'>
				<TextInput source='name' label='general.name' />
				<TextInput source='manufacturer' label='deviceType.manufacturer' />
				{!isOrg &&
					<SelectInput
						source='supported'
						label='deviceType.type'
						defaultValue={false}
						choices={[
							{ id: true, name: translate('deviceType.catalog') },
							{ id: false, name: translate('deviceType.custom') },
						]}
					/>
				}
				<SelectInput
					source='hasBattery'
					label='deviceType.powerSupply'
					defaultValue={true}
					choices={[
						{ id: true, name: translate('deviceType.battery') },
						{ id: false, name: translate('deviceType.external') },
					]}
				/>
				{
					!isOrg &&
					<ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<JsEditorRa
					source='encoder'
					label='deviceType.encoder'
				/>
				<JsEditorRa
					source='decoder'
					label='deviceType.decoder'
				/>
				<JsonEditorRa
					source='alarmDefinitions'
					label='deviceType.alarmDefinitions'
				/>
				<AttributeDefinitionsTableRa
					source='attributeDefinitions'
					showDeviceAttribute={true}
				/>
			</SimpleForm>
		</Create>
	);
};

export const DeviceTypeShow = (props: any) => {
	const permissions = authProvider.getPermissionsNow();
	const [showEncoderDecoder, setShowEncoderDecoder] = useState(true);
	const translate = useTranslate();

	return (
		<Show {...props} title={title}>
			<TabbedShowLayout>
				<Tab label='general.general'>
					<TextField source='id' label='general.id' />
					<TextField source='name' label='general.name' />
					<TextField source='manufacturer' label='deviceType.manufacturer' />
					<FunctionField
						label={'deviceType.powerSupply'}
						render={(record: any) => record.hasBattery ? translate('deviceType.battery') : translate('deviceType.external')}
					/>
					<FunctionField
						addLabel={false}
						render={(record: any) => {
							setShowEncoderDecoder(
								record.supported && permissions && !permissions.isOrg()
							);
							return null;
						}}
					/>
				</Tab>
				<Tab label='device.attributes'>
					<AttributeDefinitionsTableRaField
						source='attributeDefinitions'
						showDeviceAttribute={true}
					/>
				</Tab>
				{
					showEncoderDecoder &&
					<Tab label='deviceType.decoder'>
						<JsEditorRaField
							source='decoder'
							style={{ height: '90vh' }}
						/>
					</Tab>
				}
				{
					showEncoderDecoder &&
					<Tab label='deviceType.encoder'>
						<JsEditorRaField
							source='encoder'
							style={{ height: '90vh' }}
						/>
					</Tab>
				}
				<Tab label='resource.alarm'>
					<JsonEditorRaField
						source='alarmDefinitions'
						label='deviceType.alarmDefinitions'
					/>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const DeviceTypeEdit = (props: any) => {
	const translate = useTranslate();
	const isOrg = useIsOrg();
	const classes = useStyles();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Edit {...props} title={title}>
			<SimpleForm>
				<TextInput source='name' label='general.name' />
				<TextInput source='manufacturer' label='deviceType.manufacturer' />
				{!isOrg &&
					<SelectInput
						source='supported'
						label='deviceType.type'
						defaultValue={false}
						choices={[
							{ id: true, name: translate('deviceType.catalog') },
							{ id: false, name: translate('deviceType.custom') },
						]}
					/>
				}
				<SelectInput
					source='hasBattery'
					label='deviceType.powerSupply'
					defaultValue={false}
					choices={[
						{ id: true, name: translate('deviceType.battery') },
						{ id: false, name: translate('deviceType.external') },
					]}
				/>
				{
					!isOrg &&
					<FormDataConsumer>
						{({ formData}: { formData: any }) =>
						!formData.supported ??
							<ReferenceInput
								source='orgId'
								reference='organizations'
								label={'resource.organization'}
								className={classes.inputField}
								perPage={10000}
							>
								<SelectInput optionText='name' />
							</ReferenceInput>
						}
					</FormDataConsumer>
				}
				<JsEditorRa
					source='encoder'
					label='deviceType.encoder'
				/>
				<JsEditorRa
					source='decoder'
					label='deviceType.decoder'
				/>
				<JsonEditorRa
					source='alarmDefinitions'
					label='deviceType.alarmDefinitions'
				/>
				<AttributeDefinitionsTableRa
					source='attributeDefinitions'
					showDeviceAttribute={true}
				/>
			</SimpleForm>
		</Edit>
	);
};

export const deviceTypeViews = {
	list: DeviceTypeList,
	create: DeviceTypeCreate,
	show: DeviceTypeShow,
	edit: DeviceTypeEdit
};
