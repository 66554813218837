import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import Axios from '../../../api/axios-client';
import React from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { authProvider } from '../../../authProvider';
import { JsonEditor } from '../../../reusable-components/monaco-editor/JsonEditor';

export const DeviceDownlinkMessage = ({ deviceId }: any) => {
  const jwt = authProvider.getAccessToken();

  const translate = useTranslate();
  const notify = useNotify();

  const [message, setMessage] = React.useState<any>(undefined);
  const [toggleOn, setToggleOn] = React.useState<any>(false);
  // TODO: use hook
  const sendDownlinkMsg = () => {
    Axios.post(
      '/api/send-downlink',
      { deviceId, message },
      { headers: { Authorization: `Bearer ${jwt}` } }
    )
      .then((_) => notify('device.sendingDownlinkMessageSuccess'))
      .catch((_) => notify('device.sendingDownlinkMessageFailure', 'error'));
    notify('device.sendingDownlinkMessage');
  };

  return (
    <>
      <JsonEditor value={message} onChange={(val) => setMessage(val)} />
	  <FormControlLabel
        value={toggleOn}
        control={<Switch color="primary" />}
        label="Preskoči device encoder"
        labelPlacement="start"
		style={{  display: 'flex-end'}}
      />
      <div
        style={{
          margin: 10,
        }}
      >
        <Button variant="contained" color="primary" onClick={sendDownlinkMsg}>
          {translate('device.sendDownlinkMessage')}
        </Button>
      </div>
    </>
  );
};
