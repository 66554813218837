/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Theme } from '@material-ui/core';
import {
	Edit as EditIcon, GetApp as ExportIcon,

	Visibility as VisibilityIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { Datagrid, fetchRelatedRecords, Filter, FunctionField, List, TextInput, useDataProvider, useRefresh } from 'react-admin';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { ApiRoutes } from '../../constants';
import { PaginationProps } from '../../resources/helpers/paginationProps';
import { useHasRights } from '../../reusable-components/permissions-hooks';
import { SmartMeteringDeviceDrawer } from './drawer/SmartMeteringDeviceDrawer';
import { createSmartMeteringExporter, SmartMeteringCell } from './smart-metering-columns';
import { getSmartMeteringColumnLabel, SmartMeteringColumn } from './smart-metering-export-columns';
import { SmartMeteringExportDialog } from './SmartMeteringDateDialog';
import { SmartMeteringEditDialog } from './SmartMeteringEditDialog';
import SyncAttributesBulkButton from './SyncAttributesBulkButton';
import { SyncAttributesButton } from './SyncAttributesButton';


const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			color: theme.palette.text.primary,
		},
		sectionTitle: {
			display: 'flex',
			justifyContent: 'space-between',

			'& > span': {
				fontSize: '1.3rem',
				fontWeight: 200,
				textTransform: 'uppercase',
				marginBlockStart: '0.83em',
				marginBlockEnd: '0.83em',
			},
		},
		alarmChartsContainer: {
			display: 'flex',
			width: '100%',
			'& > *': {
				flexBasis: 1,
				flexGrow: 1,
			}
		},
		actions: {
			display: 'flex',
			minHeight: '40px',
			alignItems: 'flex-start',
			paddingTop: '24px',
			paddingBottom: '8px',
			justifyContent: 'flex-end',
		},
	};
});

const SmartMeteringExportTabFilters = (props: any) => {
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
		</Filter>
	);
};

const Actions = (props: any) => {
	const { onExportClick, openEditClick, displaySyncAttributesButton, dashboardId } = props;
	const translate = useTranslate();
	const classes = useStyles();
	const refreshView = useRefresh();
	const { writeRights } = useHasRights(ApiRoutes.Device);

	return (
		<div className={classes.actions}>
			{
				// TODO: MEGA HACK for Varkom until smart metering becomse it's own app
				(writeRights && displaySyncAttributesButton) &&
				<SyncAttributesButton dashboardId={dashboardId} onSuccess={() => refreshView()} />
			}
			<Button onClick={() => openEditClick()} color='primary' size='small'>
				<EditIcon /> {translate('ra.action.edit')}
			</Button>
			<Button onClick={() => onExportClick()} color='primary' size='small'>
				<ExportIcon /> {translate('ra.action.export')}
			</Button>
		</div>
	);
};

interface SmartMeteringExportTabProps {
	dashboard: any;
}

const PostBulkActionButtons = (props: any) => (
	<SyncAttributesBulkButton {...props} />
);

export const SmartMeteringExportTab = (props: SmartMeteringExportTabProps) => {
	const { dashboard } = props;
	const defaultExportedColumns: SmartMeteringColumn[] = ['name', 'deviceType', 'integration', 'status', 'battery', 'telemetryValue', 'telemetryLastChange'];
	const classes = useStyles();
	const translate = useTranslate();
	const [exportDialogOpen, setExportDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [exportedColumns, setExportedColumns] = useState<SmartMeteringColumn[]>(defaultExportedColumns);
	const [attributeDefinitions, setAttributeDefinitions] = useState<any[] | undefined>();
	const dataProvider = useDataProvider();
	const match = useRouteMatch();
	const history = useHistory();

	if (!dashboard) return null;
	const { settings } = dashboard;
	const { displayName: telemetryLabel } = settings;

	useEffect(() => {
		setExportedColumns(settings.exportedColumns || defaultExportedColumns);
	}, [settings.exportedColumns]);

	useEffect(() => {
		if (!settings.attributeSetId) return;
		dataProvider.getOne(ApiRoutes.AttributeSets, { id: settings.attributeSetId })
			.then(({ data }: any) => {
				if (data && data.attributeDefinitions) {
					setAttributeDefinitions(data.attributeDefinitions);
				}
			});
	}, [settings.attributeSetId]);

	const exportData = (date: Date, csvSeparator: string, decimalComaSeparator?: boolean) => {
		const exporter = createSmartMeteringExporter(exportedColumns, telemetryLabel, attributeDefinitions || [], translate);
		dataProvider
			.getList(
				'dashboard-devices',
				{
					sort: undefined,
					filter: {
						'dashboardId||$eq': dashboard.id,
						untilDate: date,
					},
					pagination: { page: 1, perPage: 10000 }
				},
			)
			.then(({ data }: { data: any[] }) => {
				exporter(data, fetchRelatedRecords(dataProvider), 'dashboard-devices', csvSeparator, decimalComaSeparator);
			});
	};

	const saveColumns = (columns: SmartMeteringColumn[]) => {
		dataProvider.update(
			ApiRoutes.Dashboard,
			{
				previousData: undefined,
				id: dashboard.id,
				data: {
					...dashboard,
					settings: {
						...dashboard.settings,
						exportedColumns: columns,
					},
				}
			}
		)
			.then(({ data }: any) => setExportedColumns(data.settings.exportedColumns));
	};

	// we need to prevent first render until attributeSet is fetched because labels won't change after, probably because of RA
	if (settings.attributeSetId && !attributeDefinitions) return null;

	return (
		<div className={classes.container}>
			<SmartMeteringExportDialog
				open={exportDialogOpen}
				onClose={csvExportParams => {
					setExportDialogOpen(false);
					if (csvExportParams) exportData(csvExportParams.endDate, csvExportParams.csvSeparator, csvExportParams.decimalComaSeparator);
				}}
			/>
			<SmartMeteringEditDialog
				open={editDialogOpen}
				onClose={(columns?: SmartMeteringColumn[]) => {
					setEditDialogOpen(false);
					if (columns) {
						saveColumns(columns);
					}
				}}
				columns={exportedColumns}
				attributeDefinitions={attributeDefinitions || []}
			/>
			<Route
				path={`${match.path}/:deviceId`}
				render={props => {
					return <SmartMeteringDeviceDrawer
						dashboard={dashboard}
						open={true}
						onClose={() => {
							history.push(match.url);
						}}
					/>;
				}}
			>
			</Route>
			<List
				basePath='/devices'
				resource='dashboard-devices'
				hasCreate={false}
				hasEdit={false}
				hasList={false}
				hasShow={false}
				bulkActionButtons={dashboard.settings.attributesSyncUrl ? <PostBulkActionButtons dashboardId={dashboard.id} /> : false}
				perPage={25}
				pagination={<PaginationProps />}
				filter={{ 'dashboardId||$eq': dashboard.id }}
				filters={<SmartMeteringExportTabFilters />}
				actions={<Actions
					displaySyncAttributesButton={!!dashboard.settings.attributesSyncUrl}
					dashboardId={dashboard.id}
					onExportClick={() => setExportDialogOpen(true)}
					openEditClick={() => setEditDialogOpen(true)}
				/>}
				title={dashboard.name}
			>
				<Datagrid>
					{
						(exportedColumns).map((column: string) => {
							const label = getSmartMeteringColumnLabel(column as any, attributeDefinitions || [], telemetryLabel);
							return <SmartMeteringCell
								key={column}
								label={label as any}
								column={column as any}
								telemetryLabel={telemetryLabel}
							/>;
						})
					}
					<FunctionField render={(record: any) => {
						return <Link to={`${match.url}/${record.id}`} style={{ textDecoration: 'none' }}>
							<Button
								color='primary'
								size='small'
							>
								<VisibilityIcon style={{ marginRight: '5px' }} /> {translate('ra.action.show')}
							</Button>
						</Link>;
					}} />
				</Datagrid>
			</List>
		</div>
	);
};
