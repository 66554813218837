import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as CustomDateRangePicker } from '@wojtekmaj/react-daterange-picker';
import { default as momentType } from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { useCustomTheme } from '../theme';

declare let moment: typeof momentType;

const useStyles = makeStyles(
	{
        dateRangePickerWrapper: {
            height: '50px',
            marginRight: '10px',
            display: 'inline-flex',
            alignItems: 'flex-end',
        },
		customDateRangePickerDark: {
            marginLeft: '10px',
            color: 'white',
            '& .react-daterange-picker__wrapper': {
                borderColor: 'white',
            },
            '& .react-daterange-picker__inputGroup__input': {
                color: 'white',
            },
            '& .react-daterange-picker__button > svg': {
                stroke: 'white',
            },
        },
        customDateRangePickerLight: {
            marginLeft: '10px',
        },
	}
);

enum Period {
    Week = 'week',
    Month = 'month',
    Year = 'year',
    Custom = 'custom',
};

export enum TimeResolution {
    Hour = 'hour',
    Day = 'day',
    Month = 'month',
}

export interface DateRange {
    from: Date,
    to: Date,
}

export interface DateRangeOutput {
    dateRange: DateRange;
    timeResolution: TimeResolution;
    period: Period;
}

export const DAY_MS = 24 * 60 * 60 * 1000;

interface DateRangePickerProps {
    label: string;
    onChange: (value: DateRangeOutput) => void;
    disableCustom?: boolean;
    dateRange?: DateRangeOutput;
}

const DateRangePicker = (props: DateRangePickerProps) => {
    const { label, onChange, dateRange: dateRangeProp } = props;
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useCustomTheme();
    const locale = useLocale();
    const [period, setPeriod] = useState<Period>(dateRangeProp?.period || Period.Month);
    const [dateRange, setDateRange] = useState<DateRange>(dateRangeProp?.dateRange || { from: new Date(), to: new Date() });
    const [timeResolution, setTimeResolution] = useState<TimeResolution>(dateRangeProp?.timeResolution || TimeResolution.Hour);
    const [initialized, setInitialized] = useState<boolean>(false);  // this is needed to avoid two onChanges on init

    // sync prop input with internal state
    useEffect(() => {
        if (!dateRangeProp) return;
        if (timeResolution !== dateRangeProp.timeResolution) {
            setTimeResolution(dateRangeProp.timeResolution);
        }
        const { from, to } = dateRangeProp.dateRange;
        if (dateRange.from.getTime() !== from.getTime() || dateRange.to.getTime() !== to.getTime()) {
            setDateRange({
                from: new Date(from),
                to: new Date(to),
            });
        }
        if (period !== dateRangeProp.period) {
            setPeriod(dateRangeProp.period);
        }
    }, [dateRangeProp]);

    useEffect(() => {
        setInitialized(true);
        const now: number = moment.now();
        if (period === Period.Week) {
            setDateRange({
                from: moment(now)
                    .subtract(1, 'week')
                    .startOf('day')
                    .toDate(),
                to: moment(now)
                    .endOf('day')
                    .toDate(),
            });
        } else if (period === Period.Month) {
            setDateRange({
                from: moment(now)
                    .subtract(1, 'month')
                    .startOf('day')
                    .toDate(),
                to: moment(now)
                    .endOf('day')
                    .toDate(),
            });
        } else if (period === Period.Year) {
            setDateRange({
                from: moment(now)
                    .subtract(1, 'year')
                    .startOf('day')
                    .toDate(),
                to: moment(now)
                    .endOf('day')
                    .toDate(),
            });
        } else {
            // TODO: investigate if this is needed
            // to trigger timeResolution useEffect and onChange
            setDateRange({
                ...dateRange,
            });
        }
    }, [period]);

    useEffect(() => {
        const duration = (dateRange.to.getTime() - dateRange.from.getTime()) / DAY_MS;
        let newTimeResolution = timeResolution;
        if (duration <= 7) {
            newTimeResolution = TimeResolution.Hour;
        } else if (duration <= 6 * 30) {
            newTimeResolution = TimeResolution.Day;
        } else {
            newTimeResolution = TimeResolution.Month;
        }
        setTimeResolution(newTimeResolution);

        if (onChange && initialized) {
            onChange({
                dateRange,
                timeResolution: newTimeResolution,
                period,
            });
        }
    }, [dateRange, initialized]);

    const setCustomDateRangePickerRange = ([from, to]: [Date, Date]) => {
        setDateRange({
            from,
            to,
        });
    };

    return (<div className={classes.dateRangePickerWrapper}>
        <FormControl>
            {/* TODO: ID should be unique. If there are multiple pickers on the same page this does not hold. */}
            <InputLabel htmlFor='period'>{label}</InputLabel>
            <Select
                label={label}
                value={period}
                onChange={event => setPeriod(event.target.value as Period)}
                inputProps={{
                    name: 'period',
                    id: 'period'
                }}
            >
                <MenuItem value={Period.Week}>{translate('datePeriod.week')}</MenuItem>
                <MenuItem value={Period.Month}>{translate('datePeriod.month')}</MenuItem>
                <MenuItem value={Period.Year}>{translate('datePeriod.year')}</MenuItem>
                {
                    !props.disableCustom ? <MenuItem value={Period.Custom}>{translate('datePeriod.custom')}</MenuItem> : <></>
                }
            </Select>
        </FormControl>
        {
            period === Period.Custom && (
                <CustomDateRangePicker
                    className={theme.settings.dark ? classes.customDateRangePickerDark : classes.customDateRangePickerLight}
                    onChange={(value: [Date, Date]) => setCustomDateRangePickerRange(value)}
                    value={[dateRange.from, dateRange.to]}
                    clearIcon={null}
                    locale={locale}
                    maxDate={new Date()}
                />
            )
        }
    </div>);
};

export default DateRangePicker;