import { ApiRoutes } from '../constants';

// ToDo put also together with upper const and expose route with controller
export const ReadOnlyResources = [ApiRoutes.Log, ApiRoutes.SystemLogs, ApiRoutes.IntegrationType, ApiRoutes.Telemetry];
export const NonOrgResources = new Set([
	ApiRoutes.Organization,
	ApiRoutes.SystemLogs,
	ApiRoutes.Translation,
	ApiRoutes.PlatformSettings,
	ApiRoutes.IntegrationType,
	ApiRoutes.DeviceType,
	ApiRoutes.Log
]);
