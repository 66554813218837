import { createMuiTheme, Theme, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import usePlatformSettings from './resources/platform-settings/usePlatformSettings';

interface ThemeSettings {
	dark: boolean;
	primaryColor: string;
	secondaryColor: string;
	warningColor: string;
	errorColor: string;
}

export interface CustomTheme extends Theme {
	setDarkTheme: () => void;
	setLightTheme: () => void;
	settings: ThemeSettings;
}

export function getTheme(settings: ThemeSettings): CustomTheme {
	const theme = createMuiTheme({
		palette: {
			type: settings.dark ? 'dark' : 'light',
			primary: {
				main: settings.primaryColor ? settings.primaryColor : '#007378',
			},
			secondary: {
				main: settings.secondaryColor ? settings.secondaryColor : '#006064',
			},
			warning: {
				main: settings.warningColor ? settings.warningColor : '#f48fb1',
			},
			error: {
				main: settings.errorColor ? settings.errorColor : '#f44336',
			},
			text: {
				primary: settings.dark ? '#fff' : '#000'
			}
		},
		overrides: {
			MuiInputBase: {
				input: {
					'&:-webkit-autofill': {
						transitionDelay: '9999s',
						transitionProperty: 'background-color, color',
					},
				},
			},
			MuiTab: {
				root: {
					fontSize: '0.8125rem',
				},
			},
			// MuiFilledInput: {
			//   root: {
			//     backgroundColor: 'none',
			//   },
			// }
		},
		sidebar: {
			width: 260,
		},
	} as any) as any;
	theme.settings = settings;
	return theme;
}

export const useCustomTheme: () => CustomTheme = () => useTheme();


const DarkThemeKey = 'DARK_THEME';
/**
 * should only be used in App.tsx
 */
export const useThemeController = () => {
	const platformSettings = usePlatformSettings();

	const getThemeSettingsFromPlatformThemeSettings = (platformThemeSettings: any) => {
		return {
			dark: platformThemeSettings.defaultDark,
			primaryColor: platformThemeSettings.defaultDark ? platformThemeSettings.darkColors.primaryColor : platformThemeSettings.lightColors.primaryColor,
			secondaryColor: platformThemeSettings.defaultDark ? platformThemeSettings.darkColors.secondaryColor : platformThemeSettings.lightColors.secondaryColor,
			warningColor: platformThemeSettings.defaultDark ? platformThemeSettings.darkColors.warningColor : platformThemeSettings.lightColors.warningColor,
			errorColor: platformThemeSettings.defaultDark ? platformThemeSettings.darkColors.errorColor : platformThemeSettings.lightColors.errorColor,
		};
	};

	const initialValue = getThemeSettingsFromPlatformThemeSettings(platformSettings.themeSettings);
	const [theme, setTheme] = useState(getTheme(initialValue));

	theme.setDarkTheme = () => {
		localStorage.setItem(DarkThemeKey, 'true');
		setTheme(getTheme(
			getThemeSettingsFromPlatformThemeSettings({ ...platformSettings.themeSettings, defaultDark: true })
		));
	};

	theme.setLightTheme = () => {
		localStorage.setItem(DarkThemeKey, 'false');
		setTheme(getTheme(
			getThemeSettingsFromPlatformThemeSettings({ ...platformSettings.themeSettings, defaultDark: false })
		));
	};

	// read default theme from local storage and set it
	useEffect(() => {
		if (localStorage.getItem(DarkThemeKey) === 'true') {
			theme.setDarkTheme();
		}
	}, []);

	return theme;
};
