import { Sync as SyncIcon } from '@material-ui/icons';
import Axios from '../../api/axios-client';
import * as React from 'react';
import {
	Button, useNotify, useRefresh, useUnselectAll
} from 'react-admin';
import { authProvider } from '../../authProvider';

const SyncAttributesBulkButton = ({ resource, selectedIds, dashboardId }: { resource: string, selectedIds: string[], dashboardId: string }) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const [syncingAttributes, setSyncingAttributes] = React.useState(false);
    const syncDevices = React.useCallback(() => {
        const url = `/api/dashboard-devices/sync-attributes/${dashboardId}/devices`;
        setSyncingAttributes(true);
        Axios.patch(url, { ids: selectedIds }, {
            headers: {
                Authorization: `Bearer ${authProvider.getAccessToken()}`,
            },
        })
            .then(res => {
                notify(
                    'smartMetering.attributesSynced',
                    'info',
                    { numDevices: res.data },
                    true
                );
                unselectAll(resource);
                refresh();
            })
            .catch(() => {
                notify(
                    'smartMetering.attributeSyncError',
                    'warning'
                );
            })
            .then(() => {
                setSyncingAttributes(false);
            });
    }, [dashboardId, selectedIds]);

    if (!dashboardId) return null;

    return (
        <Button
            label="smartMetering.syncAttributes"
            disabled={syncingAttributes}
            onClick={syncDevices}
        >
            <SyncIcon />
        </Button>
    );
};

export default SyncAttributesBulkButton;
