import { CardHeader, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React, { useEffect, useState } from 'react';
import { Title, useLocale, useTranslate } from 'react-admin';
import { ApiRoutes } from '../constants';
import AlarmsCountBySeverityHistoryPieChart from '../resources/device/charts/AlarmsCountBySeverityHistoryPieChart';
import BatteriesLifeChart from '../resources/device/charts/BatteriesLifeChart';
import BatteryTypeChart from '../resources/device/charts/BatteryTypesChart';
import EspChart from '../resources/device/charts/EspChart';
import MessagesHistoryChart from '../resources/device/charts/MessagesHistoryChart';
import useOverviewInfo, { DeviceInfo } from '../resources/device/useOverviewStats';
import { TimeResolution } from '../reusable-components/DateRangePicker';
import GridContainer from '../reusable-components/grid/GridContainer';
import GridItem from '../reusable-components/grid/GridItem';
import { Pin } from '../reusable-components/maps/types';
import { useIsOrg } from '../reusable-components/permissions-hooks';
import { getLink, Severity } from './helper';
import alarmsImage from './images/alarms.svg';
import batteryImage from './images/battery.svg';
import devicesImage from './images/devices.svg';
import messagesImage from './images/messages.svg';
import { OverviewMapCard } from './OverviewMapCard';
import useStyles from './overviewStyles';


//ToDO: After working version refactor all the code, extract reusable things
const Overview = () => {
    const classes = useStyles();
    const stats = useOverviewInfo();
    const translate = useTranslate();
    const locale = useLocale();
    const [pins, setPins] = useState<Pin[]>([]);
    const cardElevation = 6;

    const isOrg = useIsOrg();

    useEffect(() => {
        if (!stats.map) return;
        setPins(
            stats.map
                .filter((deviceInfo: DeviceInfo) => deviceInfo.attributes.latitude && deviceInfo.attributes.longitude)
                .map((deviceInfo: DeviceInfo) => {
                    const { name, id, attributes } = deviceInfo;
                    const pin: Pin = {
                        label: name,
                        link: `#/devices/${id}/show`,  // TODO: use getLink
                        location: {
                            lat: Number(attributes.latitude),
                            lng: Number(attributes.longitude),
                        },
                        details: deviceInfo,
                    };
                    return pin;
                }),
        );
    }, [stats]);

    return (
        <div>
            <Title title={translate('smenu.overview')} />
            <div className={classes.overviewCardTitle}>
                <h2>{isOrg ? stats.orgName : translate('general.administrator')}</h2>
                <p>{new Date().toLocaleString(locale)}</p>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <CardContent className={classes.overviewCardList}>
                        <div>
                            <img src={devicesImage} />
                            <a className={classes.link} href={getLink(ApiRoutes.Device, {})}>
                                <h2>{translate('overview.totalDevices')}</h2>
                                <h3>{stats.deviceInfo?.totalCt}</h3>
                            </a>
                            <div>
                                <a className={classes.link} href={getLink(ApiRoutes.Device, { online: true })}>
                                    <h3>{stats.deviceInfo?.onlineCt}</h3>
                                    <p>{translate('device.online')}</p>
                                </a>
                                <a className={classes.link} href={getLink(ApiRoutes.Device, { online: false })}>
                                    {<h3>{stats.deviceInfo?.offlineCt}</h3>}
                                    <p>{translate('device.offline')}</p>
                                </a>
                                <a className={classes.link} href={getLink(ApiRoutes.Device, { active: false })}>
                                    <h3>{(stats?.deviceInfo?.totalCt || 0) - (stats?.deviceInfo?.activeCt || 0)}</h3>
                                    <p>{translate('device.inactive')}</p>
                                </a>
                            </div>
                        </div>
                    </CardContent>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <CardContent className={classes.overviewCardList}>
                        <div>
                            <img src={alarmsImage} />
                            <div>
                                <h2>
                                    <a className={classes.link} href={getLink(ApiRoutes.Alarm, { acknowledged: false, severity: Severity.Critical })}>
                                        {translate('overview.critUnAckAlarms')}
                                    </a>
                                    {' / '}
                                    <a className={classes.link} href={getLink(ApiRoutes.Alarm, { acknowledged: false })}>
                                        {translate('overview.newAlarms')}
                                    </a>
                                </h2>
                                <h3>
                                    <a className={classes.link} href={getLink(ApiRoutes.Alarm, { acknowledged: false, severity: Severity.Critical })}>
                                        {stats.alarmInfo?.unAckCriticalCt}
                                    </a>
                                    {' / '}
                                    <a className={classes.link} href={getLink(ApiRoutes.Alarm, { acknowledged: false })}>
                                        {
                                            (stats?.alarmInfo?.unAckCriticalCt || 0) + (stats?.alarmInfo?.unAckMajorCt || 0) +
																						(stats?.alarmInfo?.unAckMinorCt || 0) + (stats?.alarmInfo?.unAckInfoCt || 0)
                                        }
                                    </a>
                                </h3>
                            </div>
                            <a className={classes.link} href={getLink(ApiRoutes.Alarm, { acknowledged: false })}>
                                <div>
                                    <h3>
                                        {stats.alarmInfo?.lastAlarmTimestamp ? new Date(stats.alarmInfo?.lastAlarmTimestamp).toLocaleString('en') : translate('overview.unknown')}
                                    </h3>
                                    <p>{translate('overview.lastAlarm')}</p>
                                </div>
                            </a>
                        </div>
                    </CardContent>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <CardContent className={classes.overviewCardList}>
                        <a className={classes.link} href={getLink(ApiRoutes.Messages, {})}>
                            <img src={messagesImage} />
                            <div>
                                <h2>{translate('overview.messagesToday')}</h2>
                                <h3>{stats.messagesInfo?.count}</h3>

                            </div>
                            <div>
                                <div>
                                    <h3>
                                        {(stats?.messagesInfo?.comparedToYesterdayPercentage || 0) > 0 ? '+' : ''}
                                        {(stats?.messagesInfo?.comparedToYesterdayPercentage || 0) !== null ? stats.messagesInfo?.comparedToYesterdayPercentage : '∞ '}
                                        %
                                    </h3>
                                    <p>{translate('overview.comparedToYesterday')}</p>
                                </div>
                            </div>
                        </a>
                    </CardContent>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <CardContent className={classes.overviewCardList}>
                        <div>
                            <img src={batteryImage} />
                            <a className={classes.link} href={getLink(ApiRoutes.Device, { batteryPercentage: { $ltRound: 5 } })}>
                                <h2>{translate('overview.batteriesUnder5')}</h2>
                                <h3>{stats.batteriesInfo?.under5Percent}</h3>
                            </a>
                            <div>
                                <a className={classes.link} href={getLink(ApiRoutes.Device, { batteryPercentage: { $ltRound: 10 } })}>
                                    {<h3>{stats.batteriesInfo?.under10Percent}</h3>}
                                    <p>{translate('overview.batteriesUnder10')}</p>
                                </a>
                                <a className={classes.link} href={getLink(ApiRoutes.Device, { batteryPercentage: { $ltRound: 20 } })}>
                                    {<h3>{stats.batteriesInfo?.under20Percent}</h3>}
                                    <p>{translate('overview.batteriesUnder20')}</p>
                                </a>
                            </div>
                        </div>
                    </CardContent>
                </GridItem>
            </GridContainer>

            <OverviewMapCard pins={pins} elevation={cardElevation} />

            <div style={{ paddingTop: '1em' }} />
            <Card elevation={cardElevation}>
                <CardContent className={classes.cardContent}>
                    <CardHeader title={translate('overview.messagesLast7Days')} titleTypographyProps={{className:classes.gridItemTitle}} />
                    <MessagesHistoryChart
                        data={stats.messagesInfo?.last7Days}
                        timeResolution={TimeResolution.Day}
                        fill={true}
                        locale={'en'}
                    />
                </CardContent>
            </Card>
            <Grid className={classes.graphGrid}>
                <Card className={classes.alarmSeverityGraph} elevation={cardElevation}>
                    <CardContent className={classes.cardContent}>
                        <CardHeader title={translate('resource.alarms')} titleTypographyProps={{className:classes.gridItemTitle}} />
                        <AlarmsCountBySeverityHistoryPieChart
                            data={
                                {
                                    info: stats.alarmInfo?.unAckInfoCt,
                                    minor: stats.alarmInfo?.unAckMinorCt,
                                    major: stats.alarmInfo?.unAckMajorCt,
                                    critical: stats.alarmInfo?.unAckCriticalCt
                                }
                            }
                            locale={'en'}
                        />
                    </CardContent>
                </Card>
                <Card className={classes.batteryLifeGraph} elevation={cardElevation}>
                    <CardContent className={classes.cardContent}>
                        <CardHeader title={translate('overview.batteries')} titleTypographyProps={{className:classes.gridItemTitle}} />
                        <BatteriesLifeChart
                            data={[stats.batteriesInfo?.under5Percent,
                            stats.batteriesInfo?.under10Percent,
                            stats.batteriesInfo?.under15Percent,
                            stats.batteriesInfo?.under20Percent,
                            stats.batteriesInfo?.under25Percent,
                            stats.batteriesInfo?.under50Percent,
                            stats.batteriesInfo?.under75Percent,
                            stats.batteriesInfo?.under100Percent,
                            stats.batteriesInfo?.externalPower,
                            stats.batteriesInfo?.unknown]}
                            locale={'en'}
                        />
                    </CardContent>
                </Card>

                <Card className={classes.deviceTypesGraph} elevation={cardElevation}>
                    <CardContent className={classes.cardContent}>
                        <CardHeader title={translate('resource.deviceTypes')} titleTypographyProps={{className:classes.gridItemTitle}} />
                        <BatteryTypeChart
                            labels={stats.deviceTypesInfo ? Object.keys(stats.deviceTypesInfo) : []}
                            data={stats.deviceTypesInfo ? Object.values(stats.deviceTypesInfo) : []}
                            locale={'en'}
                        />
                    </CardContent>
                </Card>

                <Card className={classes.espGraph} elevation={cardElevation}>
                    <CardContent className={classes.cardContent}>
                        <CardHeader title={translate('overview.deviceEsp')} titleTypographyProps={{className:classes.gridItemTitle}} />
                        <EspChart
                            data={stats.esp}
                            locale={'en'}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </div >
    );
};

export default Overview;