import { Button } from '@material-ui/core';
import React from 'react';
import { useDataProvider } from 'react-admin';
import { JsxElement } from 'typescript';

interface GridToggleButtonProps {
		label?: string;
    source: string;
    resource?: string;
    record?: any;
    trueLabel: string;
    falseLabel: string;
    icon: JsxElement;
}

const GridToggleButton = (props: GridToggleButtonProps) => {
    const { source, record, resource, trueLabel, falseLabel, icon } = props;
    const value: boolean = record[source];
    const dataProvider = useDataProvider();

    const onClick = (event: any) => {
        event.stopPropagation();

        const { id, ...newData } = record;
        newData[source] = !value;
        dataProvider.update(resource, {
            id,
            data: newData,
            previousData: record,
        });
    };

    return (
        <Button
            size='small'
            disabled={value}
            onClick={onClick}>
            {icon}
            { value ? trueLabel : falseLabel }
        </Button>
    );
};

export default GridToggleButton;
