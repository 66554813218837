/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useTranslate } from 'react-admin';
import BarChart from '../../../reusable-components/charts/BarChart';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface batterieTypesChartProps {
	labels: string[];
	label?: string;
	data: any;
	locale: string;
}

const batterieTypesChart = (props: batterieTypesChartProps) => {
	const { labels, label, data, locale } = props;
	const translate = useTranslate();
	const theme = useCustomTheme();

	return (
		<ChartContainer label={label}>
			{data && (
				<BarChart
                    label={translate('overview.devicesCount')}
                    labels={labels}
					backgroundColor={theme.settings.primaryColor}
					data={data}
					locale={locale}
				/>
			)}
		</ChartContainer>
	);
};

export default batterieTypesChart;