import React from 'react';
import { useTranslate } from 'react-admin';
import TimeSeriesChart from '../../../reusable-components/charts/TimeSeriesChart';
import { TimeResolution } from '../../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface MessagesHistoryChartProps {
	label?: string;
	data: any;
	locale: string;
	timeResolution?: TimeResolution;
	fill?: boolean;
}

const MessagesHistoryChart = (props: MessagesHistoryChartProps) => {
	const { label, data, timeResolution, locale, fill } = props;
	const translate = useTranslate();
	const theme = useCustomTheme();


	return (
		<ChartContainer label={label}>
			{data && (
				<TimeSeriesChart
                            datasets={[
                                {
									label: 'No. of messages',
									borderColor: theme.settings.primaryColor,
									fill: fill,
									backgroundColor: theme.settings.primaryColor.concat('66'),
									data: data,
                                }
							]}
							backgroundColor={theme.settings.primaryColor}
							timeScaleUnit={timeResolution as any}
							label={translate('device.messageCount')}
							locale={locale}
                        />
			)}
		</ChartContainer>
	);
};

export default MessagesHistoryChart;