import React from 'react';
import { BulkDeleteButton, Create, Datagrid, DateField, DeleteButton, Edit, EditButton, Filter, FormTab, List, Show, ShowButton, Tab, TabbedForm, TabbedShowLayout, TextField, TextInput, useLocale } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { JsEditorRa, JsEditorRaField } from '../../reusable-components/monaco-editor/JsEditor';
import { useHasRights } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { PaginationProps } from '../helpers/paginationProps';

const title = <ResourceTitle translationKey='integrationTypes' />;

const IntegrationTypeFilter = (props: any) => {
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source='q' alwaysOn />
		</Filter>
	);
};


const IntegrationTypeList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.IntegrationType);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<IntegrationTypeFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField label='general.name' source='name' />
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{ writeRights && <EditButton /> }
				{ writeRights && <DeleteButton undoable={false} /> }
			</Datagrid>
		</List>
	);
};

const IntegrationTypeCreate = (props: any) => {
	return (
		<Create {...props} title={title}>
			<TabbedForm>
				<FormTab label='general'>
					<TextInput label='general.name' source='name' />
				</FormTab>
				<FormTab label='integration.decoder'>
					<JsEditorRa label='integration.decoder' source='decoder' style={{ height: '90vh' }} />
				</FormTab>
				<FormTab label='integration.encoder'>
					<JsEditorRa label='integration.encoder' source='encoder' style={{ height: '90vh' }} />
				</FormTab>
			</TabbedForm>
		</Create>
	);
};

export const IntegrationTypeShow = (props: any) => {
	const locale = useLocale();

	return (
		<Show {...props} title={title}>
			<TabbedShowLayout>
				<Tab label='integration.summary'>
					<TextField label='general.name' source='name' />
					<TextField label='general.accessKey' source='accessKey' />
					<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
					<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				</Tab>
				<Tab label='integration.decoder'>
					<JsEditorRaField label='integration.decoder' source='decoder' style={{ height: '90vh' }} />
				</Tab>
				<Tab label='integration.encoder'>
					<JsEditorRaField label='integration.encoder' source='encoder' style={{ height: '90vh' }} />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

const IntegrationTypeEdit = (props: any) => (
	<Edit {...props} title={title}>
		<TabbedForm>
			<FormTab label='general.general'>
				<TextInput label='general.name' source='name' />
			</FormTab>
			<FormTab label='integration.decoder'>
				<JsEditorRa label='integration.decoder' source='decoder' style={{ height: '90vh' }} />
			</FormTab>
			<FormTab label='integration.encoder'>
				<JsEditorRa label='integration.encoder' source='encoder' style={{ height: '90vh' }} />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export const integrationTypeViews = {
	list: IntegrationTypeList,
	create: IntegrationTypeCreate,
	show: IntegrationTypeShow,
	edit: IntegrationTypeEdit
};
