import React from 'react';
import { Create, SimpleForm, FormDataConsumer } from 'react-admin';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import DeviceForm from './DeviceForm';

const title = <ResourceTitle translationKey='device' />;

export const DeviceCreate = (props: any) => {
	return (
		<Create {...props} title={title}>
			<SimpleForm redirect='show' defaultValue={{attributes: {}}}>

                <FormDataConsumer>
                    {(formDataProps: any) => <DeviceForm {...formDataProps} />}
                </FormDataConsumer>
                
			</SimpleForm>
		</Create>
	);
};