import { Divider } from '@material-ui/core';
import { Apartment, CloudCircle, CloudQueue, Dashboard, DashboardRounded, DeveloperBoard, Devices, EditAttributes, Error, GroupWork, Home, Language, List, Mail, People, PhonelinkSetup, Settings, SupervisedUserCircle, Timeline } from '@material-ui/icons';
import Axios from '../api/axios-client';
import React, { useMemo } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import { authProvider, Permissions } from '../authProvider';
import ScrollToTop from '../ScrollToTop';
import { MenuItemLinkProps, SubMenu } from './SubMenu';

export const CustomMenu = () => {
	const permissions: Permissions = authProvider.getPermissionsNow();
	const translate = useTranslate();

	const userId = permissions.getUserId();

	const shouldShow = useMemo(() => (resource: string) => {
		return permissions.hasWriteRight(resource) || permissions.hasReadRight(resource);
	}, [permissions]);

	const filterShouldShow = useMemo(() => (routes: MenuItemLinkProps[]) => {
		return routes
			.filter((route: { to: string }) => {
				const resource: string = route.to.split('/')[1];
				if (!resource) return false;
				return permissions.hasWriteRight(resource) || permissions.hasReadRight(resource);
			});
	}, [permissions]);


	// TODO: api service which automatically inserts auth token to header
	// TODO: how to re-render sidebar menu with new dashboards if user creates them?
	const [dashboards, setDashboards] = React.useState([]);
	const jwt = authProvider.getAccessToken();
	React.useEffect(() => {
		Axios({
			method: 'get',
			url: '/api/dashboards',
			headers: { Authorization: `Bearer ${jwt}` }
		})
			.then(({ data }: any) => setDashboards(data));
	}, []);

	return (
		<ScrollToTop>
			{
				dashboards.length === 0 ? undefined :
					<>
						{
							// TODO: types on frontend, commong pkg?
							dashboards.map((dashboard: any) => {
								return (
									<MenuItemLink
										key={dashboard.id}
										to={`/dashboard/${dashboard.id}`}
										primaryText={dashboard.name}
										leftIcon={<DashboardRounded />}
									/>
								);
							})
						}
						<Divider />
					</>
			}
			{/* <MenuItemLink
				to='/basic-dashboard'
				primaryText={'basic dashboard'}
				leftIcon={<Home />}
			/> */}
			<MenuItemLink
				to='/'
				primaryText={translate('smenu.overview')}
				leftIcon={<Home />}
			/>
			{shouldShow('devices') &&
				<SubMenu
					to='/devices'
					primaryText={translate('resource.devices')}
					leftIcon={<DeveloperBoard />}
					items={
						filterShouldShow([
							{ to: '/device-groups', primaryText: translate('resource.deviceGroups'), leftIcon: <GroupWork /> },
							{ to: '/device-types', primaryText: translate('resource.deviceTypes'), leftIcon: <Devices /> },
							{ to: '/attribute-sets-definition', primaryText: translate('resource.attributeSetDefinitions'), leftIcon: <EditAttributes /> }
						])
					}
				/>
			}
			{shouldShow('alarms') &&
				<MenuItemLink
					to='/alarms'
					primaryText={translate('resource.alarms')}
					leftIcon={<Error />}
				/>
			}
			{shouldShow('integrations') &&
				<SubMenu
					to='/integrations'
					primaryText={translate('resource.integrations')}
					leftIcon={<CloudQueue />}
					items={
						filterShouldShow([
							{ to: '/integration-types', primaryText: translate('resource.integrationTypes'), leftIcon: <CloudCircle /> },
						])
					}
				/>
			}
			{shouldShow('messages') &&
				<SubMenu
					to='/messages'
					primaryText={translate('resource.messages')}
					leftIcon={<Mail />}
					items={
						filterShouldShow([
							{ to: '/telemetry', primaryText: translate('resource.telemetry'), leftIcon: <Timeline /> },
						])
					}
				/>
			}
			{

				shouldShow('dashboards') &&
				<MenuItemLink
					to='/dashboards'
					primaryText={translate('resource.dashboards')}
					leftIcon={<Dashboard />}
				/>
			}
			{shouldShow('users') &&
				<SubMenu
					to='/users'
					primaryText={translate('resource.users')}
					leftIcon={<People />}
					items={
						filterShouldShow([
							{ to: '/user-roles', primaryText: translate('resource.userRoles'), leftIcon: <SupervisedUserCircle /> }
						])
					}
				/>
			}
			{
				!permissions.isOrg() &&
				<MenuItemLink
					to='/organizations'
					primaryText={translate('resource.organizations')}
					leftIcon={<Apartment />}
				/>
			}
			{!permissions.isOrg() && shouldShow('translations') &&
				<MenuItemLink
					to='/translations'
					primaryText={translate('resource.translations')}
					leftIcon={<Language />}
				/>
			}
			{!permissions.isOrg() && shouldShow('system-logs') &&
				<MenuItemLink
					to='/system-logs'
					primaryText={translate('resource.logs')}
					leftIcon={<List />}
				/>
			}
			{/* TODO: removed until implemented */}
			{
				<MenuItemLink
					to={`/users/${userId}`}
					primaryText={translate('smenu.userSettings')}
					leftIcon={<PhonelinkSetup />}
				/>
			}
			{!permissions.isOrg() &&
				<MenuItemLink
					to='/platform-settings/1/show' // HACK: to minimize custom code (using react-admin) we directly point to the single row table (hacky beacause it uses implementation details)
					primaryText={translate('resource.platformSettings')}
					leftIcon={<Settings />}
				/>
			}
		</ScrollToTop>
	);
};
