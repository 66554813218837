import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { throttle } from 'lodash';
import React from 'react';
import { GeoLocation } from '../types';

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
}));

interface GeoLocSearchProps {
	/**
	 * On selection listener.
	 */
	onLocationSelected?: (location: GeoLocation) => void;
}

interface Option {
	mainTextParts: string[];
	secondaryTextParts: string[];
	locationId: string;
}

/**
 * TODO:
 * - secure api keys
 * - extract construction of labels from suggestions
 * - refactor, extract api calls, etc.
 * @param props 
 */
export const GeoLocSearch = ({ onLocationSelected }: GeoLocSearchProps) => {
	const classes = useStyles();
	const [queryString, setQueryString] = React.useState('');
	const [options, setOptions] = React.useState<Option[]>([]);
	const loaded = React.useRef(true);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQueryString(event.target.value);
	};

	const fetchSuggestedPlaces = React.useMemo(
		() =>
			throttle(async (input: string) => {
				// (autocompleteService.current as any).getPlacePredictions(input, callback);
				const url = `https://autocomplete.geocoder.api.here.com/6.2/suggest.json?${encodeQueryData({
					query: input,
					// mapview: getMapView(),
					beginHighlight: '$',
					endHighlight: '$',
					app_id: hereMapsConfig.appId,
					app_code: hereMapsConfig.appCode,
					maxresults: '10',
				})}`;
				const res = await (await fetch(url)).json();
				return (res.suggestions as any[]).map((option: any) => {
					const { street, houseNumber, city, country } = option.address;
					const mainText = [street, houseNumber, city]
						.filter(part => !!part)
						.join(', ');
					const mainTextParts = mainText.charAt(0) === '$' ? ['', ...mainText.split('$')] : mainText.split('$');

					const secondaryText = [country]
						.filter(part => !!part)
						.join(', ');
					const secondaryTextParts = secondaryText.charAt(0) === '$' ? ['', ...secondaryText.split('$')] : secondaryText.split('$');

					return {
						mainTextParts,
						secondaryTextParts,
						locationId: option.locationId
					};
				})
					// dedupe
					.reduce((newResults: Option[], current: Option) => {
						if (!newResults.find(result => optionsEqual(result, current))) {
							newResults.push(current);
						}
						return newResults;
					}, []);
			}, 200),
		[],
	);

	React.useEffect(() => {
		let active = true;

		if (queryString === '') {
			setOptions([]);
			return undefined;
		}

		(fetchSuggestedPlaces as any)(queryString).then(async (results: any) => {
			setOptions(results);
		});

		return () => {
			active = false;
		};
	}, [queryString, fetchSuggestedPlaces]);

	return (
		<Autocomplete
			id="google-map-demo"
			style={{ width: '100%' }}
			getOptionLabel={(option: any) => option.mainTextParts.join('')}
			filterOptions={(x: any) => x}
			options={options}
			autoComplete
			includeInputInList
			freeSolo
			onChange={async (_, value: Option) => {
				const geoLoc = await fetchGeoLocation(value.locationId);
				if (onLocationSelected) onLocationSelected(geoLoc);
			}}
			renderInput={(params: any) => (
				<TextField
					{...params}
					label="Search locations"
					variant="outlined"
					fullWidth
					onChange={handleChange}
				/>
			)}
			renderOption={({ mainTextParts, secondaryTextParts }: any) => {
				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{mainTextParts.map((part: string, idx: number) => (
								<span key={idx} style={{ fontWeight: idx % 2 ? 200 : 800 }}>
									{part}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{secondaryTextParts.map((part: string, idx: number) => (
									<span key={idx} style={{ fontWeight: idx % 2 ? 200 : 800 }}>
										{part}
									</span>
								))}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
};

const hereMapsConfig = {
	appCode: 'LXGq1E1xkVAC3fJgwGhg3Q',
	appId: 'OvObzaz6Z5uekHP67GU4',
};

function encodeQueryData(data: { [key: string]: string }) {
	const ret = [];
	for (const d in data)
		ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
	return ret.join('&');
}

async function fetchGeoLocation(locationId: string) {
	const url = `https://geocoder.api.here.com/6.2/geocode.json?locationid=${locationId}&jsonattributes=1&gen=9&app_id=${hereMapsConfig.appId}&app_code=${hereMapsConfig.appCode}`;
	const res = await (await fetch(url)).json();
	const loc = res.response.view[0].result[0].location;
	
	if (loc.displayPosition) return {
		lat: loc.displayPosition.latitude,
		lng: loc.displayPosition.longitude
	};

	return {
		lat: loc.navigationPosition[0].latitude,
		lng: loc.navigationPosition[0].longitude
	};
}

function optionsEqual(a: Option, b: Option): boolean {
	return a.mainTextParts.join('') === b.mainTextParts.join('')
		&& a.secondaryTextParts.join('') === b.secondaryTextParts.join('');
}