import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Axios from '../../../api/axios-client';
import React, { useEffect, useState } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { authProvider } from '../../../authProvider';
import { ApiRoutes } from '../../../constants';
import { AttributesTableRaField } from '../../../resources/device/AttributesTable';
import { DeviceShowTelemetryHistory } from '../../../resources/device/DeviceShowTelemetryHistory';
import { useHasRights } from '../../../reusable-components/permissions-hooks';
import { SmartMeteringCard } from '../../components/SmartMeteringCard';
import { SyncAttributesButton } from '../SyncAttributesButton';


const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			padding: '20px 15px',
			color: theme.palette.text.primary,
		},
		attributesTable: {
			margin: '20px 0',
		},
		telemetryHistory: {
			margin: '30px 0 0 10px',
		},
	};
});

interface Props {
	device: any;
	dashboard: any;
	displaySyncAttributesButton: boolean;
	refreshDevice: () => void;
}

export const SmartMeteringDeviceOverview = (props: Props) => {
	const { device, dashboard, displaySyncAttributesButton, refreshDevice } = props;
	const classes = useStyles();
	const translate = useTranslate();
	const [overviewData, setOverviewData] = useState<any>();
	const displayName: string = dashboard.settings.displayName || '';
	const { writeRights } = useHasRights(ApiRoutes.Device);
	const refreshView = useRefresh();

	useEffect(() => {
		Axios({
			method: 'GET',
			url: `/api/dashboard-info/metering/${dashboard.id}/device/${device.id}/overview`,
			headers: {
				Authorization: `Bearer ${authProvider.getAccessToken()}`
			},
		})
			.then(({ data }: any) => {
				setOverviewData(data);
			});
	}, [dashboard, device]);

	if (!overviewData) return null;

	return <div className={classes.container}>
		<SmartMeteringCard displayName={displayName} overviewData={overviewData} />
		{
			// TODO: MEGA HACK for Varkom until smart metering becomse it's own app
			(writeRights && displaySyncAttributesButton) &&
			<SyncAttributesButton
				dashboardId={dashboard?.id}
				deviceId={device.id}
				onSuccess={() => {
					refreshView();
					refreshDevice();
				}}
				style={{ marginTop: '20px', float: 'right' }}
			/>
		}
		<DeviceShowTelemetryHistory
			record={device}
			defaultTelemetryName={dashboard?.settings?.name}
			className={classes.telemetryHistory}
		/>
		<AttributesTableRaField
			source='attributes'
			record={device}
			showEdit={false}
			className={classes.attributesTable}
		/>
	</div>;
};
