import { makeStyles } from '@material-ui/core/styles';
import React from 'react';


const useStyles = makeStyles({
	card: {
			gridColumnStart: 'span 2'
	},
	title: {
			fontSize: '1.2em',
			fontWeight: 200,
			textTransform: 'uppercase'
	},
	container: {
			height: '400px'
	},
});


interface ChartContainerProps {
	label?: string;
	hideTitle?: boolean;
	children: any;
}

const ChartContainer = (props: ChartContainerProps) => {
	const { label, hideTitle } = props;
	const classes = useStyles();

	return (
		<div className={classes.card}>
			{ !hideTitle &&
				<h2 className={classes.title}>
					{label}
				</h2>
			}
			<div className={classes.container}>
				{props.children}
			</div>
		</div>
	);
};

export default ChartContainer;
