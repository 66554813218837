import { makeStyles, TextField } from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
import React, { useState } from 'react';
import { GeoLocSearch } from './geo-location-search/GeoLocSearch';
import { PinInput } from './PinInput';
import { GeoLocation } from './types';

const isUndefined = (value: any): boolean => value === undefined;

const useStyles = makeStyles(_ => ({
	coordFields: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 30,
		'& > *': {
			flexGrow: 1,
		},
		'& > :nth-child(1)': {
			marginRight: 20,
		},
		'& > :nth-child(2)': {
			marginLeft: 20
		}
	},
	geoLocSearch: {
		marginTop: 15,
	}
}));

interface GeoLocInputProps {
	lat?: number;
	lng?: number;
	onChange?: (location: GeoLocation) => void;
	required?: boolean;
	displayLatLngFields?: boolean;
}

const Constants = {
	LatitudeMax: 90,
	LatitudeMin: -90,
	LongitudeMax: 180,
	LongitudeMin: -180
};

export const GeoLocInput = ({ onChange, ...rest }: GeoLocInputProps) => {
	const classes = useStyles();
	const [lat, setLat] = useState<number | undefined>(rest.lat);
	const [lng, setLng] = useState<number | undefined>(rest.lng);

	// TODO: this calls onChange two times when location is changed, lat and lng should use same state to avoid this
	React.useEffect(() => {
		if (isUndefined(lat) || isUndefined(lng)) return;
		if (onChange) onChange({ lat, lng });
	}, [lat, lng]);

	React.useEffect(() => {
		if (lat !== rest.lat) setLat(rest.lat);
	}, [rest.lat]);

	React.useEffect(() => {
		if (lng !== rest.lng) setLng(rest.lng);
	}, [rest.lng]);

	return (
		<div>
			<PinInput
				location={lat && lng ? { lat, lng } : undefined}
				onLocationChange={loc => {
					setLat(loc.lat);
					setLng(loc.lng);
				}}
			/>
			{
				!rest.displayLatLngFields ? <></> :
					<div className={classes.coordFields}>
						<TextField
							value={lat}
							onChange={e => {
								const lat = Number.parseFloat(e.target.value);
								if (isNaN(lat)) return setLat(undefined);
								setLat(lat);
							}}
							label='latitude'
							InputLabelProps={{
								shrink: true,
							}}
							error={(typeof lat !== 'string') && lat && (lat < Constants.LatitudeMin || lat > Constants.LatitudeMax) ? true : false} />
						<TextField
							value={lng}
							onChange={e => {
								const lng = Number.parseFloat(e.target.value);
								if (isNaN(lng)) return setLng(undefined);
								setLng(lng);
							}}
							label='latitude'
							InputLabelProps={{
								shrink: true,
							}}
							error={(typeof lng !== 'string') && lng && (lng < Constants.LongitudeMin || lng > Constants.LongitudeMax) ? true : false}
						/>
					</div>
			}
			<div className={classes.geoLocSearch}>
				<GeoLocSearch onLocationSelected={loc => {
					setLat(loc.lat);
					setLng(loc.lng);
				}} />
			</div>
		</div>
	);
};
