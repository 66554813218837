import React, { useState, useEffect } from 'react';
import DateRangePicker from '../../reusable-components/DateRangePicker';
import { useTranslate, ReferenceManyField, Datagrid, TextField, DateField, Pagination, useLocale } from 'react-admin';
import { DateRangeOutput } from '../../reusable-components/DateRangePicker';
import TelemetryHistoryChart from './charts/TelemetryHistoryChart';
import { Select, FormControl, InputLabel, MenuItem, makeStyles } from '@material-ui/core';
import axios from '../../api/axios-client';
import { ApiRoutes } from '../../constants';

const useDeviceTelemetryHistoryChartData = (deviceId: string) => {
    const [dataNames, setDataNames] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState<DateRangeOutput | undefined>(undefined);
    const [dataName, setDataName] = useState<string>('');
    const [chartData, setChartData] = useState<any>([]);

    useEffect(() => {
        axios.get(`/api/device-stats/latest-telemetry/${deviceId}`)
            .then(({data}: any) => {
                setDataNames(data.map((a: any) => a.name));
            });
    }, []);

    useEffect(() => {
        if (!dateRange || !dataName || !deviceId) return;
        axios.get(
            `/api/device-stats/telemetry/${deviceId}`,
            {
                params: {
                    name: dataName,
                    from: dateRange?.dateRange.from.getTime(),
                    to: dateRange?.dateRange.to.getTime(),
                },
            },
        )
            .then(({data}: any) => setChartData(data));
    }, [dateRange, dataName]);

    return {
        dataNames,
        dateRange,
        setDateRange,
        dataName,
        setDataName,
        chartData,
    };
};

const useStyles = makeStyles({
    textfield: {
        display: 'block',
        padding: '10px 0',
    },
});

interface Props {
    record: any;  // device
    defaultTelemetryName?: string;
    classname?: string;
}

export const DeviceShowTelemetryHistory = (props: any) => {
    const { record: device, defaultTelemetryName, className } = props;
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const {dataNames, chartData, dateRange, setDateRange, dataName, setDataName} = useDeviceTelemetryHistoryChartData(device.id);
    const selectId = `property-name-select-${Math.random()}`;
    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        if (!dateRange) return;
        setFilter({
            'name||$eq': dataName || ' ',
            'createdAt||$between': [
                dateRange.dateRange.from.toISOString(),
                dateRange.dateRange.to.toISOString(),
            ],
        });
    }, [dataName, dateRange]);

    useEffect(() => {
        if (!defaultTelemetryName) return;
        setDataName(defaultTelemetryName);
    }, [defaultTelemetryName]);

    return (
        <div className={className}>
            <div>
                <FormControl style={{minWidth: '100px', marginRight: '10px'}}>
                    <InputLabel htmlFor={selectId}>{translate('device.telemetryData')}</InputLabel>
                    <Select
                        value={dataName}
                        onChange={(e: any) => setDataName(e.target.value)}
                        inputProps={{
                            id: selectId
                        }}
                    >
                        {
                            dataNames.map((name: string) => <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <DateRangePicker
                    label={translate('datePeriod.period')}
                    onChange={value => setDateRange(value)}/>
            </div>
            <TelemetryHistoryChart
                label={dataName}
                data={chartData}
                timeResolution={dateRange && dateRange.timeResolution}
                locale={'en'}
            />

            {/* TODO: throwing errors for some reason */}
            <ReferenceManyField
                basePath={`/${ApiRoutes.Device}`}
                resource={ApiRoutes.Device}
                record={device}
                addLabel={false}
                reference="telemetry"
                target="deviceId"
                perPage={10}
                pagination={<Pagination/>}
                sort={{ field: 'createdAt', order: 'DESC' }}
                filter={filter}
            >
                <Datagrid style={{marginBottom: '16px'}}>
                    <TextField source="name" className={classes.textfield} />
                    <TextField source="value" className={classes.textfield} />
                    <DateField source="createdAt" showTime locales={locale} className={classes.textfield} />
                </Datagrid>
            </ReferenceManyField>
        </div>
    );
};
