import React from 'react';

/**
 * Component that passes its properties to all the children.
 * 
 * Useful when creating react-admin views.
 */
const TiaDiv = ({children, style, className, fragment, ...props}: any) => {
    const renderChildren = () => {
        return React.Children.map(
            children,
            child => child ? React.cloneElement<any>(child, props) : null,
        );
    };
    
    if (fragment) {
        return (
            <>
                {renderChildren()}
            </>
        );
    } else {
        return (
            <div style={style} className={className}>
                {renderChildren()}
            </div>
        );
    }
};

export default TiaDiv;