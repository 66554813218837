import { makeStyles, Theme } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import React from 'react';

function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);
 }

function formatNumber(num: number, decimalPlaces: number) {
    if (!isNumber(num)) return '-';
    return (Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toString();
}

function formatPercentage(num: number, decimalPlaces: number) {
    if (!isNumber(num)) return '-';
    return formatNumber(num, decimalPlaces) + '%';
}

// TODO: extract only OverviewCard styles
const useStyles = makeStyles((theme: Theme) => {
    return {
        '@global': {
            '.map-info': {
                padding: '6px 8px',
                fontSize: '16px',
                background: 'rgba(255,255,255,0.8)',
                boxShadow: '0 0 15px rgba(0,0,0,0.2)',
                borderRadius: '5px'
            },

            '.map-info h4': {
                margin: '0 0 5px',
                color: '#777'
            },

            '.map-legend': {
                lineHeight: '24px',
                color: '#555'
            },

            '.map-legend i': {
                width: '18px',
                height: '18px',
                float: 'left',
                marginRight: '8px',
                opacity: '0.7'
            }
        },

        container: {
            '&>div:nth-child(1)': {},
            '&>div:nth-child(2)': {
                paddingTop: 0
            }
        },

        cardsContainer: {
            display: 'grid',
            gridRowGap: '20px',
            gridColumnGap: '20px',
            gridTemplateColumns: 'repeat(2, 1fr)'
        },

        consumptionOverviewCard: {
            gridColumnStart: 'span 2'
        },

        consumptionHistoryCard: {
            gridColumnStart: 'span 2'
        },

        alarmsOverviewCard: {
            gridColumnStart: 'span 2'
        },

        alarmsHistoryCard: {
            gridColumnStart: 'span 2'
        },

        alarmsBySeverityCard: {},

        alarmsByTypeCard: {},

        cardTitleContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },

        cardTitle: {
            fontSize: '1.3rem',
            fontWeight: 200,
            textTransform: 'uppercase'
        },

        overviewList: {
            boxShadow: '10px 10px 5px -6px rgba(0,0,0,0.53)',

            backgroundColor: theme.palette.secondary.main,
            padding: '20px 15px',

            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridColumnGap: '15px',

            color: 'white',

            '&>*': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, auto)',
                gridTemplateRows: 'repeat(2, auto)',
                gridColumnGap: '0',
                gridRowGap: '0',

                '&>img': {
                    height: '95px',
                    alignSelf: 'center',
                    justifySelf: 'center'
                },

                '&>:nth-child(2)': {
                    gridColumnStart: 'span 2',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    '&>h2': {
                        margin: 0,
                        padding: 0,
                        fontSize: '1rem',
                        fontWeight: 200
                    },
                    '&>h3': {
                        margin: '-2px 0 -2px 0',
                        padding: 0,
                        fontSize: '2rem',
                        fontWeight: 'bold'
                    }
                }
            }
        },

        chartContainer: {
            backgroundColor: 'white',
            border: '1px solid #eee',
            padding: '10px',
            paddingTop: '30px',
            height: '400px'
        },

        blockLink: {
            display: 'block',
            color: 'white',
            textDecoration: 'none',
            textTransform: 'uppercase'
        },

        formControl: {
            margin: theme.spacing,
            minWidth: 120
        },

        exportTabDevicesList: {
            '&>div': {
                boxShadow: 'none'
            }
        },

        exportColumnList: {
            marginBottom: 10,
            minWidth: 298
        },

        exportColumn: {
            display: 'flex',
            alignItems: 'center',
            '&>:nth-child(2)': {
                textAlign: 'right',
                'min-width': 30
            },
            '&>:nth-child(3)': {
                width: 200,
                'max-width': 200,
                margin: '0 10px 0 10px'
            },
            padding: 10,
            marginBottom: 7,
            backgroundColor: '#eee'
        },

        exportDialog: {
            overflow: 'visible',
            '&>div:nth-child(2)': {
                overflow: 'visible'
            }
        },

        manualEntryDialog: {
            overflow: 'visible',
            '&>div:nth-child(2)': {
                overflow: 'visible',
                '&>div': {
                    overflow: 'visible'
                }
            }
        }
    };
});

interface OverviewCardStats {
    total: number;
    trend: number;
    average: number;
    maximum: number;
    minimum: number;
}

interface OverviewCardProps {
    stats: OverviewCardStats;
}

export const OverviewCard = (props: OverviewCardProps) => {
    const { stats } = props;
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.consumptionOverviewCard}>
            <div className={classes.overviewList}>
                <div>
                    <img src='/assets/images/sigma.png' />
                    <a className={classes.blockLink}>
                        <h2>{translate('dashboardOverviewCard.total')}</h2>
                        <h3>{formatNumber(stats?.total, 0)}</h3>
                    </a>
                </div>

                <div>
                    <img src='/assets/images/rise.png' />
                    <a className={classes.blockLink}>
                        <h2>{translate('dashboardOverviewCard.trend')}</h2>
                        <h3>
                            {formatPercentage(stats?.trend, 1)}
                        </h3>
                    </a>
                </div>

                <div>
                    <img src={'/assets/images/average.png'} />
                    <a className={classes.blockLink}>
                        <h2>{translate('dashboardOverviewCard.average')}</h2>
                        <h3>{formatNumber(stats?.average, 1)}</h3>
                    </a>
                </div>

                <div>
                    <img src='/assets/images/maximum.png' />
                    <a className={classes.blockLink}>
                        <h2>{translate('dashboardOverviewCard.maximum')}</h2>
                        <h3>
                            {formatNumber(stats?.maximum, 0)}
                        </h3>
                    </a>
                </div>

                <div>
                    <img src='/assets/images/minimum.png' />
                    <a className={classes.blockLink}>
                        <h2>{translate('dashboardOverviewCard.minimum')}</h2>
                        <h3>
                            {formatNumber(stats?.minimum, 0)}
                        </h3>
                    </a>
                </div>
            </div>
        </div>
    );
};