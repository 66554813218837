import { Button, Snackbar, Theme } from '@material-ui/core';
import { Sync as SyncIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Axios from '../../api/axios-client';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import { authProvider } from '../../authProvider';


// TODO: MEGA HACK for Varkom until smart metering becomse it's own app

const useStyles = makeStyles((theme: Theme) => {
    return {
        snackBar: {
            backgroundColor: theme.palette.grey.A400,
            color: theme.palette.info.contrastText,
            fontSize: '16px',
            padding: '14px 50px',
            borderRadius: '6px',
        },
        snackBarError: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
    };
});

interface Props {
    dashboardId: string;
    deviceId?: string;
    onSuccess: () => void;
    style?: React.CSSProperties;
}

export const SyncAttributesButton = (props: Props) => {
    const { dashboardId, deviceId, onSuccess, style } = props;
    const [syncingAttributes, setSyncingAttributes] = React.useState(false);
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [numDevicesSynced, setNumDevicesSynced] = React.useState(0);
    const classes = useStyles();
    const [error, setError] = useState(false);

    const syncDevices = React.useCallback(() => {
        const url = deviceId ? `/api/dashboard-devices/sync-attributes/${dashboardId}/device/${deviceId}` :  `/api/dashboard-devices/sync-attributes/${dashboardId}`;
        setSyncingAttributes(true);
        Axios.patch(url, null, {
            headers: {
                Authorization: `Bearer ${authProvider.getAccessToken()}`,
            },
        })
            .then(res => {
                onSuccess();
                setNumDevicesSynced(res.data);
                setError(false);
            })
            .catch(() => {
                setError(true);
            })
            .then(() => {
                setOpen(true);
                setSyncingAttributes(false);
            });
    }, [dashboardId, deviceId]);

    if (!dashboardId) return null;

    return (
        <>
            <Button
                disabled={syncingAttributes}
                onClick={() => syncDevices()}
                color='primary'
                size='small'
                style={style}>
                <SyncIcon style={{marginRight: '5px'}} /> {translate('smartMetering.syncAttributes')}
            </Button>
            <Snackbar open={open} autoHideDuration={4000} onClose={() => setOpen(false)}>
                <div className={clsx(classes.snackBar, error && classes.snackBarError)}>
                    {
                        error ?
                        translate('ra.page.error') :
                        translate('smartMetering.attributesSynced', { numDevices: numDevicesSynced })
                    }
                </div>
            </Snackbar>
        </>
    );
};
