/* eslint-disable @typescript-eslint/ban-types */
import { Button, FormControl, InputLabel, MenuItem, Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from '../../api/axios-client';
import React from 'react';
import { BooleanInput, Edit, FormTab, ImageField, ImageInput, NumberInput, PasswordInput, ReferenceInput, SelectInput, TabbedForm, TextInput, useInput, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { authProvider } from '../../authProvider';
import { ApiRoutes } from '../../constants';
import { HexColorInput } from '../../reusable-components/color-picker/HexInput';
import ResourceTitle from '../../reusable-components/ResourceTitle';

const title = <ResourceTitle translationKey='platformSettings' />;

const MaterialColorsInput = ({ title, value, onChange }: any) => {
	const translate = useTranslate();

	const onChangeProp = (propName: string, propValue: any) => {
		onChange({
			...value,
			[propName]: propValue
		});
	};

	return (
		<div>
			<HexColorInput label={translate('material.primaryColor')} value={value.primaryColor} onChange={color => onChangeProp('primaryColor', color)} />
			<HexColorInput label={translate('material.secondaryColor')} value={value.secondaryColor} onChange={color => onChangeProp('secondaryColor', color)} />
			<HexColorInput label={translate('material.warningColor')} value={value.warningColor} onChange={color => onChangeProp('warningColor', color)} />
			<HexColorInput label={translate('material.errorColor')} value={value.errorrColor} onChange={color => onChangeProp('errorrColor', color)} />
		</div>
	);

};

const useThemeSettingsInputStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '40%',
	},
	colors: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		marginTop: 40,
	},
	title: {
		marginBottom: 30
	},
	defaultThemeLabel: {
		whiteSpace: 'nowrap',
	},
});

const useSmtpSettingsInputStyle = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '20%',
	},
	button: {
		marginTop: '1em'
	},
	message: {
		marginTop: '1em	'
	}
});

const SmtpSettingsInput = () => {
	const classes = useSmtpSettingsInputStyle();
	const translate = useTranslate();
	const accessToken = authProvider.getAccessToken();

	const notify = useNotify();

	const handleTestMailClick = React.useCallback(async () => {
		const isOk = (await axios.get('/api/mailer/testEmailConfiguration', {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})).data;

		isOk ? notify(translate('smtp.emailSent')) : notify(translate('smtp.emailConfigurationError'));
	}, []);

	return (
		<div className={classes.container}>
			<TextInput label="smtp.host" source='smtpSettings.host' />
			<NumberInput label="smtp.port" source='smtpSettings.port' />
			<BooleanInput label="smtp.secure" source='smtpSettings.secure' />
			<TextInput label="ra.auth.username" source='smtpSettings.username' />
			<PasswordInput label="ra.auth.password" source='smtpSettings.password' />
			<Button className={classes.button} variant="contained" color="secondary" onClick={handleTestMailClick}>
				{translate('smtp.sendTestEmail')}
			</Button>
		</div>
	);
};

const ThemeSettingsInput = (props: any) => {
	const classes = useThemeSettingsInputStyles();
	const translate = useTranslate();
	const selectId = 'platform-settings-theme-select';

	const { input: { value, onChange }, meta: { touched, error } } = useInput(props);
	const onChangeProp = (propName: string, propValue: any) => {
		onChange({
			...value,
			[propName]: propValue
		});
	};

	return (
		<div className={classes.container}>
			<div>
				<FormControl
					style={{ minWidth: '256px' }}
					variant='filled'
					margin='dense'
				>
					<InputLabel htmlFor={selectId} className={classes.defaultThemeLabel}>
						{translate('general.defaultTheme')}
					</InputLabel>
					<Select
						value={value.defaultDark}
						onChange={e => onChangeProp('defaultDark', e.target.value)}
						inputProps={{
							id: selectId
						}}
					>
						<MenuItem key='light' value={false as any}>{translate('theme.light')}</MenuItem>
						<MenuItem key='dark' value={true as any}>{translate('theme.dark')}</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className={classes.colors}>
				<div>
					<div className={classes.title}>{translate('material.lightColors')}</div>
					<MaterialColorsInput value={value.lightColors} onChange={val => onChangeProp('lightColors', val)} />
				</div>
				<div>
					<div className={classes.title}>{translate('material.darkColors')}</div>
					<MaterialColorsInput value={value.darkColors} onChange={val => onChangeProp('darkColors', val)} />
				</div>
			</div>
		</div>
	);
};

const convertFileToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.rawFile);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

const ImageInputBase64 = ({ formData, ...rest }: any) => {
	const form = useForm();
	return (
		<ImageInput label="platform.loginImage" source="loginImage" accept="image/*">
			<ImageField source="src" title="title" />
		</ImageInput>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	const dark = theme.palette.type === 'dark';
	return {
		imageField: {
			backgroundColor: dark ? null : 'grey',
		},
	};
});

export const PlatformSettingsEdit = (props: any) => {
	const classes = useStyles();

	return (
		<Edit {...props} title={title}>
			<TabbedForm redirect={false}>
				<FormTab label='platform.generalSettings'>
					<TextInput label='platform.name' source='name' />
					<ReferenceInput label='platform.defaultLanguage' source='defaultLanguageId' reference={ApiRoutes.TranslationLanguage}>
						<SelectInput optionText='id' />
					</ReferenceInput>
					<ImageInput
						label="platform.loginImage"
						source="loginImage"
						accept="image/*"
						format={(value: Object | string) => typeof value === 'object' ? value : { src: value }}
					>
						<ImageField source="src" title="title" classes={{ image: classes.imageField }} />
					</ImageInput>
					<ImageInput
						label="platform.headerImage"
						source="headerImage"
						accept="image/*"
						format={(value: Object | string) => typeof value === 'object' ? value : { src: value }}
					>
						<ImageField source="src" title="title" classes={{ image: classes.imageField }} />
					</ImageInput>
					<ImageInput
						label="platform.favicon"
						source="favicon"
						accept="image/*"
						format={(value: Object | string) => typeof value === 'object' ? value : { src: value }}
					>
						<ImageField source="src" title="title" classes={{ image: classes.imageField }} />
					</ImageInput>
					<ThemeSettingsInput source='themeSettings' />
				</FormTab>
				<FormTab label='platform.smtpSettings'>
					<SmtpSettingsInput />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
