import React from 'react';
import { List, Create, Edit, Show, SimpleShowLayout, SimpleForm, Datagrid, TextField, TextInput, DateField, ShowButton, EditButton, DeleteButton, ReferenceArrayInput, SelectArrayInput, ChipField, FunctionField, ReferenceInput, SelectInput, useLocale, BulkDeleteButton } from 'react-admin';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { PaginationProps } from './helpers/paginationProps';
import { useHasRights } from '../reusable-components/permissions-hooks';
import { ApiRoutes } from '../constants';

const title = <ResourceTitle translationKey='organizations' />;

const OrganizationList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField label='general.name' source='name' />
				<FunctionField label='general.languages' source='languages' render={(record: any) => record.languages.join(', ')} />
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{ writeRights && <EditButton /> }
				{ writeRights && <DeleteButton undoable={false} /> }
			</Datagrid>
		</List>
	);
};

const OrganizationCreate = (props: any) => (
	<Create {...props} title={title}>
		<SimpleForm label='general.name' redirect='show'>
			<TextInput label='general.name' source='name' />
			<ReferenceArrayInput label='general.languages' reference={ApiRoutes.TranslationLanguage} source='languages'>
				<SelectArrayInput optionText='id' />
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);

export const OrganizationShow = (props: any) => {
	const locale = useLocale();
	
	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField label='general.name' source='name' />
				<FunctionField label='general.languages' render={(record: any) => record.languages.join(', ')} />
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
			</SimpleShowLayout>
		</Show>
	);
};

const OrganizationEdit = (props: any) => (
	<Edit {...props} title={title}>
		<SimpleForm>
			<TextInput label='general.name' source='name' />
			<ReferenceArrayInput label='general.languages' reference={ApiRoutes.TranslationLanguage} source='languages'>
				<SelectArrayInput optionText='id' />
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);

export const organizationViews = {
	list: OrganizationList,
	create: OrganizationCreate,
	show: OrganizationShow,
	edit: OrganizationEdit
};