import { InteractionMode, TimeUnit } from 'chart.js';
import React from 'react';
import { useCustomTheme } from '../../theme';
import BasicChart from './BasicChart';

interface TimeSeriesChartProp {
    datasets: Array<{
        label: string;
        borderColor: string;
        fill?: boolean;
        backgroundColor?: string;
        data: Array<{
            t: Date | string;
            y: number;
        }>;
    }>;
    timeScaleUnit: TimeUnit;
    legend?: boolean;
    locale: string;
}

const TimeSeriesChart = ({
    datasets,
    timeScaleUnit,
    legend,
    locale,
}: any) => {
    const theme = useCustomTheme();
    return (
        <BasicChart
            {...{
                type: 'line',
                data: {
                    datasets: datasets.map(dataset => ({
                        label: dataset.label,
                        data: dataset.data,
                        borderColor: dataset.borderColor,
                        pointRadius: 0,
                        backgroundColor: dataset.fill ? dataset.backgroundColor : 'rgba(0, 0, 0, 0)',
                        lineTension: 0,
                        borderWidth: 2
                    }))
                },
                options: {
                    legend: {
                        display: !!legend
                    },
                    scales: {
                        xAxes: [
                            {
                                type: 'time',
                                distribution: 'linear',
                                time: {
                                    unit: timeScaleUnit,
                                    displayFormats: {
                                        hour: 'LT'
                                    }
                                },
                                ticks: {
                                    source: 'data',
                                    autoSkip: true,
                                    fontColor: theme.palette.text.primary,
                                },
                                gridLines: {
                                    display: true,
                                    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                                },
                            }
                        ],
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: false,
                                },
                                ticks: {
                                    fontColor: theme.palette.text.primary,
                                },
                                gridLines: {
                                    display: true,
                                    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                                },
                            }
                        ]
                    },
                    tooltips: {
                        intersect: false,
                        mode: 'index' as InteractionMode,
                        callbacks: {
                            title: item => {
                                const label = item[0].xLabel;
                                if (typeof label === 'string') {
                                    return new Date(label).toLocaleString(locale);
                                }
                                return '';
                            }
                        }
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    }
                },
                locale
            }}
        />
    );
};

export default TimeSeriesChart;