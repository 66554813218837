import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { RouteWithTranslationKey } from '../constants';
import { useIsOrg } from '../reusable-components/permissions-hooks';
import { NonOrgResources, ReadOnlyResources } from './user-role-constants';


export enum RuleDef {
	READ = 'read',
	WRITE = 'write'
}

export const UserRoleRights = (props: any) => {
	const value = props.record.userRights;
	const translate = useTranslate();
	const isOrg = useIsOrg();

	return (
		<div>
			{
				!value ? '' :
					<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell key='changeMePls'>{translate('general.resource')}</TableCell>
									<TableCell key={RuleDef.READ}>
										{translate('general.read')}
									</TableCell>
									<TableCell key={RuleDef.WRITE}>
										{translate('general.write')}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{RouteWithTranslationKey
									.filter(({ name }: any) => !(isOrg && NonOrgResources.has(name)))
									.map(({ name, translationKey }: any) => {
									return (
										<TableRow key={name} hover={true}>
											<TableCell component="th" scope="row" key=''>
												{translate(translationKey)}
											</TableCell>
											<TableCell key={RuleDef.READ}>
												<Checkbox
													checked={!!value.read[name]}
													color="primary"
													disabled={true}
												/>
											</TableCell>
											<TableCell key={RuleDef.WRITE}>
												{
													ReadOnlyResources.indexOf(name) === -1 ?
														<Checkbox
															checked={!!value.write[name]}
															color="primary"
															disabled={true}
														/> : ''
												}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
			}
		</div>
	);
};
