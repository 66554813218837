import React from 'react';
import { useCustomTheme } from '../../theme';
import BasicChart from './BasicChart';

interface HorizontalBarChartProps {
    label: string;
    labels: string[];
    backgroundColor: string | string[];
    data: number[];
    locale: string;
}

const HorizontalBarChart = ({
    label,
    labels,
    backgroundColor,
    data,
    locale,
}: HorizontalBarChartProps) => {
    const theme = useCustomTheme();
    return (
        <BasicChart
            {...{
                type: 'horizontalBar',
                data: {
                    labels,
                    datasets: [
                        {
                            label,
                            borderColor: '#ECEEF1',
                            pointBorderColor: '#ECEEF1',
                            pointBackgroundColor: '#1E3D60',
                            pointHoverBackgroundColor: '#1E3D60',
                            pointHoverBorderColor: '#ECEEF1',
                            pointBorderWidth: 1,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 2,
                            pointRadius: 5,
                            fill: true,
                            backgroundColor,
                            borderWidth: 2,
                            data
                        }
                    ]
                },
                options: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        backgroundColor: '#fff',
                        titleFontColor: '#333',
                        bodyFontColor: '#666',
                        bodySpacing: 4,
                        xPadding: 12,
                        mode: 'nearest',
                        intersect: false,
                        position: 'nearest'
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                fontColor: theme.palette.text.primary,
                            },
                            gridLines: {
                                display: true,
                                color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                            },
                        }],
                        yAxes: [{
                            ticks: {
                                fontColor: theme.palette.text.primary,
                            },
                            gridLines: {
                                display: true,
                                color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                            },
                        }]
                    }
                },
                locale
            }}
        />
    );
};

export default HorizontalBarChart;