/* eslint-disable react-hooks/rules-of-hooks */
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { Datagrid, List, useDataProvider } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { AlarmFilters } from '../../resources/alarm/AlarmList';
import { PaginationProps } from '../../resources/helpers/paginationProps';
import { getSmartMeteringColumnLabel, SmartMeteringAlarmColumn, SmartMeteringCell } from './smart-metering-alarm-columns';


// TODO: edit columns dialog

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            color: theme.palette.text.primary,
        },
        sectionTitle: {
            display: 'flex',
            justifyContent: 'space-between',

            '& > span': {
                fontSize: '1.3rem',
                fontWeight: 200,
                textTransform: 'uppercase',
                marginBlockStart: '0.83em',
                marginBlockEnd: '0.83em',
            },
        },
        alarmChartsContainer: {
            display: 'flex',
            width: '100%',
            '& > *': {
                flexBasis: 1,
                flexGrow: 1,
            }
        },
        actions: {
            display: 'flex',
            minHeight: '40px',
            alignItems: 'flex-start',
            paddingTop: '24px',
            paddingBottom: '8px',
            justifyContent: 'flex-end',
        },
    };
});

const Actions = (props: any) => {
    const { openEditClick, filters, resource, showFilter, displayedFilters, filterValues } = props;
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.actions}>
            {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
            {/* <Button onClick={() => openEditClick()} color='primary' size='small'>
                <EditIcon /> {translate('ra.action.edit')}
            </Button> */}
        </div>
    );
};

interface SmartMeteringAlarmsTabProps {
    dashboard: any;
}

export const SmartMeteringAlarmsTab = (props: SmartMeteringAlarmsTabProps) => {
    const { dashboard } = props;
    const defaultAlarmColumns: SmartMeteringAlarmColumn[] = ['deviceId', 'type', 'severity', 'occurrences', 'acknowledged', 'cleared', 'createdAt', 'updatedAt'];
    const classes = useStyles();
    const translate = useTranslate();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [alarmColumns, setAlarmColumns] = useState<SmartMeteringAlarmColumn[]>(defaultAlarmColumns);
    const [attributeDefinitions, setAttributeDefinitions] = useState<any[] | undefined>();
    const dataProvider = useDataProvider();

    if (!dashboard) return null;
    const { settings } = dashboard;
    const { displayName: telemetryLabel } = settings;

    useEffect(() => {
        setAlarmColumns(settings.alarmColumns || defaultAlarmColumns);
    }, [settings.exportedColumns]);

    useEffect(() => {
        if (!settings.attributeSetId) return;
        dataProvider.getOne(ApiRoutes.AttributeSets, { id: settings.attributeSetId })
            .then(({ data }: any) => {
                if (data && data.attributeDefinitions) {
                    setAttributeDefinitions(data.attributeDefinitions);
                }
            });
    }, [settings.attributeSetId]);

    // const saveColumns = (columns: SmartMeteringAlarmColumn[]) => {
    //     dataProvider.update(
    //         ApiRoutes.Dashboard,
    //         {
    //             id: dashboard.id,
    //             data: {
    //                 ...dashboard,
    //                 settings: {
    //                     ...dashboard.settings,
    //                     exportedColumns: columns,
    //                 },
    //             },
    //         }
    //     )
    //         .then(({ data }: any) => setExportedColumns(data.settings.exportedColumns));
    // };

    // we need to prevent first render until attributeSet is fetched because labels won't change after, probably because of RA
    if (settings.attributeSetId && !attributeDefinitions) return null;

    return (
        <div className={classes.container}>
            {/* <SmartMeteringEditDialog
                open={editDialogOpen}
                onClose={(columns?: SmartMeteringAlarmColumn[]) => {
                    setEditDialogOpen(false);
                    if (columns) {
                        saveColumns(columns);
                    }
                }}
                columns={exportedColumns}
                attributeDefinitions={attributeDefinitions || []}
            /> */}
            <List
                basePath='/alarms'
                resource='dashboard-alarms'
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
                bulkActionButtons={false}
                perPage={25}
                pagination={<PaginationProps />}
                filter={{ 'dashboardId||$eq': dashboard.id }}
                filters={<AlarmFilters />}
                actions={<Actions
                    openEditClick={() => setEditDialogOpen(true)}
                />}
                title={dashboard.name}
            >
                <Datagrid>
                    {
                        (alarmColumns).map((column: string) => {
                            const label = getSmartMeteringColumnLabel(column as any, attributeDefinitions || []);
                            return <SmartMeteringCell
                                key={column}
                                column={column as any}
                            />;
                        })
                    }
                </Datagrid>
            </List>
        </div>
    );
};