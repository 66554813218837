import { Button, FormControl, FormHelperText, MenuItem, Select, Switch, Table, TableBody, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Cancel } from '@material-ui/icons';
import DevicesIcon from '@material-ui/icons/DeveloperBoard';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import React, { Attributes, useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import useAttributeDefinitionsReducer from './useAttributeDefinitionsReducer';

// TODO: validation
// TODO: is readonly needed?
// TODO: showing device attributes in create?
// TODO: allowEditDelete - do we need this?

const validateAttribute = (value: any, attributeSpecification: any) => {
    const { type, mandatory, min, max } = attributeSpecification;
    let error = '';
    // TODO: values are strings, this should be fixed
    if (type === 'number' && value != undefined) {
        value = Number.parseFloat(value as string);
        // TODO: min and max shouldn't be saved to database if they are not defined (empty string)
        if (min !== undefined && min !== '' && value < min) {
            error = `Number must be greater than ${min}`;
        }
        if (max !== undefined && max !== '' && value > max) {
            error = `Number must be less than ${max}`;
        }
        if (mandatory && value !== 0 && !value) {
            error = 'Required';
        }
    } else {
        if (mandatory && (value === undefined || value === null) && value !== false) {
            error = 'Required';
        }
    }
    return error;
};

export const validateAttributes = (attributes: any, attributeDefinitions: any[]) => {
    attributes = attributes || {};
    const invalid = attributeDefinitions
        .map((definition: any) => {
            return validateAttribute(attributes[definition.name], definition);
        })
        .some((error: string) => !!error);
    
    return invalid ? 'error' : null;
};

const getDefaultAttributeValue = (attributeSpecification: any, attributes: any) => {
    const { name, type, defaultValue } = attributeSpecification;
    const value = attributes[name];

    if (value !== undefined) return value;
    if (defaultValue !== undefined) return defaultValue;
    if (type === 'string') {
        return '';
    } else if (type === 'number') {
        return 0;
    } else if (type === 'boolean') {
        return false;
    } else {
        return '';
    }
};


interface AttributesTableValueCellProps {
    attributeSpecification: any;
    value: any;
    editing: boolean;
    onChange: (value: any) => void;
}

const AttributesTableValueCell = (props: AttributesTableValueCellProps) => {
    const { value, onChange, attributeSpecification, editing } = props;
    const { type, options, readOnly } = attributeSpecification;
    const [error, setError] = useState<string>('');
    
    useEffect(() => {
        setError(validateAttribute(value, attributeSpecification));
    }, [value, attributeSpecification]);

    const changeValue = (newValue: any) => {
        if (attributeSpecification.type === 'number' && typeof newValue === 'string') {
            newValue = Number.parseFloat(newValue);
        }
        setError(validateAttribute(newValue, attributeSpecification));
        if (onChange) {
            onChange(newValue);
        }
    };

    if (type === 'string') {
        return <TextField
            style={{ width: '200px'}}
            name='value'
            value={value}
            onChange={(e: any) => changeValue(e.target.value)}
            error={!!error}
            helperText={error ? error : null}
            InputProps={{
                readOnly: readOnly || !editing,
            }}
        />;
    } else if (type === 'number') {
        return <TextField
            style={{ width: '200px'}}
            name='value'
            type='number'
            value={value}
            onChange={(e: any) => changeValue(e.target.value)}
            error={!!error}
            helperText={error ? error : null}
            InputProps={{
                readOnly: readOnly || !editing,
            }}
        />;
    } else if (type === 'boolean') {
        return <Switch
            name='value'
            checked={value ? true : false}
            onClick={() => changeValue(!value)}
            disabled={readOnly || !editing}
        />;
    } else if (type === 'enum') {
        const selectId = `default-value-select${Math.random()}`;
        return <FormControl error={!!error}>
            <Select
                name='defaultValue'
                value={value}
                onChange={(e: any) => changeValue(e.target.value)}
                id={selectId}
                readOnly={readOnly || !editing}
            >
                <MenuItem key={''} value={''}>
                    <em>-</em>
                </MenuItem>
                {
                    options.map((option: string) => <MenuItem key={option} value={option}>{option}</MenuItem>)
                }
            </Select>
            { error ? <FormHelperText>{error}</FormHelperText> : null }
        </FormControl>;
    } else if (type === 'labeled_enum') {
        const selectId = `default-value-select${Math.random()}`;
        return <FormControl error={!!error}>
            <Select
                name='defaultValue'
                value={value}
                onChange={(e: any) => changeValue(e.target.value)}
                id={selectId}
                readOnly={readOnly || !editing}
            >
                <MenuItem key={''} value={''}>
                    <em>-</em>
                </MenuItem>
                {
                    options.map((option: any) => <MenuItem key={option} value={option.value}>{option.label}</MenuItem>)
                }
            </Select>
            { error ? <FormHelperText>{error}</FormHelperText> : null }
        </FormControl>;
    }
    return null;
};

interface AttributesTableRowProps {
    key: string;  // needed by Table
    attributeSpecification: any;
    defaultValue: any;
    onChange: (row: any) => void;
    showEdit?: boolean;
    readonly?: boolean;
    allowEditDelete?: boolean;
}

const AttributesTableRow = (props: AttributesTableRowProps) => {
    const { attributeSpecification, defaultValue, onChange, showEdit, readonly, allowEditDelete } = props;
    const translate = useTranslate();
    const [editing, setEditing] = useState(!showEdit);
    const [value, setValue] = useState<any>(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const valueChanged = (newValue: any) => {
        setValue(newValue);
        // if showEdit is set value only changes when Save is clicked
        if (onChange && !showEdit) onChange(newValue);
    };

    const toggleEditing = () => {
        if (!editing) {
            setEditing(!editing);
            return;
        }
        if (value === undefined) return;
        if (!validateAttribute(value, attributeSpecification)) {
            if (editing && onChange) onChange(value);
            setEditing(!editing);
        }
    };

    const cancelEditing = () => {
        setEditing(!editing);
        setValue(defaultValue);
    };

    return <TableRow hover={true}>
            <TableCell>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                        attributeSpecification.deviceAttribute
                        ? <Tooltip title={translate('attributeTable.deviceAttribute')}>
                            <DevicesIcon style={{marginRight: '15px'}}/>
                        </Tooltip>
                        : null
                    }
                    {attributeSpecification.displayName || attributeSpecification.name}
                </div>
            </TableCell>
            <TableCell>
            {
                <AttributesTableValueCell
                    attributeSpecification={attributeSpecification}
                    value={value}
                    editing={!readonly && editing}
                    onChange={valueChanged} />
            }
            </TableCell>
            {
                showEdit && <TableCell>
                    {
                        !attributeSpecification.readOnly &&
                        <>
                            <Button
                                startIcon={editing ? <SaveIcon/> : <EditIcon/>}
                                size="small"
                                color="primary"
                                onClick={toggleEditing}>
                                { editing ? translate('ra.action.save') : translate('ra.action.edit') }
                            </Button>
                            {
                                editing &&
                                    <Button
                                        startIcon={<Cancel/>}
                                        size="small"
                                        color="primary"
                                        onClick={cancelEditing}
                                        style={{marginLeft: '10px'}}>
                                        { translate('ra.action.cancel') }
                                    </Button>
                            }
                        </>
                    }
                </TableCell>
            }
            
        </TableRow>;
};


interface AttributesTableProps {
    defaultValue: Attributes;
    attributeDefinitions: any[];
    onChange: (a: Attributes) => void;
    showEdit?: boolean;
    readonly?: boolean;
    allowAddDelete?: boolean;
    onlyMandatory?: boolean;
    className?: string;
    emitOnInit?: boolean;  // hack so that react-admin create device form receives correct value on init (otherwise it's empty)
}

export const AttributesTable = (props: AttributesTableProps) => {
    const { defaultValue, onChange, showEdit, readonly, allowAddDelete, attributeDefinitions, onlyMandatory, className, emitOnInit } = props;

    const translate = useTranslate();
    const [filteredAttributeDefinitions, setFilteredAttributeDefinitions] = useState<any[]>([]);
    const [attributes, setAttributes] = useState(defaultValue || {});

    const calculateNewAttributes = (attributeDefinitions: any[], attributes: Attributes): Attributes => {       
        return Object.assign(
            attributes,
            ...attributeDefinitions
                .map((specification: any) => {
                    return {
                        [specification.name]: getDefaultAttributeValue(specification, attributes),
                    };
                }),
        );
    };

    useEffect(() => {
        const newAttributes = calculateNewAttributes(filteredAttributeDefinitions, defaultValue);
        if (emitOnInit && !_.isEqual(attributes, newAttributes)) {
            onChange(newAttributes);
        }
        setAttributes(newAttributes);
    }, [filteredAttributeDefinitions, defaultValue]);

    useEffect(() => {
        const filtered = onlyMandatory
            ? (attributeDefinitions || [])
                .filter((attributeSpecification: any) => attributeSpecification.mandatory)
            : attributeDefinitions;
    
        setFilteredAttributeDefinitions(filtered);
    }, [attributeDefinitions]);

    const attributeChanged = (name: string, value: any) => {
        const newAttributes ={
            ...attributes,
            [name]: value,
        };
        setAttributes(newAttributes);
        onChange(newAttributes);
    };

    if (!filteredAttributeDefinitions.length) return null;

	return (
		<div className={className}>
			<TableContainer component={Paper} elevation={0}>
				<Table aria-label='simple table' size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('general.name')}</TableCell>
                            <TableCell>{translate('device.attributes.value')}</TableCell>
                            { showEdit && <TableCell></TableCell> }
                            { allowAddDelete && <TableCell></TableCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAttributeDefinitions
                            .map((attributeSpecification: any) => {
                            return <AttributesTableRow
                                key={attributeSpecification.name}
                                defaultValue={getDefaultAttributeValue(attributeSpecification, attributes)}
                                attributeSpecification={attributeSpecification}
                                showEdit={showEdit}
                                readonly={readonly}
                                onChange={(value: any) => attributeChanged(attributeSpecification.name, value)} />;
                        })}
                    </TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};


/**
 * react-admin wrapper 
 */

interface AttributesTableRaProps {
    source: string;
    attributeDefinitions?: any[];
    record?: { attributes: Attributes };
    showEdit?: boolean;
    allowAddDelete?: boolean;
    onlyMandatory?: boolean;
    validate?: any;
    onChange?: (value: Attributes) => void;
    className?: string;
    emitOnInit?: boolean;
}

export const AttributesTableRa = (props: AttributesTableRaProps) => {
    const { allowAddDelete, attributeDefinitions, showEdit, onlyMandatory, className, emitOnInit } = props;
    const { 
        input: { value, onChange },
    } = useInput(props);
    
    return (
        <AttributesTable
            defaultValue={value || {}}
            onChange={onChange}
            allowAddDelete={allowAddDelete}
            attributeDefinitions={attributeDefinitions}
            onlyMandatory={onlyMandatory}
            showEdit={showEdit}
            className={className}
            emitOnInit={emitOnInit}
        />
    );
};

export const AttributesTableRaField = (props: AttributesTableRaProps) => {
    const { record, onChange, showEdit, className } = props;
    const { attributes: value } = record;
    const [attributeDefinitions, dispatch] = useAttributeDefinitionsReducer();

    useEffect(() => {
        const { deviceTypeId, attributeSetDefinitionId } = record as any;
        if (deviceTypeId !== undefined) {
            dispatch({type: 'DeviceTypeId', value: deviceTypeId});
        }
        if (attributeSetDefinitionId !== undefined) {
            dispatch({type: 'AttributeSetDefinitionId', value: attributeSetDefinitionId});
        }

    }, [record]);

    return (
        <AttributesTable
            defaultValue={value || {}}
            onChange={(attributes: Attributes) => onChange && onChange(attributes)}
            attributeDefinitions={attributeDefinitions}
            showEdit={showEdit}
            readonly={!showEdit}
            className={className}
        />
    );
};