import { makeStyles } from '@material-ui/core';
import React, { Attributes, useEffect, useState } from 'react';
import { BooleanInput, ReferenceInput, required, SelectInput, TextInput, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { ApiRoutes } from '../../constants';
import { GeoLocInput } from '../../reusable-components/maps/GeoLocInput';
import { GeoLocation } from '../../reusable-components/maps/types';
import { JsonEditorRa } from '../../reusable-components/monaco-editor/JsonEditor';
import { useIsOrg } from '../../reusable-components/permissions-hooks';
import TiaDiv from '../../reusable-components/TiaDiv';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { AttributesTableRa, validateAttributes } from './AttributesTable';
import useAttributeDefinitionsReducer from './useAttributeDefinitionsReducer';

const useStyles = makeStyles({
    grid: {
        marginTop: '10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: '10px',

        '@media (maxWidth:1200px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridRowGap: '10px'
        },
    },
    column: {
        display: 'block',
    },
    inputsColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    geoMap: {
        display: 'block',
        gridColumn: 'span 2',
    },
    attributesTable: {
        display: 'block',
        gridColumn: 'span 4',
    },
    inputField: {
        width: '220px',
        '& .MuiInputBase-formControl': {
            width: '220px',
        }
    },
    alarmDefinitions: {
        marginTop: '30px',
        width: '100%'
    }
});

const DeviceForm = ({ formData, ...props }: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const form = useForm();
    const [attributeSpecifications, dispatch] = useAttributeDefinitionsReducer();
    const [showGeoLocInput, setShowGeoLocInput] = useState(false);
    const isOrg = useIsOrg();

    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    // Inititialize edit form, runs only once. Could be problematic if formData is empty during the first render
    useEffect(() => {
        if (formData.deviceTypeId) {
            dispatch({ type: 'DeviceTypeId', value: formData.deviceTypeId });
        }
        if (formData.attributeSetDefinitionId) {
            dispatch({ type: 'AttributeSetDefinitionId', value: formData.attributeSetDefinitionId });
        }
    }, []);

    useEffect(() => {
        const hasLatitude = attributeSpecifications.some((spec: any) => spec.name === 'latitude');
        const hasLongitude = attributeSpecifications.some((spec: any) => spec.name === 'longitude');
        setShowGeoLocInput(hasLatitude && hasLongitude);
    }, [attributeSpecifications]);

    return (
        <>
            <TiaDiv className={classes.grid}>

                <TiaDiv className={classes.inputsColumn}>
                    <TextInput
                        source='name'
                        label={translate('general.name')}
                        validate={required()}
                        className={classes.inputField}
                    />

                    {
                        !isOrg &&
                        <ReferenceInput
                            source='orgId'
                            reference='organizations'
                            label={'resource.organization'}
                            className={classes.inputField}
                            perPage={10000}
                        >
                            <SelectInput optionText='name' />
                        </ReferenceInput>
                    }

                    <ReferenceInput
                        source='integrationId'
                        reference='integrations'
                        label={translate('resource.integration')}
                        validate={required()}
                        className={classes.inputField}
                        perPage={10000}
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>

                    <ReferenceInput
                        source='deviceTypeId'
                        reference='device-types'
                        label={translate('resource.deviceType')}
                        validate={required()}
                        onChange={(e: any) => dispatch({ type: 'DeviceTypeId', value: e.target.value })}
                        className={classes.inputField}
                        perPage={10000}
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>

                    <ReferenceInput
                        source='attributeSetDefinitionId'
                        reference='attribute-sets-definition'
                        label={translate('resource.attributeSetDefinition')}
                        onChange={(e: any) => dispatch({ type: 'AttributeSetDefinitionId', value: e.target.value })}
                        allowEmpty
                        className={classes.inputField}
                        perPage={10000}
                    >
                        <SelectInput emptyText='-' optionText='name' allowEmpty emptyValue={null} />
                    </ReferenceInput>

                    <TextInput
                        source="deviceKey"
                        label={translate('device.deviceKey')}
                        validate={required()}
                        className={classes.inputField}
                    />

                    <BooleanInput source='active' label={translate('device.active')} defaultValue={false} />

                </TiaDiv>

                <TiaDiv className={classes.column}></TiaDiv>

                <TiaDiv className={classes.geoMap}>
                    {
                        showGeoLocInput && <GeoLocInput
                            lat={formData?.attributes?.latitude}
                            lng={formData?.attributes?.longitude}
                            onChange={(value: GeoLocation) => {
                                setTimeout(() => {
                                    form.change(
                                        'attributes',
                                        {
                                            ...formData.attributes,
                                            latitude: value.lat,
                                            longitude: value.lng,
                                        },
                                    );
                                });
                            }}
                        />
                    }
                </TiaDiv>


                <TiaDiv className={classes.attributesTable}>
                    <AttributesTableRa
                        source='attributes'
                        attributeDefinitions={attributeSpecifications}
                        onlyMandatory={false}
                        emitOnInit={true}
                        validate={(attributes: Attributes) => validateAttributes(attributes, attributeSpecifications)}
                    />
                </TiaDiv>

            </TiaDiv>
            <TiaDiv Div className={classes.alarmDefinitions}>
                <JsonEditorRa
                    source='alarmDefinitions'
                    label='deviceType.alarmDefinitions'
                />
            </TiaDiv>
        </>
    );
};

export default DeviceForm;
