import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { authProvider, Permissions } from '../../authProvider';

interface OrgFieldProps {
    label: string;
	displayFn: (record: any) => boolean;
    [key: string]: any;
}

export const OrgField = (props: OrgFieldProps) => {
	const { orgLabel, record, displayFn } = props;
	if (!displayFn) throw new Error('displayFn not provided');
	if (displayFn(record)) {
		return <ReferenceField {...props} label={orgLabel} source='orgId' reference='organizations' link={ConditionalLink('organizations')} >
			<TextField label='general.name' source='name' />
		</ReferenceField>;
	} else {
		return <div>/</div>;
	}
};

export const ConditionalLink = (resource: string) => {
    const permissions: Permissions = authProvider.getPermissionsNow();
    return permissions.hasReadRight(resource) ? 'show' : '';
};