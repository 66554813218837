import { useRef } from 'react';
import { PlatformSettingsKey } from '../../App';

let platformSettingsMemo: any;
function getPlatformSettings() {
	if (!platformSettingsMemo) {
		platformSettingsMemo = JSON.parse(localStorage.getItem(PlatformSettingsKey) || '');
	}
	return platformSettingsMemo;
}

// TODO: type for platformSettings
const usePlatformSettings = () => {
	const platformSettings = useRef(getPlatformSettings());
	return platformSettings.current;
};

export default usePlatformSettings;