import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Helmet } from 'react-helmet';
import './App.css';
import { authProvider, Permissions } from './authProvider';
import { ApiRoutes, EnglishTranslationsKey } from './constants';
import { customRoutes } from './customRoutes';
import dataProvider from './dataProvider';
import { CustomLayout } from './layout/Layout';
import NotFound from './layout/NotFound';
import LoginPage from './LoginPage';
import Overview from './overview/Overview';
import { allowedViews } from './resources/helpers/user-right-helper';
import usePlatformSettings from './resources/platform-settings/usePlatformSettings';
import * as views from './resources/resources';
import { useThemeController } from './theme';
import { createTranslationProvider } from './translationProvider';

export const PlatformSettingsKey = 'platform-settings-key';

export const App = () => {
  const platformSettings = usePlatformSettings();
  const theme = useThemeController();
  const translationProvider = createTranslationProvider(JSON.parse(localStorage.getItem(EnglishTranslationsKey) as string), platformSettings);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{platformSettings.name}</title>
        <link rel="shortcut icon" type="image/x-icon" href={platformSettings.favicon ? platformSettings.favicon : ''}></link>
      </Helmet>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={LoginPage}
        layout={CustomLayout}
        i18nProvider={translationProvider}
        title='Title should come from Platform Settings'
        theme={theme}
        dashboard={Overview}
        catchAll={NotFound}
        customRoutes={customRoutes}
      >
        {(permissions: Permissions) => {
          if (!permissions) return null;
          return [
            <Resource name={ApiRoutes.Organization} {...allowedViews(permissions, views.organizationViews, ApiRoutes.Organization)} />,
            //ToDO: Don't forget to add @UseGuards(AuthGuard('jwt'), new HttpRoleGuard($correspondingResource)) to the corresponding controller when adding new resources
            <Resource name={ApiRoutes.User} {...allowedViews(permissions, views.userViews, ApiRoutes.User)} />,
            <Resource name={ApiRoutes.Integration} {...allowedViews(permissions, views.integrationViews, ApiRoutes.Integration)} />,
            <Resource name={ApiRoutes.IntegrationType} {...allowedViews(permissions, views.integrationTypeViews, ApiRoutes.IntegrationType)} />,
            <Resource name={ApiRoutes.DeviceType} {...allowedViews(permissions, views.deviceTypeViews, ApiRoutes.DeviceType)} />,
            <Resource name={ApiRoutes.AttributeSets} {...allowedViews(permissions, views.attributeSetDefinitionViews, ApiRoutes.AttributeSets)} />,
            <Resource name={ApiRoutes.Translation} {...allowedViews(permissions, views.translationViews, ApiRoutes.Translation)} />,
            <Resource name={ApiRoutes.TranslationLanguage} />,
            <Resource name={ApiRoutes.Device} {...allowedViews(permissions, views.deviceViews, ApiRoutes.Device)} />,
            <Resource name={ApiRoutes.DeviceGroup} {...allowedViews(permissions, views.deviceGroupViews, ApiRoutes.DeviceGroup)} />,
            permissions.isOrg() ? undefined : <Resource name={ApiRoutes.PlatformSettings} {...allowedViews(permissions, views.platformSettingsViews, ApiRoutes.PlatformSettings)} />,
            <Resource name={ApiRoutes.UserRole} {...allowedViews(permissions, views.userRoleViews, ApiRoutes.UserRole)} />,
            permissions.isOrg() ? undefined : <Resource name={ApiRoutes.SystemLogs} {...allowedViews(permissions, views.systemLogViews, ApiRoutes.SystemLogs)} />,
            <Resource name={ApiRoutes.Alarm} {...allowedViews(permissions, views.alarmViews, ApiRoutes.Alarm)} />,
            <Resource name={ApiRoutes.Messages} {...allowedViews(permissions, views.messageViews, ApiRoutes.Messages)} />,
            <Resource name={ApiRoutes.Telemetry} {...allowedViews(permissions, views.telemetryViews, ApiRoutes.Telemetry)} />,
            <Resource name={ApiRoutes.Dashboard} {...allowedViews(permissions, views.dashboardViews, ApiRoutes.Dashboard)} />,
            <Resource name={ApiRoutes.DashboardType} />,
            <Resource name='dashboard-devices' />,
            <Resource name='dashboard-alarms' />,
          ];
        }}
      </Admin>
    </>
  );
};

