import React from 'react';
import ReactDOM from 'react-dom';
import {App, PlatformSettingsKey} from './app/App';
import {EnglishTranslationsKey} from "./app/constants";
import {BrowserRouter} from "react-router-dom";
import {API_URL} from "./app/_config";

async function startApp() {
    const platformSettings = await (await fetch(`${API_URL}/api/platform-settings/1`)).json();
    const englishTranslations = await (await fetch(`${API_URL}/api/multilinguality/translations/${platformSettings.defaultLanguageId}`)).json();

    localStorage.setItem(PlatformSettingsKey, JSON.stringify(platformSettings));
    localStorage.setItem(EnglishTranslationsKey, JSON.stringify(englishTranslations));

    const app = <BrowserRouter><App /></BrowserRouter>;

    ReactDOM.render(app, document.getElementById('root'));
}

startApp().catch(console.error);
