import axios from '../../api/axios-client';
import { useEffect, useState } from 'react';
import { authProvider } from '../../authProvider';
import { getUserOrgId } from '../../reusable-components/permissions-hooks';

export type DeviceInfo = {
	id: string;
	name: string;
	lastSeen: number;
	longitude: number;
	latitude: number;
	online: boolean;
	hasBattery: boolean;
	batteryPercentage: number | undefined;
	rssi: number;
	snr: number;
	attributes: any,
};

interface OverviewInfo {
	orgName?: string;
	deviceInfo?: {
		totalCt: number;
		onlineCt: number;
		offlineCt: number;
		activeCt: number;
	},
	alarmInfo?: {
		unAckInfoCt: number;
		unAckMinorCt: number;
		unAckMajorCt: number;
		unAckCriticalCt: number;
		lastAlarmTimestamp: string;
	},
	messagesInfo?: {
		count: number;
		comparedToYesterdayPercentage: number;
		last7Days: {
			timestamp: string;
			count: number;
		}[];
	},
	batteriesInfo?: {
		under5Percent: number;
		under10Percent: number;
		under15Percent: number;
		under20Percent: number;
		under25Percent: number;
		under50Percent: number;
		under75Percent: number;
		under100Percent: number;
		externalPower: number;
		unknown: number;
	},
	deviceTypesInfo?: {
		[deviceTypeName: string]: number;
	},
	esp?: any,
	map?: DeviceInfo[];
}

const useOverviewInfo = () => {
	const [stats, setStats] = useState<OverviewInfo>({});
	const accessToken = authProvider.getAccessToken();
	const orgId = getUserOrgId();

	useEffect(() => {
		axios.get(
			'/api/overview',
			{
				params: {
					orgId: orgId
				},
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			},
		)
			.then(({ data }: any) => {
				setStats(data);
			});
	}, []);

	return stats;
};

export default useOverviewInfo;
