import React from 'react';
import { DeviceDownlinkMessage } from '../../../resources/device/downlink/DeviceDownlinkMessage';

interface Props {
    device: any;
}

export const SmartMeteringDeviceDownlink = (props: Props) => {
    const { device } = props;

    if (!device) return null;
    
    return <div style={{padding: '10px'}}>
        <DeviceDownlinkMessage deviceId={device.id} />
    </div>;
};