import { Chip } from '@material-ui/core';
import React from 'react';
import { AutocompleteArrayInput, BulkDeleteButton, Create, Datagrid, DateField, DeleteButton, Edit, EditButton, Filter, Labeled, List, ReferenceArrayInput, ReferenceInput, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, useLocale, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { ApiRoutes } from '../../constants';
import { JsonEditorRa, JsonEditorRaField } from '../../reusable-components/monaco-editor/JsonEditor';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { OrgField } from '../helpers/resource-helper';

const title = <ResourceTitle translationKey='deviceGroups' />;

const DeviceGroupsFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};

const DeviceGroupList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.DeviceGroup);
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			title={title}
			filters={<DeviceGroupsFilter />}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField label='general.name' source='name' />
				{
					!isOrg &&
					<OrgField label = 'resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};

const DeviceGroupCreate = (props: any) => {
	const isOrg = useIsOrg();


	return (
		<Create {...props} title={title}>
			<SimpleForm label='general.name' redirect='show'>
				<TextInput label='general.name' source='name' />
				{
					!isOrg && <ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<CustomArrayAutocompleteInput />
				<JsonEditorRa
					source='alarmDefinitions'
					label='deviceType.alarmDefinitions'
				/>
			</SimpleForm>
		</Create>
	);
};

const DeviceChips = ({ record }: any) => {
	const translate = useTranslate();

	return (
		<>
			<Labeled label={translate('resource.devices')} >
				<span></span>
			</Labeled>
			<br />
			{record.devices.map(item => {
				return (
					<Chip key={item.name} label={item.name} component="a" href={`/#/devices/${item.id}/show`} clickable />
				);
			})}
		</>
	);
};

const DeviceGroupShow = (props: any) => {
	const locale = useLocale();
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.DashboardType);
	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField label='general.id' source='id' />
				<TextField label='general.name' source='name' />
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				{
					!isOrg &&
					<Labeled label='resource.organization'>
						<OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
					</Labeled>
				}

				<DeviceChips />
				<JsonEditorRaField
						source='alarmDefinitions'
						label='deviceType.alarmDefinitions'
					/>
			</SimpleShowLayout>
		</Show>
	);
};

const CustomArrayAutocompleteInput = () => {
	const devices = useField('devices');
	const parsedDevices = useField('parsedDevices');

	React.useEffect(() => {
		if (!Array.isArray(parsedDevices.input.value)) return;
		devices.input.onChange(parsedDevices.input.value.map(v => ({ id: v })));
	}, [parsedDevices.input.value]);

	React.useEffect(() => {
		if (!Array.isArray(devices.input.value)) return;
		const novi = devices.input.value.map(v => v.id);
		if (JSON.stringify(novi) !== JSON.stringify(parsedDevices.input.value)) {
			parsedDevices.input.onChange(novi);
		}
	}, [devices.input.value]);
	return (<ReferenceArrayInput label='resource.devices' reference='devices' source='parsedDevices'>
			<AutocompleteArrayInput optionText='name' />
		</ReferenceArrayInput>);
};

const DeviceGroupEdit = (props: any) => {
	const isOrg = useIsOrg();

	// TODO: autocomplete array input should display only devices for chosen organization

	return (
		<Edit {...props} title={title}>
			<SimpleForm>
				<TextInput label='general.name' source='name' />
				{
					!isOrg && <ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}
				<CustomArrayAutocompleteInput />
				<JsonEditorRa
					source='alarmDefinitions'
					label='deviceType.alarmDefinitions'
				/>
			</SimpleForm>
		</Edit>
	);
};

export const deviceGroupViews = {
	list: DeviceGroupList,
	create: DeviceGroupCreate,
	show: DeviceGroupShow,
	edit: DeviceGroupEdit
};
