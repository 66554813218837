import { Check, ClearAll } from '@material-ui/icons';
import React from 'react';
import { DateField, Labeled, ReferenceField, Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField, useLocale, useTranslate } from 'react-admin';
import GridToggleButton from '../../reusable-components/GridToggleButton';
import { useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { ConditionalLink, OrgField } from '../helpers/resource-helper';
import { AlarmSeverityField } from './alarm-components';
import AlarmComments from './AlarmComments';

const title = <ResourceTitle translationKey='alarm' />;

const AlarmShow = (props: any) => {
    const translate = useTranslate();
    const locale = useLocale();
    const id: string = props.id;
    const isOrg = useIsOrg();

    return (
        <Show {...props} title={title}>
            <TabbedShowLayout {...props}>
                <Tab label={translate('resource.alarm')}>
                    <SimpleShowLayout>
                        <ReferenceField
                            source='deviceId'
                            reference='devices'
                            label={translate('resource.device')}
                            link={ConditionalLink('devices')}
                        >
                            <TextField label='general.name' source='name' />
                        </ReferenceField>

                        <TextField
                            source='type'
                            label={translate('alarm.type')}
                        />

                        <Labeled label={translate('alarm.severity')}>
                            <AlarmSeverityField source='severity' />
                        </Labeled>

                        <TextField
                            source='occurrences'
                            label={translate('alarm.occurrences')}
                        />

                        <Labeled label={translate('alarm.acknowledged')}>
                            <GridToggleButton
                                source='acknowledged'
                                trueLabel={translate('alarm.action.acknowledged')}
                                falseLabel={translate('alarm.action.acknowledge')}
                                icon={<Check /> as any}
                            />
                        </Labeled>

                        <Labeled label={translate('alarm.cleared')}>
                            <GridToggleButton
                                source='cleared'
                                trueLabel={translate('alarm.action.cleared')}
                                falseLabel={translate('alarm.action.clear')}
                                icon={<ClearAll /> as any}
                            />
                        </Labeled>

                        <DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
                        <DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />

                        {
                            !isOrg &&
                            <Labeled label='resource.organization'>
                                <OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
                            </Labeled>
                        }

                    </SimpleShowLayout>
                </Tab>
                <Tab label={translate('resource.alarmComments')} path='log'>
                    <SimpleShowLayout>
                        <AlarmComments alarmId={id} />
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default AlarmShow;