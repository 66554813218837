import jsonExport from 'jsonexport/dist';
import _ from 'lodash';
import { downloadCSV } from 'react-admin';
import { ApiRoutes } from '../../constants';

const joinData = async (data: any[], joinSettings: ExporterJoin[], fetchRelatedRecords: any) => {
	const relatedResources = await Promise.all(
		joinSettings.map(({ foreignKey, resource }: ExporterJoin) => fetchRelatedRecords(data, foreignKey, resource)),
	);
	return data.map((row: any) => {
		const relatedData: any = {};
		joinSettings.forEach((value, i) => {
			const relatedId = row[value.foreignKey];
			const relatedResource = relatedResources[i];
			relatedData[value.property] = relatedResource[relatedId];
		});
		// NOTE: convert telemetry value to number
		row.telemetry.value = Number(row.telemetry.value);
		return {
			...row,
			...relatedData,
		};
	});
};

const mapData = (data: any[], mapperSettings: ExporterMapperItem[]) => {
	return data.map((row: any) => {
		return mapperSettings
			.map(({ name, field }: ExporterMapperItem) => [name, _.get(row, field)])
			.reduce((acc: any, [name, value]: any) => {
				acc[name] = value;
				return acc;
			}, {});
	});
};

/**
 * Example usage:
 * 
 * <List exporter={createExporter(
 *              [ { foreignKey: 'integrationId', resource: ApiRoutes.Integration, property: 'integration} ],
 *              [ { field: 'name', name: translate('general.name') }, { field: 'integration.name', name: 'Integration name' } ],
 *          )}
 *         ...
 */
export const createExporter = (joinSettings: ExporterJoin[], mapperSettings: ExporterMapperItem[]) => {
	return async (data: any[], fetchRelatedRecords: any, resource: string, rowDelimiter = ';', decimalComaSeparator?: boolean) => {
		const joinedData = await joinData(data, joinSettings, fetchRelatedRecords);
		const mappedData = mapData(joinedData, mapperSettings);
		const options = {
			rowDelimiter,
			typeHandlers: decimalComaSeparator ? {
				Number: (num: number) => String(num).replace('.', ',')
			} : undefined
		};
		jsonExport(mappedData, options, (_err: any, csv: any) => downloadCSV(csv, resource));
	};
};

export interface ExporterJoin {
	foreignKey: string;
	resource: ApiRoutes;
	property: string;
}

export interface ExporterMapperItem {
	field: string;
	name: string;
};