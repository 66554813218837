import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import BarChart from '../../../reusable-components/charts/BarChart';
import ChartContainer from './ChartContainer';

interface AlarmsCountByTypeChartProps {
    labels: string[];
    backgroundColors: string | string[];
    data: number[];
}


const AlarmsCountByTypeChart = (props: AlarmsCountByTypeChartProps) => {
    const { labels, backgroundColors, data } = props;
    const translate = useTranslate();
    const locale = useLocale();

    return (<ChartContainer label={translate('alarm.alarmTypes')}>
        {data && (
            <BarChart
                label={translate('alarm.alarmTypes')}
                labels={labels}
                backgroundColor={backgroundColors}
                data={data}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default AlarmsCountByTypeChart;