import { FormControlLabel, ListItemIcon, makeStyles, Switch, Theme, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import Axios from '../api/axios-client';
import React, { forwardRef } from 'react';
import { useLogout, UserMenu, useSetLocale, useTranslate } from 'react-admin';
import { authProvider } from '../authProvider';
import { useCustomTheme } from '../theme';

const useStyles = makeStyles((theme: Theme) => {
	return {
		text: {
			padding: '10px',
			textAlign: 'center',
			color: theme.palette.type === 'dark' ? '#DEDEDE' : '#252525',
		},
	};
});

const Menu = forwardRef((props: any, ref: any) => {
	const theme = useCustomTheme();
	const handleDarkThemeToggle = (dark: boolean) => dark ? theme.setDarkTheme() : theme.setLightTheme();
	const translate = useTranslate();
	const logout = useLogout();
	const handleClick = () => logout();
	const setLocale = useSetLocale();
	const classes = useStyles();
	const permissions = authProvider.getPermissionsNow();
	const username = permissions.getUsername();

	const [languages, setLanguages] = React.useState(['']);
	const jwt = authProvider.getAccessToken();
	React.useEffect(() => {
		Axios({
			method: 'get',
			url: '/api/user-menu/languages',
			headers: { Authorization: `Bearer ${jwt}` }
		})
			.then(({ data }: any) => setLanguages(data));
	}, []);

	return (
		<>
			<div className={classes.text}>
				{username}
			</div>
			<div className={classes.text}>
				{`${translate('platform.version')}: 2.0.21`}
			</div>
			<MenuItem>
				<FormControlLabel
					control={<Switch
						checked={theme.settings.dark}
						onChange={e => handleDarkThemeToggle(e.target.checked)}
					/>}
					label={translate('general.darkTheme')}
				/>
			</MenuItem>
			<MenuItem disableRipple>
				<div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
					{
						languages?.includes('EN') ?
							<img
								title="EN"
								src={'/assets/flags/en.png'}
								onClick={(e) => setLocale('EN')}>
							</img> : ''
					}
					{
						languages?.includes('HR') ?
							<img
								title="HR"
								src={'/assets/flags/hr.png'}
								onClick={() => setLocale('HR')}>
							</img> : ''
					}
					{/* TODO: add support for serbian */}
					{/* <img
					title="SR Latin"
					src={'/assets/flags/sr-latn.png'}
					onClick={() => setLocale('SR-LATN')}>
				</img>
				<img
					title="SR Cyrillic"
					src={'/assets/flags/sr-cyrl.png'}
					onClick={() => setLocale('SR-CYRL')}>
				</img> */}
				</div>
			</MenuItem>
			<MenuItem
				onClick={handleClick}
				ref={ref}
			>
				<ListItemIcon>
					<ExitIcon />
				</ListItemIcon>
				<Typography variant="inherit">{translate('general.logout')}</Typography>
			</MenuItem>
		</>
	);
});

const CustomUserMenu = (props: any) => (
	<UserMenu {...props} logout={<span />}>
		<Menu />
	</UserMenu>
);

export default CustomUserMenu;
