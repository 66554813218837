import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import {
	Add as AddIcon, DragHandle as DragHandleIcon, Remove as RemoveIcon
} from '@material-ui/icons';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SmartMeteringRaColumn } from './smart-metering-columns';
import { getSmartMeteringColumnLabel, SmartMeteringColumn } from './smart-metering-export-columns';

// TODO: 'missing translation for key' errors because translate throws when translation key is missing

const useStyles = makeStyles(theme => {
    return {
        exportColumnList: {
            marginBottom: 10,
            minWidth: 298
        },
        exportColumn: {
            display: 'flex',
            alignItems: 'center',
            '&>:nth-child(2)': {
                textAlign: 'right',
                'min-width': 30
            },
            '&>:nth-child(3)': {
                width: 200,
                'max-width': 200,
                margin: '0 10px 0 10px'
            },
            padding: 10,
            marginBottom: 7,
            backgroundColor: theme.palette.type === 'dark' ? '#303030': '#EEEEEE',
        },
    };
});

interface SmartMeteringEditDialogProps {
    open: boolean;
    onClose: (result?: SmartMeteringColumn[]) => void;
    columns: SmartMeteringColumn[];
    attributeDefinitions: any[];
}

export const SmartMeteringEditDialog = (props: SmartMeteringEditDialogProps) => {
    const { open, onClose, attributeDefinitions } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const [columns, setColumns] = useState<SmartMeteringColumn[]>(props.columns);
    const [unusedColumns, setUnusedColumns] = useState<SmartMeteringColumn[]>([]);

    useEffect(() => {
        setUnusedColumns([
            ...(Object.keys(SmartMeteringRaColumn) as SmartMeteringColumn[])
                .filter(column => columns.indexOf(column) === -1),
            ...(attributeDefinitions
                .map((definition: any) => `attributes.${definition.name}`)
                .filter((column: string) => columns.indexOf(column as any) === -1) as SmartMeteringColumn[]),
            
        ]);
    }, [columns, attributeDefinitions]);

    return (
        <Dialog
            open={open}
            onClose={() => onClose() }
            aria-labelledby='form-dialog-title'
        >
            <DialogTitle id='form-dialog-title'>{translate('ra.action.edit')}</DialogTitle>
            <DialogContent>
                <DragDropContext
                    onDragEnd={(result: any) => {
                        if (result.destination) {
                            const newColumns = [...columns];

                            const [movedColumn] = newColumns.splice(result.source.index, 1);
                            newColumns.splice(result.destination.index, 0, movedColumn);

                            setColumns(newColumns);
                        }
                    }}
                >
                    <Droppable droppableId='droppable'>
                        {(provided: any, snapshot: any) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classes.exportColumnList}
                            >
                                {columns.map((column, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={(index + 1).toString()}
                                        index={index}
                                    >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Paper elevation={1} className={classes.exportColumn}>
                                                    <DragHandleIcon />
                                                    <div>{index + 1}.</div>
                                                    {
                                                        <Select
                                                            value={column}
                                                            onChange={event => {
                                                                const newValue: SmartMeteringColumn = event.target.value as any;
                                                                const newColumns = [...columns];
                                                                newColumns.splice(index, 1, newValue);
                                                                setColumns(newColumns);
                                                            }}
                                                        >
                                                            {[column, ...unusedColumns]
                                                                .map(column => (
                                                                    <MenuItem
                                                                        key={column}
                                                                        value={column}
                                                                    >
                                                                        {
                                                                            translate(
                                                                                getSmartMeteringColumnLabel(
                                                                                    column,
                                                                                    attributeDefinitions,
                                                                                    'Telemetry value'  // TODO: hardcoded telemetry name
                                                                                )
                                                                            )
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    }
                                                    <Button
                                                        onClick={() => {
                                                            const newColumns = [...columns];
                                                            newColumns.splice(index, 1);
                                                            setColumns(newColumns);
                                                        }}
                                                    >
                                                        <RemoveIcon /> {translate('ra.action.delete')}
                                                    </Button>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {unusedColumns.length > 0 && (
                    <Button
                        onClick={() => setColumns([...columns, unusedColumns[0]])}
                    >
                        <AddIcon /> {translate('ra.action.add')}
                    </Button>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                >
                    {translate('ra.action.cancel')}
                </Button>
                <Button
                    onClick={() => onClose(columns)}
                >
                    {translate('ra.action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};