import React from 'react';
import { useTranslate } from 'react-admin';
import TimeSeriesChart from '../../../reusable-components/charts/TimeSeriesChart';
import { TimeResolution } from '../../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface AlarmsCountHistoryChartProps {
    label: string;
    data: any;
    locale: string;
    timeResolution?: TimeResolution;
}


const AlarmsCountHistoryChart = (props: AlarmsCountHistoryChartProps) => {
    const { label, data, timeResolution, locale } = props;
    const translate = useTranslate();
    const theme = useCustomTheme();

    return (<ChartContainer label={label}>
        {data && (
            <TimeSeriesChart
                label={translate('device.alarmCount')}
                backgroundColor={theme.settings.primaryColor}
                timeScaleUnit={timeResolution as any}
                datasets={data}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default AlarmsCountHistoryChart;