/* eslint-disable react-hooks/rules-of-hooks */
import { Check, ClearAll } from '@material-ui/icons';
import React from 'react';
import { DateField, ReferenceField, TextField, useLocale, useTranslate } from 'react-admin';
import { AlarmSeverityField } from '../../resources/alarm/alarm-components';
import { ConditionalLink } from '../../resources/helpers/resource-helper';
import GridToggleButton from '../../reusable-components/GridToggleButton';


export const PredefinedColumns = {
    'deviceId': {
        source: 'deviceId',
        label: 'resource.device',
        cell: (props: any) => {
            return (
                <ReferenceField
                    {...props}
                    source='deviceId'
                    reference='devices'
                    label='resource.device'
                    link={ConditionalLink('devices')}
                >
                    <TextField label='general.name' source='name' />
                </ReferenceField>
            );
        },
        exportField: 'device.name',
    },
    'type': {
        source: 'type',
        label: 'alarm.type',
        cell: (props: any) => {
            return (
                <TextField
                    {...props}
                    source='type'
                    label='alarm.type'
                />
            );
        },
        exportField: 'type',
    },
    'severity': {
        source: 'severity',
        label: 'alarm.severity',
        cell: (props: any) => {
            return (
                <AlarmSeverityField
                    {...props}
                    source='severity'
                    label='alarm.severity'
                />
            );
        },
        exportField: 'severity',
    },
    'occurrences': {
        source: 'occurrences',
        label: 'alarm.occurrences',
        cell: (props: any) => {
            return (
                <TextField
                    {...props}
                    source='occurrences'
                    label='alarm.occurrences'
                />
            );
        },
        exportField: 'occurrences',
    },
    'acknowledged': {
        source: 'acknowledged',
        label: 'alarm.acknowledged',
        cell: (props: any) => {
            const translate = useTranslate();
            return (
                <GridToggleButton
                    {...props}
                    source='acknowledged'
                    label='alarm.acknowledged'
                    trueLabel={translate('alarm.action.acknowledged')}
                    falseLabel={translate('alarm.action.acknowledge')}
                    icon={<Check/>}
                />
            );
        },
        exportField: 'acknowledged',
    },
    'cleared': {
        source: 'cleared',
        label: 'alarm.cleared',
        cell: (props: any) => {
            const translate = useTranslate();
            return (
                <GridToggleButton
                    {...props}
                    source='cleared'
                    label='alarm.cleared'
                    trueLabel={translate('alarm.action.cleared')}
                    falseLabel={translate('alarm.action.clear')}
                    icon={<ClearAll/>}
                />
            );
        },
        exportField: 'cleared',
    },
    'createdAt': {
        source: 'createdAt',
        label: 'general.createdAt',
        cell: (props: any) => {
            const locale = useLocale();
            return (
                <DateField
                    {...props}
                    source='createdAt'
                    label='general.createdAt'
                    showTime
                    locales={locale}
                />
            );
        },
        exportField: 'createdAt',
    },
    'updatedAt': {
        source: 'updatedAt',
        label: 'general.updatedAt',
        cell: (props: any) => {
            const locale = useLocale();
            return (
                <DateField
                    {...props}
                    source='updatedAt'
                    label='general.updatedAt'
                    showTime
                    locales={locale}
                />
            );
        },
        exportField: 'updatedAt',
    },
    'device.deviceKey': {
        source: 'device.deviceKey',
        label: 'device.deviceKey',
        cell: (props: any) => {
            return (
                <TextField
                    {...props}
                    source='device.deviceKey'
                />
            );
        },
        exportField: 'device.deviceKey',
    },
};

export type SmartMeteringAlarmColumn = keyof typeof PredefinedColumns;


// TODO: extract this with smart metering columns to generic functions

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const SmartMeteringAttributeCell = ({ column, ...props}: { column: string }) => {
    return (
        <TextField {...props} source={column} />
    );
};

export const SmartMeteringCell = ({column, attributeDefinitions, ...props}: { column: SmartMeteringAlarmColumn, attributeDefinitions?: any[] }) => {
    if (PredefinedColumns[column]) {
        const CellComponent = PredefinedColumns[column].cell;
        return <CellComponent column={column} {...props} />;
    } else {
        return <SmartMeteringAttributeCell column={column} {...props} />;
    }
};

export const getSmartMeteringColumnLabel = (column: SmartMeteringAlarmColumn, attributeDefinitions: any[]) => {
    if (PredefinedColumns[column]) {
        return PredefinedColumns[column].label;
    }

    // device.attribute.something
    const parts = column.split('.');
    if (parts.length > 1) {
        const attributeName: string = parts[parts.length - 1];
        const attributeDefinition: any | undefined = attributeDefinitions
            .find(definition => definition.name === attributeName);
        if (attributeDefinition && attributeDefinition.displayName) {
            return attributeDefinition.displayName;
        } else {
            return capitalizeFirstLetter(attributeName);
        }
    }
    return '';
};