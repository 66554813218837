import { makeStyles } from '@material-ui/core';
import { ControlledEditor } from '@monaco-editor/react';
import React, { useState } from 'react';
import { Labeled, useInput } from 'react-admin';
import { useCustomTheme } from '../../theme';

interface JsEditorProps {
	value?: string;
	onChange?: (value: string) => void;
	readonly?: boolean;
	style?: any;
}

export const JsEditor = ({ value: defaultValue, onChange, readonly, style }: JsEditorProps) => {
	const theme = useCustomTheme();
	const [value] = useState<string>(defaultValue || '');

	const updateValue = (newValue: string | undefined) => {
		if (newValue == undefined) return;
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div style={{ height: '300px', padding: '5px', ...style }}>
			<ControlledEditor
				theme={theme.settings.dark ? 'dark' : 'light'}
				language='javascript'
				value={value}
				onChange={(e: any, value: string | undefined) => updateValue(value)}
				options={{ readOnly: readonly }}
			/>
		</div>
	);
};

interface JsEditorPropsRaProps {
	source: string;
	record?: any;
	validate?: any;
	style?: any;
	label?: string;
}

const useStyles = makeStyles({
	// dirty hack because Labeled just passes style to its child
	label: {
		'& > *': {
			width: '100%',
		},
	},
});

export const JsEditorRa = (props: JsEditorPropsRaProps) => {
	const {
		input: { value, onChange },
	} = useInput(props);
	const classes = useStyles();

	return (
		<div className={classes.label}>
			<Labeled label={props.label || ' '}>
				<JsEditor
					value={value || ''}
					onChange={onChange}
					style={props.style}
				/>
			</Labeled>
		</div>
	);
};

export const JsEditorRaField = (props: JsEditorPropsRaProps) => {
	const { record, source, label } = props;
	const classes = useStyles();

	return (
		<div className={classes.label}>
			<Labeled label={label || ' '}>
				<JsEditor
					value={record[source] || ''}
					readonly={true}
					style={props.style}
				/>
			</Labeled>
		</div>
	);
};