import { FormControl, InputLabel, MenuItem, Select, TextField as MaterialTextField } from '@material-ui/core';
import { Check, ClearAll } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { BooleanField, BulkDeleteButton, Datagrid, DateField, DeleteButton, Filter, List, ReferenceField, ReferenceInput, SelectInput, ShowButton, TextField, useLocale, useTranslate } from 'react-admin';
import { ApiRoutes } from '../../constants';
import GridToggleButton from '../../reusable-components/GridToggleButton';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { PaginationProps } from '../helpers/paginationProps';
import { ConditionalLink, OrgField } from '../helpers/resource-helper';
import { AlarmSeverityField } from './alarm-components';

const title = <ResourceTitle translationKey='alarms' />;

// TODO: alarm comments icon if alarm has comments

export const AlarmFilters = (props: any) => {
	const { setFilters } = props;
	const [q, setQ] = useState('');
	const [displayType, setDisplayType] = useState('active');
	const translate = useTranslate();
	const isOrg = useIsOrg();

	useEffect(() => {
		if (!setFilters) return;
		const filters: any = {};
		if (q) filters.q = q;
		if (displayType) {
			switch (displayType) {
				case 'active':
					filters.cleared = false;
					break;
				case 'acknowledged':
					filters.acknowledged = true;
					break;
				case 'unacknowledged':
					filters.acknowledged = false;
					break;
			}
		}
		setFilters(filters);
	}, [q, displayType]);

	return (
		<Filter {...props}>
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
			<MaterialTextField
				label={translate('ra.action.search')}
				value={q}
				onChange={(event: any) => setQ(event.target.value)}
				// alwaysOn
			/>
			<FormControl
				// alwaysOn
			>
				<InputLabel htmlFor='alarmFilterDisplayType'>
					{translate('alarm.filter.display')}
				</InputLabel>
				<Select
					value={displayType}
					onChange={(e: any) => setDisplayType(e.target.value)}
					inputProps={{
						id: 'alarmFilterDisplayType'
					}}
					style={{ minWidth: '160px' }}
				>
					<MenuItem value='active'>
						{translate('alarm.filter.active')}
					</MenuItem>
					<MenuItem value='acknowledged'>
						{translate('alarm.filter.acknowledged')}
					</MenuItem>
					<MenuItem value='unacknowledged'>
						{translate('alarm.filter.unacknowledged')}
					</MenuItem>
					<MenuItem value='all'>
						{translate('alarm.filter.all')}
					</MenuItem>
				</Select>
			</FormControl>
		</Filter>
	);
};

export const AlarmDatagrid = (props: any) => {
	const translate = useTranslate();
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Alarm);
	const isOrg = useIsOrg();

	return (
		<Datagrid {...props}>

			<ReferenceField
				source='deviceId'
				reference='devices'
				label={'resource.device'}
				link={ConditionalLink('devices')}
			>
				<TextField label='general.name' source='name' />
			</ReferenceField>

			<TextField
				source='type'
				label={'alarm.type'}
			/>

			<AlarmSeverityField
				source='severity'
				label={'alarm.severity'}
			/>

			<TextField
				source='occurrences'
				label={'alarm.occurrences'}
			/>

			<GridToggleButton
				source='acknowledged'
				label={'alarm.acknowledged'}
				trueLabel={translate('alarm.action.acknowledged')}
				falseLabel={translate('alarm.action.acknowledge')}
				icon={<Check /> as any}
			/>

			<GridToggleButton
				source='cleared'
				label={'alarm.cleared'}
				trueLabel={translate('alarm.action.cleared')}
				falseLabel={translate('alarm.action.clear')}
				icon={<ClearAll /> as any}
			/>

			<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
			<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />

			{
				!isOrg &&
				<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
			}

			{
				!isOrg &&
				<BooleanField label='alarm.orgVisible' source='orgVisible' />
			}

			<ShowButton />
			{writeRights && <DeleteButton undoable={false} />}

		</Datagrid>
	);
};

const AlarmList = (props: any) => {
	const { writeRights } = useHasRights(ApiRoutes.Alarm);
	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<AlarmFilters />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<AlarmDatagrid {...props} />
		</List>
	);
};

export default AlarmList;