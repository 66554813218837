import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import DateRangePicker from '../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../theme';
import AlarmsCountBySeverityHistoryChart from './charts/AlarmsCountBySeverityHistoryChart';
import AlarmsCountHistoryChart from './charts/AlarmsCountHistoryChart';
import MessagesHistoryChart from './charts/MessagesHistoryChart';
import SignalHistoryChart from './charts/SignalHistoryChart';
import useDeviceShowStats from './useDeviceShowStats';

interface DeviceShowChartsProps {
    device: any;
}

export const DeviceShowCharts = (props: DeviceShowChartsProps) => {
    const { device } = props;
    const translate = useTranslate();
    const { stats, dateRange, setDateRange } = useDeviceShowStats(device.id);
    const [signalHistoryData, setSignalHistoryData] = useState({});
    const theme = useCustomTheme();

    useEffect(() => {
        setSignalHistoryData({
            snrHistory: stats.snrHistory,
            rssiHistory: stats.rssiHistory,
        });
    }, [stats.snrHistory, stats.rssiHistory]);

    return (
        <div>
            <DateRangePicker
                label={translate('datePeriod.period')}
                onChange={value => setDateRange(value)}/>
            <div style={{marginTop: '10px',
                    marginRight: '10px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridColumnGap: '10px',
                    '@media (maxWidth:1200px)': {
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridRowGap: '10px'
                    }
            } as any}>

                <MessagesHistoryChart
                    label={translate('resource.messages')}
                    data={stats.messagesHistory}
                    timeResolution={dateRange && dateRange.timeResolution}
                    fill={true}
                    locale={'en'}
                />
                <SignalHistoryChart
                    label={'RSSI / SNR'}
                    data={signalHistoryData as any}
                    timeResolution={dateRange && dateRange.timeResolution}
                    locale={'en'}
                />

                <AlarmsCountHistoryChart
                    label={translate('resource.alarms')}
                    data={
                        [{'label': translate('device.alarmCount'),
                            data: stats.alarmsCountHistory,
                            'borderColor': theme.settings.primaryColor,
                            'pointRadius':0,
                            'fill':false,
                            'lineTension':0,
                            'borderWidth':2}]
                    }
                    timeResolution={dateRange && dateRange.timeResolution}
                    locale={'en'}
                />
                <AlarmsCountBySeverityHistoryChart
                    label={translate('alarm.alarmSeverities')}
                    data={stats.alarmsCountBySeverityHistory}
                    timeResolution={dateRange && dateRange.timeResolution}
                    locale={'en'}
                />
            </div>
        </div>
    );
};