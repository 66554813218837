import React from 'react';
import { List, Create, Edit, SimpleForm, Datagrid, TextField, TextInput, EditButton, DeleteButton, ReferenceField, AutocompleteInput, ReferenceInput, Filter, BulkDeleteButton } from 'react-admin';
import { useBlankReferenceFieldHelper } from './helpers/blank-reference-field-helper';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { PaginationProps } from './helpers/paginationProps';
import { useHasRights } from '../reusable-components/permissions-hooks';
import { ApiRoutes } from '../constants';
import { ConditionalLink } from './helpers/resource-helper';

const title = <ResourceTitle translationKey='translations' property='key' />;

const TranslationFilter = (props: any) => {
	return (
		<Filter {...props}>
			<TextInput label="ra.action.search" source="q" alwaysOn />
		</Filter>
	);
};

const TranslationList = (props: any) => {
	const { writeRights } = useHasRights(ApiRoutes.Translation);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<TranslationFilter /> }
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField label='translation.key' source='key' />
				<ReferenceField label='general.language' source='translationLanguageId' reference={ApiRoutes.TranslationLanguage} link={ConditionalLink(ApiRoutes.TranslationLanguage)}>
					<TextField source='id' />
				</ReferenceField>
				<TextField label='translation.value' source='value' />
				{ writeRights && <EditButton /> }
				{ writeRights && <DeleteButton undoable={false} /> }
			</Datagrid>
		</List>
	);
};

const TranslationCreate = (props: any) => {
	return (
		<Create {...props} title={title}>
			<SimpleForm redirect='list'>
				<TextInput label='translation.key' source='key' />
				<ReferenceInput label='general.language' source='translationLanguageId' reference={ApiRoutes.TranslationLanguage}
					filterToQuery={(searchQuery: string) => searchQuery ? { id: searchQuery } : undefined}>
					<AutocompleteInput optionText='id' />
				</ReferenceInput>
				<TextInput label='translation.value' source='value' fullWidth />
			</SimpleForm>
		</Create>
	);
};

const TranslationEdit = (props: any) => {
	return (
		<Edit {...props} title={title}>
			<SimpleForm redirect='list'>
				<TextInput label='translation.key' source='key' />
				<ReferenceInput label='general.language' source='translationLanguageId' reference={ApiRoutes.TranslationLanguage}
					filterToQuery={(searchQuery: string) => searchQuery ? { id: searchQuery } : undefined}>
					<AutocompleteInput optionText='id' />
				</ReferenceInput>
				<TextInput label='translation.value' source='value' fullWidth />
			</SimpleForm>
		</Edit>
	);
};

export const translationViews = {
	list: TranslationList,
	create: TranslationCreate,
	edit: TranslationEdit
};
