import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { BooleanInput, BulkDeleteButton, Datagrid, DateField, DeleteButton, EditButton, Filter, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, ShowButton, TextField, TextInput, useLocale, useTranslate } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { activeRowStyle } from '../../reusable-components/grid/rowStyle';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { OrgField, ConditionalLink } from '../helpers/resource-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { DeviceBatteryStatus, DeviceOnlineTag } from './device-fields';
import { Error as ErrorIcon } from '@material-ui/icons';

const title = <ResourceTitle translationKey='devices' />;

const useStyles = makeStyles((theme: Theme) => {
	return {
		booleanInputFilter: {
			color: theme.palette.text.primary,
		},
	};
});

const DeviceFilter = (props: any) => {
	const classes = useStyles();
	const isOrg = useIsOrg();

	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source='q' alwaysOn />
			<ReferenceInput
				source='integrationId||$eq'
				label='resource.integration'
				reference='integrations'
				perPage={10000}
				sort={{ field: 'name', order: 'ASC' }}
			>
				<SelectInput optionText='name' />
			</ReferenceInput>
			<ReferenceInput
				source='deviceTypeId||$eq'
				label='resource.deviceType'
				reference='device-types'
				perPage={10000}
				sort={{ field: 'name', order: 'ASC' }}
			>
				<SelectInput optionText='name' />
			</ReferenceInput>
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
			<TextInput source='deviceKey' label='device.deviceKey' />
			<BooleanInput source='active' label='device.active' defaultValue={true} className={classes.booleanInputFilter} />
			<BooleanInput source='online' label='device.online' defaultValue={true} className={classes.booleanInputFilter} />
			<BooleanInput source='attributesCompleted' label='device.attributesCompleted' defaultValue={true} className={classes.booleanInputFilter} />

		</Filter>
	);
};

export const DeviceList = (props: any) => {
	const translate = useTranslate();
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Device);
	const isOrg = useIsOrg();

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<DeviceFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid rowStyle={activeRowStyle()}>
				<FunctionField
					source='name'
					label='general.name'
                    render={(record: any) =>
                        record.attributesCompleted ? (
                            record.name
                        ) : (
                            <span
                                title={translate('device.mandatoryAttributesNotSpecified')}
                                style={{ display: 'inline-flex', alignItems: 'center' }}
                            >
                                <ErrorIcon style={{ color: 'rgb(238, 125, 96)' }} />
                                <span style={{ marginLeft: '0.5rem' }}>{record.name}</span>
                            </span>
                        )
                    }
                />
				<ReferenceField source='integrationId' reference='integrations' link={ConditionalLink('integrations')}>
					<TextField source='name' />
				</ReferenceField>
				<ReferenceField source='deviceTypeId' reference='device-types' link={ConditionalLink('device-types')} label='resource.deviceType'>
					<TextField source='name' />
				</ReferenceField>
				<FunctionField
					source='online'
					label={translate('device.status')}
					render={(record: any) => <DeviceOnlineTag record={record} />}
				/>
				<FunctionField
					source='batteryPercentage'
					label={'device.battery'}
					render={(record: any) => <DeviceBatteryStatus record={record} />}
				/>
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				{
					!isOrg &&
					<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};