import React from 'react';
import { Datagrid, DateField, Filter, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useLocale } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { ConditionalLink, OrgField } from '../helpers/resource-helper';

const title = <ResourceTitle translationKey='telemetry' />;

const TelemetryFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};

const postRowStyle = () => ({
	height: 43,
});

const TelemetryList = (props: any) => {
	const locale = useLocale();
	const isOrg = useIsOrg();

	useBlankReferenceFieldHelper(ApiRoutes.Device);
	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<TelemetryFilter/>}
			title={title}
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={false}
		>
			<Datagrid rowStyle={postRowStyle}>
				<ReferenceField label='resource.device' source='deviceId' reference='devices' link={ConditionalLink('devices')}>
					<TextField source='name' />
				</ReferenceField>
				<TextField source='name' label='general.name' />
				<TextField source='value' label='general.value' />
				{
					!isOrg &&
					<OrgField label = 'resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
			</Datagrid>
		</List>
	);
};

export const telemetryViews = {
	list: TelemetryList
};
