import { Button, TextField, Theme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { ApiRoutes } from '../../constants';

// TODO: only admin can delete
// TODO: comment edit??


const useCommentsProvider = (alarmId: string) => {
    const [saving, setSaving] = useState(false);
    const [comments, setComments] = useState<any[]>([]);
    const dataProvider = useDataProvider();

    const addComment = useCallback(
        (commentText: string) => {
            setSaving(true);
            dataProvider.create(
                    ApiRoutes.AlarmComment,
                    {
                        data: {
                            alarmId,
                            comment: commentText,
                        },
                    },
                )
                .then((res: any) => dataProvider.getOne(ApiRoutes.AlarmComment, { id: res.data.id }))
                .then((res: any) => {
                    setSaving(false);
                    setComments(comments => [...comments, res.data]);
                })
                .catch(() => setSaving(false));
        },
        [],
    );

    const deleteComment = useCallback(
        (id: string) => {
            dataProvider.update(
                    ApiRoutes.AlarmComment,
                    {
                        previousData: undefined,
                        id,
                        data: { deleted: true }
                    },
                )
                .then((res: any) => {
                    setComments(comments => {
                        comments.find(c => c.id === id).deleted = true;
                        return [...comments];
                    });
                });
        },
        [],
    );

    useEffect(() => {
        dataProvider.getManyReference(
                ApiRoutes.AlarmComment,
                {
                    filter: undefined,
                    pagination: { page: 1, perPage: 10000 },
                    target: 'alarmId',
                    id: alarmId,
                    sort: { field: 'updatedAt', order: 'ASC' }
                },
            )
            .then((res: any) => {
                setComments(res.data);
            });
    }, []);

    return [comments, addComment, deleteComment, saving];
};

const useStyles = makeStyles((
	(theme: Theme) => {
		const dark = theme.palette.type === 'dark';

		return {
				container: {
						padding: '20px'
				},
				comment: {
						display: 'inline-block',
						width: '500px',
						padding: '10px',
						color: dark ? '#FFFFFF': 'rgba(0, 0, 0, 0.75)',
						backgroundColor: dark ? '#525252' : '#EAEAEA',
						borderRadius: '6px',
						marginBottom: '14px'
				},
				header: {
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '10px',
						fontSize: '12px',
						color: dark ? '#D4D4D4' : 'gray'
				},
				email: {
						display: 'inline-block'
				},
				createdAt: {},
				text: {
						display: 'inline-block'
				},
				deleted: {
						fontStyle: 'italic',
						color: '#AFACAC'
				},
				commentButtons: {
						display: 'inline-block'
				},
				newCommentContainer: {
						display: 'flex',
						width: '500px',
						paddingTop: '20px'
				},
				commentField: {
						flexGrow: '1',
						marginRight: '10px'
				}
		};
}
) as any
);

interface AlarmCommentsProps {
    alarmId: string;
}

const AlarmComments = ({ alarmId }: AlarmCommentsProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [comments, addComment, deleteComment, saving] = useCommentsProvider(alarmId);
    const [commentText, setCommentText] = useState('');

    const formatTime = (time: string) => {
        const date = new Date(time);
        return date.toLocaleString();
    };

    useEffect(() => {
        if (!saving) {
            setCommentText('');
        }
    }, [saving]);

    return (
        <div className={classes.container}>
            {(comments as any).map((comment: any) => (
                <div>
                    <div className={classes.comment}>
                        <div className={classes.header}>
                            <div className={classes.email}>{`${comment.user.email}`}</div>
                            <div className={classes.createdAt}>{comment.createdAt}</div>
                        </div>
                        <div className={classes.text}>
                            {comment.deleted ? (
                                <div className={classes.deleted}>{translate('alarmComment.commentDeleted')}</div>
                            ) : (
                                    comment.comment
                                )}
                        </div>
                    </div>
                    {
                        comment.deleted ?
                        null :
                        <div className={classes.commentButtons}>
                            <Button onClick={() => {
															(deleteComment as any)(comment.id);
															}}>
                                {translate('ra.action.delete')}
                            </Button>
                        </div>
                    }
                </div>
            ))}
            <div className={classes.newCommentContainer}>
                <TextField
                    className={classes.commentField}
                    value={commentText}
                    onChange={(event: any) => setCommentText(event.target.value)}
                    disabled={saving as any}
                    onKeyPress={(event: any) => {
                        if (event.key === 'Enter') {
                            (addComment as any)(commentText);
                            event.preventDefault();
                        }
                    }}
                ></TextField>
                <Button
                    variant='contained'
                    type='submit'
                    color='primary'
                    onClick={() => (addComment as any)(commentText)}
                    disabled={saving as any}
                >
                    <AddCircle/>
                    {translate('alarmComment.addEntry')}
                </Button>
            </div>
        </div>
    );
};

export default AlarmComments;
