import { Permissions } from '../../authProvider';
import { ApiRoutes } from '../../constants';

type Views = {
	list?: any,
	show?: any,
	create?: any,
	edit?: any
}


export const allowedViews = (permissions: Permissions, { list, show, create, edit }: Views, resourceType: ApiRoutes) => {
	const viewsForReadOnlyUsers = resourceType === ApiRoutes.User ? { show, list, edit } : { show, list }; // allow user edit view to edit itself
	
	const allViews = { create, edit, show, list };

	// TODO: quick hack to display user settings if user has no users read/write rights..., fix it
	if (resourceType === ApiRoutes.User) return { list, show, create, edit };

	// const { create, edit, ...viewsForReadOnlyUsers } = views
	return (
		permissions.hasWriteRight(resourceType) ? allViews : (permissions.hasReadRight(resourceType) ? viewsForReadOnlyUsers : {})
	);
};
