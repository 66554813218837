//ToDO: put in common directory types that define such objects and get the instance of this type from the controller
export enum ApiRoutes {
    User = 'users',
    Device = 'devices',
    DeviceGroup = 'device-groups',
    Messages = 'messages',
    Telemetry = 'telemetry',
    Alarm = 'alarms',
    DeviceType = 'device-types',
    Integration = 'integrations',
    IntegrationType = 'integration-types',
    AttributeSets = 'attribute-sets-definition',
    VirtualTelemetry = 'virtual-telemetry-definition',
    UserRole = 'user-roles',
		Organization = 'organizations',
    Translation = 'translations',
    TranslationLanguage = 'translation-languages',
    Log = 'log',
    UserSettings = 'user-settings',
    PlatformSettings = 'platform-settings',
    SystemLogs = 'system-logs',
    AlarmComment = 'alarm-comment',
    Dashboard = 'dashboards',
    DashboardType = 'dashboard-types'
};

export const RouteWithTranslationKey = [
    {
        name: ApiRoutes.Alarm,
        translationKey: 'resource.alarms'
    },
    {
        name: ApiRoutes.AttributeSets,
        translationKey: 'resource.attributeSetDefinitions'
    },
    {
        name: ApiRoutes.Organization,
        translationKey: 'resource.organizations'
    },
    {
        name: ApiRoutes.Dashboard,
        translationKey: 'resource.dashboards'
    },
    {
        name: ApiRoutes.Device,
        translationKey: 'resource.devices'
    },
    {
        name: ApiRoutes.DeviceGroup,
        translationKey: 'resource.deviceGroups'
    },
    {
        name: ApiRoutes.DeviceType,
        translationKey: 'resource.deviceTypes'
    },
    {
        name: ApiRoutes.Integration,
        translationKey: 'resource.integrations'
    },
    {
        name: ApiRoutes.IntegrationType,
        translationKey: 'resource.integrationTypes'
    },
    {
        name: ApiRoutes.Log,
        translationKey: 'resource.logs'
    },
    {
        name: ApiRoutes.Messages,
        translationKey: 'resource.messages'
    },
    {
        name: ApiRoutes.PlatformSettings,
        translationKey: 'resource.platformSettings'
    },
    {
        name: ApiRoutes.SystemLogs,
        translationKey: 'resource.systemLogs'
    },
    {
        name: ApiRoutes.Telemetry,
        translationKey: 'resource.telemetry'
    },
    {
        name: ApiRoutes.Translation,
        translationKey: 'resource.translations'
    },
    {
        name: ApiRoutes.User,
        translationKey: 'resource.users'
    },
    {
        name: ApiRoutes.UserRole,
        translationKey: 'resource.userRoles'
    },
];

export const EnglishTranslationsKey = 'englishTranslations';

export const NonOrgSuperAdminId = '4b0cea70-caf0-4378-a421-01e7c33fa004';
