import { Card, CardContent, CardHeader, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Modal, Select, Theme } from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { DeviceInfo } from '../resources/device/useOverviewStats';
import { Pins } from '../reusable-components/maps/Pins';
import { Pin } from '../reusable-components/maps/types';
import { getLegendView, getPinColor, LegendView } from './helper';

const useStyles = makeStyles((theme: Theme) => {
    return {
        mapCardHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },

        mapTitle: {
            fontSize: '1.2rem',
            fontWeight: 300,
            textTransform: 'uppercase',
        },

        colorByInput: {
            marginLeft: 'auto',
            paddingTop: '0.5em',
        },

        fullScreenIcon: {
            height: '48px',
            marginTop: '8px',
            marginLeft: '10px',
        },

        themeTextColor: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            paddingTop: '1em',
        },

        mapCard: {
            gridColumnStart: 'span 2'
        },

        mapCardFullscreen: {
            padding: 20,
            backgroundColor: theme.palette.background.default,
        },

        cardContent: {
            paddingTop: '0em',
            paddingBottom: '0em',
        },
    };
});


const MapCardHeader = ({selectedLegendView, setSelectedLegendView, fullscreen, setFullscreen }: any) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.mapCardHeader}>
            <CardHeader title={translate('overview.mapTitle')} titleTypographyProps={{className:classes.mapTitle}} />
            <FormControl className={classes.colorByInput}>
                <InputLabel className={classes.themeTextColor}>{translate('legendView.colorBy')}</InputLabel>
                <Select
                    value={selectedLegendView}
                    onChange={event => setSelectedLegendView(event.target.value as LegendView)}
                >
                    <MenuItem value={LegendView.Status}>{translate('legendView.status')}</MenuItem>
                    <MenuItem value={LegendView.SignalPower}>{translate('legendView.signalPower')}</MenuItem>
                    <MenuItem value={LegendView.Battery}>{translate('legendView.battery')}</MenuItem>
                </Select>
            </FormControl>
            <IconButton
                className={classes.fullScreenIcon}
                onClick={() => setFullscreen(!fullscreen)}
            >
                {
                    fullscreen ? <FullscreenExit/> : <Fullscreen/>
                }
            </IconButton>
        </div>
    );
};

interface OverviewMapCardProps {
    pins: Pin[];
    elevation: number;
    labelRenderer?: (pin: Pin) => JSX.Element;
    mapHeight?: string;
}

export const OverviewMapCard = (props: OverviewMapCardProps) => {
    const { elevation, labelRenderer, mapHeight } = props;
    const translate = useTranslate();
    const classes = useStyles();
    const [selectedLegendView, setSelectedLegendView] = useState<LegendView>(LegendView.Status);
    const [fullscreen, setFullscreen] = useState(false);
    const [pins, setPins] = useState<Pin[]>();

    useEffect(() => {
        if (!props.pins) return;
        setPins(
            props.pins.map((pin: Pin) => {
                return {
                    ...pin,
                    color: getPinColor(pin.details as DeviceInfo, selectedLegendView),
                };
            }),
        );
    }, [props.pins, selectedLegendView]);

    if (!pins) return null;

    return (
        <Card elevation={elevation}>
            <CardContent className={classes.cardContent}>
                <MapCardHeader
                    fullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                    selectedLegendView={selectedLegendView}
                    setSelectedLegendView={setSelectedLegendView}
                />
            </CardContent>
            <Pins
                pins={pins}
                legendColorLabels={getLegendView(selectedLegendView, translate)}
                labelRenderer={labelRenderer}
                height={mapHeight}
            />
            <Modal open={fullscreen} style={{ height: '100vh' }} hideBackdrop={true}>
                <div className={classes.mapCardFullscreen}>
                    <MapCardHeader
                        fullscreen={fullscreen}
                        setFullscreen={setFullscreen}
                        selectedLegendView={selectedLegendView}
                        setSelectedLegendView={setSelectedLegendView}
                    />
                    <Pins
                        pins={pins}
                        legendColorLabels={getLegendView(selectedLegendView, translate)}
                        autoFocus={true}
                        height={'calc(100vh - 100px)'}
                        labelRenderer={labelRenderer}
                    />
                </div>
            </Modal>
        </Card>
    );
};