import React from 'react';
import { useTranslate } from 'react-admin';
import { getEstimatedSignalPower } from '../../../overview/helper';
import HorizontalBarChart from '../../../reusable-components/charts/HorizontalBarChart';
import ChartContainer from './ChartContainer';

interface EspChartProps {
	label?: string;
	data: any;
	locale: string;
}

export const ESP_COLORS = ['green', 'yellow', 'orange', 'red', 'grey'];

export function getEspLabels(translate: any) {
	return [
		translate('esp.excellent'),
		translate('esp.good'),
		translate('esp.fair'),
		translate('esp.poor'),
		translate('esp.unknown')
	];
}

export function getDeviceEspGroup(espDeviceRelevantData: any) {
	let rssi = null;
	let snr = null;

	if (espDeviceRelevantData) {
		Object.values(espDeviceRelevantData)
		.forEach((attribute:any) => {
			if (attribute.name) {
				if (attribute.name == 'rssi') {
					rssi = parseFloat(attribute.value);
				} else if (attribute.name == 'snr') {
					snr = parseFloat(attribute.value);
				}
			} else {
				return;
			}
		});
	}

	if (rssi && snr && (Number.isFinite(rssi), Number.isFinite(snr))) {
		const esp = getEstimatedSignalPower(rssi, snr) || 0;

		if (esp < -112) {
			return 3; // poor
		}

		if (esp <= -107) {
			return 2; // fair
		}

		if (esp <= -101) {
			return 1; // good
		}

		return 0; // excellent
	}

	return 4; // unknown
}

const EspChart = (props: EspChartProps) => {
	const { label, data, locale } = props;
	const translate = useTranslate();
	const espData = [0, 0, 0, 0, 0];

	if (data) {
		Object.values(data).forEach((espDeviceRelevantData: any) => ++espData[getDeviceEspGroup(espDeviceRelevantData)]);
	}

	return (
		<ChartContainer label={label}>
			{data && (
				<HorizontalBarChart
					label={translate('overview.devicesCount')}
					labels={getEspLabels(translate)}
					backgroundColor={ESP_COLORS}
					data={espData}
					locale={locale}
				/>
			)}
		</ChartContainer>
	);
};

export default EspChart;