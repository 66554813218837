import { default as MaterialTextField } from '@material-ui/core/TextField';
import React from 'react';


// TODO: better UX

interface EnumInputProps {
    value?: string[];
    onChange?: (options: string[]) => void;
    label?: string;
    style?: React.CSSProperties;
}

export const EnumInput = (props: EnumInputProps) => {
    const { value: defaultValue, onChange, label, style } = props;

    const stringToOptions = (value: string): string[] => {
        if (!value) return [];
        return value.split(/[,;]/)
            .map(v => v.trim())
            .filter(v => !!v);
    };

    const optionsToString = (options: string[]): string => {
        if (!options) return '';
        return options.join(', ');
    };

    return <MaterialTextField
        defaultValue={optionsToString(defaultValue)}
        onChange={e => onChange(stringToOptions(e.target.value))}
        label={label}
        style={style}
    />;
};