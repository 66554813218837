import { Divider, Tab, Tabs, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { DateRangeOutput } from '../../reusable-components/DateRangePicker';
import { SmartMeteringAlarmsTab } from './SmartMeteringAlarmsTab';
import { SmartMeteringExportTab } from './SmartMeteringExportTab';
import { SmartMeteringMapTab } from './SmartMeteringMapTab';
import { SmartMeteringStatisticsTab } from './SmartMeteringStatisticsTab';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            color: theme.palette.text.primary,
        },

    };
});

interface SmartMeteringDashboardProps {
    dashboard: any;
}

export const SmartMeteringDashboard = (props: SmartMeteringDashboardProps) => {
    const { dashboard } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const [dateRange, setDateRange] = useState<DateRangeOutput | undefined>(undefined);
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const [activeTab, setActiveTab] = useState('');

    return (
        <Card>
            <Tabs
                value={activeTab}
                indicatorColor='primary'
                onChange={(event, value) => {
                    if (value === '') {
                        history.push(`${match.url}`);
                    } else {
                        history.push(`${match.url}/${value}`);
                    }
                }}
            >
                <Tab
                    label={translate('smartMetering.statistics')}
                    value={``}
                />
                <Tab
                    label={translate('smartMetering.map')}
                    value={`map`}
                />
                <Tab
                    label={translate('smartMetering.devicesAndExport')}
                    value={`export`}
                />
                <Tab
                    label={translate('resource.alarms')}
                    value={`alarms`}
                />
            </Tabs>
            <Divider />
            <CardContent>
                <Switch>                    
                    <Route
                        path={`${match.path}/map`}
                        render={props => {
                            if (activeTab !== 'map') setActiveTab('map');
                            return <SmartMeteringMapTab dashboard={dashboard} />;
                        }}
                    />
                    <Route
                        path={`${match.path}/export`}
                        render={props => {
                            if (activeTab !== 'export') setActiveTab('export');
                            return <SmartMeteringExportTab dashboard={dashboard} />;
                        }}
                    />
                    <Route
                        path={`${match.path}/alarms`}
                        render={props => {
                            if (activeTab !== 'alarms') setActiveTab('alarms');
                            return <SmartMeteringAlarmsTab dashboard={dashboard} />;
                        }}
                    />
                    <Route
                        path={`${match.path}`}
                        render={props => {
                            if (activeTab !== '') setActiveTab('');
                            return <SmartMeteringStatisticsTab dashboard={dashboard} />;
                        }}
                    />
                </Switch>
            </CardContent>
        </Card>
    );
};