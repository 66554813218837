import React, { useState, useEffect } from 'react';
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import axios from '../../api/axios-client';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { useTranslate, useLocale } from 'react-admin';

// TODO: use react admin datatable and add latest-telemetry to resources if sorting is needed

interface DeviceShowLatestTelemetryProps {
    device: any;
}

export const DeviceShowLatestTelemetry = (props: DeviceShowLatestTelemetryProps) => {
    const { device } = props;
    const [data, setData] = useState<any[]>([]);
    const translate = useTranslate();
    const locale = useLocale();

    useEffect(() => {
        axios.get(`/api/device-stats/latest-telemetry/${device.id}`)
            .then(({data}: any) => {
                setData(data);
            });
    }, []);

    return (
        <TableContainer component={Paper} elevation={0} style={{marginBottom: '20px'}}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                <TableRow>
                    <TableCell>{translate('general.name')}</TableCell>
                    <TableCell>{translate('general.value')}</TableCell>
                    <TableCell>{translate('general.updatedAt')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <TableRow key={row.name} hover={true}>
                            <TableCell component="th" scope="row" size="medium">
                                {row.name}
                            </TableCell>
                            <TableCell size="medium">{row.value}</TableCell>
                            <TableCell size="medium">{formatDate(row.t, locale)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function formatDate(dateString: string, locale: string) {
    const date = new Date(dateString);
    return date.toLocaleString(locale);
}

function padNumber(value: number) {
    return value.toString().padStart(2, '0');
}
