import React from 'react';
import { BulkDeleteButton, Create, Datagrid, DateField, DeleteButton, Edit, EditButton, Filter, Labeled, List, ReferenceInput, SaveButton, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, useLocale } from 'react-admin';
import { UserRights } from '../authProvider';
import { UserRoleRightsInput } from '../components/UserRoleRightInput';
import { UserRoleRights } from '../components/UserRoleRights';
import { ApiRoutes, NonOrgSuperAdminId } from '../constants';
import { getUserRoleId, useHasRights, useIsOrg } from '../reusable-components/permissions-hooks';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from './helpers/blank-reference-field-helper';
import { PaginationProps } from './helpers/paginationProps';
import { OrgField } from './helpers/resource-helper';


const title = <ResourceTitle translationKey='userRoles' />;

const UserRoleFilter = (props: any) => {
    const isOrg = useIsOrg();
    return (
        <Filter {...props}>
            <TextInput label={'ra.action.search'} source="q" alwaysOn />
            {
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
        </Filter>
    );
};

interface ConditionalButtonProps {
    displayFn: (record: any) => boolean;
    [key: string]: any;
}

const ConditionalDeleteButton = (props: ConditionalButtonProps) => {
    const { record, displayFn } = props;
    if (!displayFn) throw new Error('displayFn not provided');
    if (displayFn(record)) {
        return <DeleteButton {...props} undoable={false} />;
    }
    return null;
};

const ConditionalEditButton = (props: ConditionalButtonProps) => {
    const { record, displayFn } = props;
    if (!displayFn) throw new Error('displayFn not provided');
    if (displayFn(record)) {
        return <EditButton {...props} undoable={false} />;
    }
    return null;
};

const UserRoleList = (props: any) => {
    const locale = useLocale();
    const { writeRights } = useHasRights(ApiRoutes.UserRole);
    const isOrg = useIsOrg();
    const userRoleId = getUserRoleId();


    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    return (
        <List
            {...props}
            pagination={<PaginationProps />}
            perPage={25}
            filters={<UserRoleFilter />}
            title={title}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
        >
            <Datagrid>
                <TextField label='general.name' source="name" />
                {
                    !isOrg &&
                    <OrgField label = 'resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
                }
                <DateField label='general.createdAt' source="createdAt" showTime locales={locale} />
                <DateField label='general.updatedAt' source="updatedAt" showTime locales={locale} />
                <ShowButton />
                {writeRights && <ConditionalEditButton displayFn={(record: any) => record.id !== (NonOrgSuperAdminId && userRoleId)} />}
                {writeRights && <ConditionalDeleteButton displayFn={(record: any) => record.id !== (NonOrgSuperAdminId && userRoleId)} />}
            </Datagrid>
        </List>
    );
};

const UserRoleShow = (props: any) => {
    const locale = useLocale();
    const isOrg = useIsOrg();

    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    return (
        <Show {...props} title={title}>
            <SimpleShowLayout>
                <TextField label="general.name" source="name" />
                {
                    !isOrg &&
                    <Labeled label='resource.organization'>
						<OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
					</Labeled>
                }
                <DateField label="general.createdAt" source="createdAt" showTime locales={locale} />
                <DateField label="general.updatedAt" source="updatedAt" showTime locales={locale} />

                <TextField label="userRole.userRightsWhitelist" />
                <UserRoleRights source='userRights' />
            </SimpleShowLayout>
        </Show>
    );
};

const UserRoleEdit = (props: any) => {
    const isOrg = useIsOrg();

    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    return (
        <Edit {...props} title={title}>
            <SimpleForm validate={validateUserRoleCreation}>
                <TextInput label='general.name' source="name" />
                {
                    !isOrg &&
                    <ReferenceInput
                        source='orgId'
                        reference='organizations'
                        label={'resource.organization'}
                        perPage={10000}
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>
                }
                <UserRoleRightsInput source='userRights' />
            </SimpleForm>
        </Edit>
    );
};

const UserRoleCreateToolbar = (props: any) => (
    <Toolbar {...props} title={title}>
        <SaveButton label="save" redirect="list" >
        </SaveButton>
    </Toolbar>
);

interface UserRole {
    userRights: UserRights,
    name: string
}

const validateUserRoleCreation = (userRole: any) => {
    const errors = {} as any;

    if (userRole.userRights === undefined) {
        userRole.userRights = {
            read: {},
            write: {}
        };
    }

    if (!userRole.name) {
        errors.name = ['Name can\'t be empty']; //dodati translation za ovo i koristi translation objekt
    }

    if (Object.keys(userRole.userRights.read).every((resource) => !userRole.userRights.read[resource])) {
        errors.emptyUserRoleRights = ['User rights table can\'t be empty'];
    }

    return errors;
};

const UserRoleCreate = (props: any) => {
    const isOrg = useIsOrg();

    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    return (
        <Create {...props} title={title}>
            <SimpleForm toolbar={<UserRoleCreateToolbar />} validate={validateUserRoleCreation} >
                <TextInput source="name" />
                {
                    !isOrg &&
                    <ReferenceInput
                        source='orgId'
                        reference='organizations'
                        label={'resource.organization'}
                        perPage={10000}
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>
                }
                <UserRoleRightsInput source='userRights' />
            </SimpleForm>
        </Create>
    );
};

export const userRoleViews = {
    list: UserRoleList,
    edit: UserRoleEdit,
    show: UserRoleShow,
    create: UserRoleCreate
};
