import React from 'react';
import { useLocale } from 'react-admin';
import ChartContainer from '../../resources/device/charts/ChartContainer';
import { useCustomTheme } from '../../theme';
import { TimeResolution } from '../DateRangePicker';
import TimeSeriesChart from './TimeSeriesChart';

interface TimeSeriesSingleChartProps {
    label: string;
    data: Array<{
        t: Date | string;
        y: number;
    }>;
    timeResolution?: TimeResolution;
    hideTitle?: boolean;
    fill?: boolean;
}


const TimeSeriesSingleChart = (props: TimeSeriesSingleChartProps) => {
    const { label, data, timeResolution, hideTitle, fill } = props;
    const theme = useCustomTheme();
    const locale = useLocale();

    return (<ChartContainer label={label} hideTitle={hideTitle}>
        {data && (
            <TimeSeriesChart
                backgroundColor={theme.settings.primaryColor}
                timeScaleUnit={timeResolution as any}
                datasets={[
                    {
                        label,
                        data,
                        borderColor: theme.settings.primaryColor,
                        backgroundColor: theme.settings.primaryColor.concat('66'),
                        fill,
                    }
                ]}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default TimeSeriesSingleChart;