import { green, red } from '@material-ui/core/colors';

export const activeRowStyle = () => (record: any, index: number, defaultStyle = {}) => {
	const style = defaultStyle;
	return {
		...style,
		borderLeftColor: record.active ? green[500] : red[500],
		borderLeftWidth: 5,
		borderLeftStyle: 'solid',
	};
};