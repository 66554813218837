import { PaletteType } from '@material-ui/core';
import * as L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {LEAFLET_ACCESS_TOKEN} from "../../_config";

L.Icon.Default.imagePath = 'assets/';
L.Icon.Default.prototype.options.iconUrl = 'marker-icon-blue.png';

const MapIdDark = 'mapbox/dark-v9';
const MapIdLight = 'mapbox/streets-v11';

export const initializeLeafletMap = (id: string, theme: PaletteType | undefined) => {
	const map = L.map(
		id,
		// important when there are no devices
		{
			center: [45, 22],
			zoom: 6
		},
	);
	changeLeafletMapStyle(map, theme);
	return map;
};

export const changeLeafletMapStyle = (map: L.Map, theme: PaletteType | undefined) => {
	map.eachLayer((layer: L.Layer) => map.removeLayer(layer));
	L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
		attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
		maxZoom: 22,
		tileSize: 512,
  	zoomOffset: -1,
		id: theme === 'dark' ? MapIdDark : MapIdLight,
		accessToken: LEAFLET_ACCESS_TOKEN
	}).addTo(map);
};

// TODO: extract constants
const LegendWrapper = ({ children }: any) => (
	<div style={{
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		color: 'black',
		borderRadius: 5,
		padding: 10
	}}>
		{children}
	</div>
);

export const createLegend = (position: string, legendContent: JSX.Element) => {
	const legend: L.Control = (L.control as any)({ position });
	legend.onAdd = _ => {
		const div = L.DomUtil.create('div');

		div.innerHTML = ReactDOMServer.renderToString(
			<LegendWrapper>
				{legendContent}
			</LegendWrapper>
		);

		return div;
	};

	return legend;
};

export const ColorLabelLegend = ({ colorsLabels }: { colorsLabels: [string, string][] }) => {
	return (
		<>
			{colorsLabels.map(([color, label], idx) => {
				return (
					<div style={{ marginTop: idx === 0 ? 0 : 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<i style={{ background: color, width: 18, height: 18, marginRight: 10, borderRadius: 2 }}></i>
							{label}
							<br></br>
						</div>
					</div>
				);
			})}
		</>
	);
};

const PinsMap = new Map<string, L.Icon>();

/**
 * @param color same as css color string (rgb(50, 50, 50), #00AEEF, 'yellow', etc.)
 *
 * TODO: bottom part of pin should be location (currently it's image center), fix this
 */
export const getPinIcon = (color: string) => {
	if (PinsMap.has(color)) return PinsMap.get(color);

	const svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 365 560" enable-background="new 0 0 365 560" xml:space="preserve"><g><path fill="${color}" d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9   C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8   c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"/></g></svg>`;
	const iconUrl = encodeURI(`data:image/svg+xml,` + svg).replace('#', '%23');

	const pinIcon = L.icon({
		iconUrl: iconUrl,
		iconSize: [25, 25]
	});

	PinsMap.set(color, pinIcon);

	return pinIcon;
};

const TooltipWrapper = ({ children }: any) => (
	<div style={{
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		color: 'black',
		borderRadius: 5,
		padding: 10
	}}>
		{children}
	</div>
);

export const createTooltip = (element: JSX.Element) => {
	const div = L.DomUtil.create('div');

	div.innerHTML = ReactDOMServer.renderToString(
		<TooltipWrapper>
			{element}
		</TooltipWrapper>
	);

	return div;
};
