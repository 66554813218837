import axios from 'axios';
import {API_URL} from "../_config";

const instance = axios.create();

instance.interceptors.request.use((config) => {
    config.baseURL = API_URL;

    return config;
});

export default instance;

