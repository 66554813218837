import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles({
	root: {
		background: 'black'
	},
	input: {
		color: 'white'
	},
	popover: {
		position: 'relative',
		zIndex: 2
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	}
});

interface HexColorProps {
	value: string;
	label: string;
	onChange: (hexColor: string) => void;	
}

export const HexColorInput = ({ value, label, onChange }: HexColorProps) => {
	const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
	const [hexColor, setHexColor] = React.useState(value ? value : '');

	const classes = useStyles();

	React.useEffect(() => {
		if (onChange) onChange(hexColor);
	}, [hexColor]);

	return (
		<div>
			<TextField label={label} value={hexColor} onClick={_ => setDisplayColorPicker(!displayColorPicker)}
				style={{
					color: hexColor
				}}
				InputProps={{
					style: {
						color: hexColor
					}
				}}
			/>
			{displayColorPicker ? <div className={classes.popover}>
				<div className={classes.cover} onClick={_ => setDisplayColorPicker(false)} />
				<ChromePicker color={hexColor} onChange={colorRes => setHexColor(colorRes.hex)} />
			</div> : null}
		</div>
	);
};
