import { ApiRoutes } from '../constants';
import { DeviceInfo } from '../resources/device/useOverviewStats';

export function getLink(resource: ApiRoutes, filter: any) {
    return `#/${resource}?filter=${encodeURIComponent(JSON.stringify(filter))}`;
}

export enum Severity {
	Info = 'info',
	Minor = 'minor',
	Major = 'major',
	Critical = 'critical'
}

enum deviceStatusColor {
    Online = 'green',
    Offline = 'red',
}

enum deviceBatteryColor {
    LessThan5 = 'red',
    LessThan10 = 'darkOrange',
    LessThan20 = 'yellow',
    TwentyToFull = 'green',
    ExternalPower = 'blue',
    Unknown = 'darkGrey',
}


enum deviceSignalPowerColor {
    Excellent = 'green',
    Good = 'yellow',
    Fair = 'darkOrange',
    Poor = 'red',
    Unknown = 'darkGrey',
}

const statusLegend = (translate: any): [string, string][] => {
    return ([
        [deviceStatusColor.Online, translate('legend.online')],
        [deviceStatusColor.Offline, translate('legend.offline')]
    ]);
};

const signalPowerLegend = (translate: any): [string, string][] => {
    return ([
        [deviceSignalPowerColor.Excellent, translate('legend.excellent')],
        [deviceSignalPowerColor.Good, translate('legend.good')],
        [deviceSignalPowerColor.Fair, translate('legend.fair')],
        [deviceSignalPowerColor.Poor, translate('legend.poor')],
        [deviceSignalPowerColor.Unknown, translate('legend.unknown')],
    ]);
};

const batteryLegend = (translate: any): [string, string][] => {
    return ([
        [deviceBatteryColor.LessThan5, '< 5%'],
        [deviceBatteryColor.LessThan10, '< 10%'],
        [deviceBatteryColor.LessThan20, '< 20%'],
        [deviceBatteryColor.TwentyToFull, '20 - 100%'],
        [deviceBatteryColor.ExternalPower, translate('legend.externalPower')],
        [deviceBatteryColor.Unknown, translate('legend.unknown')],
    ]);
};

export enum LegendView {
    Status = 'Status',
    SignalPower = 'Signal Power',
    Battery = 'Battery',
};

export const getLegendView = (legendView: LegendView, translate: any) => {
    if (legendView === LegendView.SignalPower) {
        return signalPowerLegend(translate);
    } else if (legendView === LegendView.Battery) {
        return batteryLegend(translate);
    } else {
        return statusLegend(translate);
    }
};

export function getEstimatedSignalPower(rssi: number, snr: number) {
    if (rssi == undefined) return undefined;
    return rssi - 10 * Math.log10(1 + Math.pow(10, -snr / 10));
}

export const getPinColor = (deviceInfo: DeviceInfo, legendView: LegendView) => {
    {
        if (legendView === LegendView.SignalPower) {
            const signalPower = getEstimatedSignalPower(deviceInfo.rssi, deviceInfo.snr);
            if (!signalPower || !isFinite(signalPower)) {
                return deviceSignalPowerColor.Unknown;
            } else if (signalPower < -112) {
                return deviceSignalPowerColor.Poor;
            } else if (signalPower <= -107) {
                return deviceSignalPowerColor.Fair;
            } else if (signalPower <= -101) {
                return deviceSignalPowerColor.Good;
            } else {
                return deviceSignalPowerColor.Excellent;
            }

        } else if (legendView === LegendView.Battery) {
            if (deviceInfo.hasBattery) {
                if (!deviceInfo.batteryPercentage) {
                    return deviceBatteryColor.Unknown;
                } else if (deviceInfo.batteryPercentage < 5) {
                    return deviceBatteryColor.LessThan5;
                } else if (deviceInfo.batteryPercentage < 10) {
                    return deviceBatteryColor.LessThan10;
                } else if (deviceInfo.batteryPercentage < 20) {
                    return deviceBatteryColor.LessThan20;
                } else {
                    return deviceBatteryColor.TwentyToFull;
                }
            } else {
                return deviceBatteryColor.ExternalPower;
            }
        } else {
            return deviceInfo.online ? deviceStatusColor.Online : deviceStatusColor.Offline;
        }
    }
};