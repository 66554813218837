import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { BooleanField, FunctionField, ImageField, NumberField, ReferenceField, Show, Tab, TabbedShowLayout, TextField, useTranslate } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { HexColorInput } from '../../reusable-components/color-picker/HexInput';
import ResourceTitle from '../../reusable-components/ResourceTitle';

const title = <ResourceTitle translationKey='platformSettings' />;

const MaterialColorsInput = ({ title, value, onChange }: any) => {
	const translate = useTranslate();

	const onChangeProp = (propName: string, propValue: any) => {
		if (!onChange) return;
		onChange({
			...value,
			[propName]: propValue
		});
	};

	return (
		<div>
			<HexColorInput label={translate('material.primaryColor')} value={value.primaryColor} onChange={color => onChangeProp('primaryColor', color)} />
			<HexColorInput label={translate('material.secondaryColor')} value={value.secondaryColor} onChange={color => onChangeProp('secondaryColor', color)} />
			<HexColorInput label={translate('material.warningColor')} value={value.warningColor} onChange={color => onChangeProp('warningColor', color)} />
			<HexColorInput label={translate('material.errorColor')} value={value.errorrColor} onChange={color => onChangeProp('errorrColor', color)} />
		</div>
	);

};

const useStyles = makeStyles((theme: Theme) => {
	const dark = theme.palette.type === 'dark';
	return {
		imageField: {
			backgroundColor: dark ? null : 'grey',
		},
		title: {
			// marginBottom: 30,
			marginRight: '150px'
		},
		colors: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'space-between',
		},
	};
});

export const PlatformSettingsShow = (props: any) => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<Show {...props} title={title}>
			<TabbedShowLayout>
				<Tab label='platform.generalSettings'>
					<TextField label='platform.name' source='name' />
					<ReferenceField label='platform.defaultLanguage' source='defaultLanguageId' reference={ApiRoutes.TranslationLanguage} link={null}>
						<TextField source='id' />
					</ReferenceField>
					<ImageField source="loginImage" label='platform.loginImage' classes={{ image: classes.imageField }} />
					<ImageField source="headerImage" label='platform.headerImage' classes={{ image: classes.imageField }} />
					<ImageField source="favicon" label='platform.favicon' classes={{ image: classes.imageField }} />
					<FunctionField
						label='general.defaultTheme'
						render={(record: any) => <>{record.themeSettings.defaultDark ? translate('theme.dark') : translate('theme.light')}</>}
					/>
					<FunctionField
						label='‎'
						render={(record: any) => (
							<div className={classes.colors} style={{ paddingBottom: '8px' }}>
								<div>
									<div className={classes.title}>{translate('material.lightColors')}</div>
									<MaterialColorsInput value={record.themeSettings.lightColors} />
								</div>
								<div>
									<div className={classes.title}>{translate('material.darkColors')}</div>
									<MaterialColorsInput value={record.themeSettings.darkColors} />
								</div>
							</div>
						)}
					/>
				</Tab>
				<Tab label='platform.smtpSettings'>
					<TextField label="smtp.host" source='smtpSettings.host' />
					<NumberField label="smtp.port" source='smtpSettings.port' />
					<BooleanField label="smtp.secure" source='smtpSettings.secure' />
					<TextField label="ra.auth.username" source='smtpSettings.username' />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};
