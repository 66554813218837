import { Theme, withTheme } from '@material-ui/core';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { changeLeafletMapStyle, ColorLabelLegend, createLegend, createTooltip, getPinIcon, initializeLeafletMap } from './leaflet-helper';
import { Pin } from './types';

interface PinsProps {
	pins: Pin | Pin[];
	labelRenderer?: (pin: Pin) => JSX.Element;
	theme?: Theme;
	/**
	 * Event listener for pin click.
	 * Parameter pin is the same object passed in to React component.
	 */
	onPinClick?: (pin: Pin) => void;
	width?: number | string;
	height?: number | string;
	legendColorLabels?: [string, string][];
	autoFocus?: boolean;
	zoomLvl?: number;
}

/**
 * TODO: secure access tokens!
 * TODO: refactor as functional component
 */
class PinsRaw extends React.Component<PinsProps, any> {
	private mapId = 'map-' + Math.random();
	private map!: L.Map;
	private layer?: L.FeatureGroup;
	private legend?: L.Control;
	private defaultPinColor = '#00AEEF';
	node: any;

	updateMap(pins: Pin | Pin[], legendColorLabels?: [string, string][]) {

		const pinsArray: Pin[] = (Array.isArray(pins) ? pins : [pins])
			.filter(filterInvalidPins);

		// reset layers
		if (this.layer) {
			this.map.removeLayer(this.layer);
		}

		this.layer = L.featureGroup().addTo(this.map);

		if (!pins) return;

		// add legend
		if (legendColorLabels) {
			if (this.legend) this.map.removeControl(this.legend);
			this.legend = createLegend('topright', <ColorLabelLegend colorsLabels={legendColorLabels} />);
			this.legend.addTo(this.map);
		}

		// map scroll disabled by default, enable on click inside map, disable on click outside of map
		this.map.scrollWheelZoom.disable();

		// set pins
		const { onPinClick } = this.props;
		pinsArray.forEach(pin => {
			if (Math.abs(pin.location.lat) > 180 || Math.abs(pin.location.lng) > 180) return;
			const marker = L.marker(pin.location, { icon: getPinIcon(pin.color ? pin.color : this.defaultPinColor) }).addTo(this.layer!);
			const labelRenderer = this.props.labelRenderer;
			if (labelRenderer) {
				marker.bindTooltip(
					createTooltip(
						labelRenderer(pin)
					),
				);
			} else if (pin.label) {
				marker.bindTooltip(pin.label);
			}
			if (pin.link) {
				// TODO: should we use router?
				marker.on('click', _ => window.location = pin.link as any);
			} else if (onPinClick) {
				marker.on('click', _ => onPinClick(pin));
			}
		});

		// small hack
		setTimeout(() => {
			if (this.layer?.getBounds().isValid() && this.props.pins instanceof Array) {
				this.map.fitBounds(this.layer.getBounds(), {padding: [50, 50]});
			}
		}, 300);
	}

	componentDidMount() {
		this.map = initializeLeafletMap(this.mapId, this.props.theme?.palette.type);
		this.updateMap(this.props.pins, this.props.legendColorLabels);
		if (!Array.isArray(this.props.pins) && filterInvalidPins(this.props.pins) && this.props.zoomLvl) {
			this.map.setZoom(this.props.zoomLvl);
			this.map.setView(this.props.pins.location, this.map.getZoom());
		}
		if (this.props.autoFocus) {
			this.map.scrollWheelZoom.enable();
		}
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e: any) => {
		if (this.node.contains(e.target)) {
			this.map.scrollWheelZoom.enable();
		} else {
			this.map.scrollWheelZoom.disable();
		}
	}

	componentDidUpdate(prevProps: PinsProps) {
		if (prevProps.pins !== this.props.pins || prevProps.legendColorLabels !== this.props.legendColorLabels) {
			this.updateMap(this.props.pins, this.props.legendColorLabels);
		}
	}

	componentWillReceiveProps(nextProps: PinsProps) {
		if (this.props.theme?.palette.type !== nextProps.theme?.palette.type && this.map) {
			changeLeafletMapStyle(this.map, nextProps.theme.palette.type);
		}
	}

	render() {
		const [width, height] = [
			this.props.width ? this.props.width : '100%',
			this.props.height ? this.props.height : 500
		];

		return (
			<div style={{ position: 'relative', width, height }}>
				<div id={this.mapId} ref={node => this.node = node} style={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					height: '100%',
					width: '100%',
				}}></div>
			</div>
		);
	}
}

export const Pins = withTheme(PinsRaw);

const filterInvalidPins = (object: unknown): boolean => {
	if (!object) return false;
	const latitude = (object as any).location?.lat as number;
	const longitude = (object as any).location?.lng as number;
	if (typeof latitude !== 'number' || typeof longitude !== 'number') return false;
	if (!Number.isFinite(latitude) || !Number.isFinite(longitude)) return false;
	if (latitude < -180 || latitude > 180) return false;
	if (longitude < -90 || longitude > 90) return false;
	return true;
};