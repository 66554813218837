import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import { BooleanField, FunctionField, Labeled, ReferenceField, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField, useDataProvider, useNotify, useTranslate } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { Pins } from '../../reusable-components/maps/Pins';
import { Pin } from '../../reusable-components/maps/types';
import { useHasRights, useIsOrg } from '../../reusable-components/permissions-hooks';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import TiaDiv from '../../reusable-components/TiaDiv';
import { AlarmDatagrid } from '../alarm/AlarmList';
import { useBlankReferenceFieldHelper } from '../helpers/blank-reference-field-helper';
import { PaginationProps } from '../helpers/paginationProps';
import { ConditionalLink } from '../helpers/resource-helper';
import { AttributesTableRaField } from './AttributesTable';
import { DeviceBatteryStatus, DeviceOnlineTag } from './device-fields';
import { DeviceShowFirstMessageTime, DeviceShowGetAvgNrOfMsgPerDay, DeviceShowLastSeenTime } from './DeviceShowAttributes';
import { DeviceShowCharts } from './DeviceShowCharts';
import { DeviceShowLatestTelemetry } from './DeviceShowLatestTelemetry';
import { DeviceShowTelemetryHistory } from './DeviceShowTelemetryHistory';
import { DeviceDownlinkMessage } from './downlink/DeviceDownlinkMessage';

const title = <ResourceTitle translationKey='device' />;

const useStyles = makeStyles({
    grid: {
        marginTop: '10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: '10px',

        '@media (maxWidth:1200px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridRowGap: '10px'
        },
    },
    column: {
        display: 'block',
        '& > *': {
            display: 'block',
            marginTop: '24px',
        },
    },
    geoMap: {
        display: 'block',
        gridColumn: 'span 2',
    },
    attributesTable: {
        marginBottom: '20px',
    },
});

export const DeviceShow = (props: any) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const notify = useNotify();
    const [pin, setPin] = useState<Pin | null>(null);
    const isOrg = useIsOrg();
    const { writeRights } = useHasRights(ApiRoutes.Device);


    // https://gitlab.com/nzagorec/tia/issues/21
    useBlankReferenceFieldHelper(ApiRoutes.Integration);
    useBlankReferenceFieldHelper(ApiRoutes.DeviceType);
    useBlankReferenceFieldHelper(ApiRoutes.Organization);

    const updateShowMap = (record: any) => {
        if (pin) return;
        const attributes: any = record.attributes;
        const { latitude, longitude } = attributes;
        if (attributes.latitude != undefined && attributes.longitude != undefined) {
            setPin({
                location: {
                    lat: latitude as number,
                    lng: longitude as number,
                },
            });
        }
    };

    const saveAttributes = (attributes: any, record: any) => {
        if (attributes) {
            dataProvider.update(
                ApiRoutes.Device,
                {
                    previousData: undefined,
                    id: record.id,
                    data: { attributes }
                }
            )
                .then(() => notify('ra.notification.updated', 'info', { smart_count: 1 }));
        }
    };

    return (

        <Show {...props} title={title}>
            <TabbedShowLayout>
                <Tab label={translate('general.general')}>
                    <TiaDiv className={classes.grid} fragment={true}>
                        <TiaDiv className={classes.column}>
                            <Labeled label={translate('general.name')}>
                                <TextField source='name' />
                            </Labeled>
                            <Labeled label={translate('resource.integration')}>
                                <ReferenceField source='integrationId' reference='integrations' link={ConditionalLink('integrations')}>
                                    <TextField source='name' />
                                </ReferenceField>
                            </Labeled>
                            <Labeled label={translate('resource.deviceType')}>
                                <ReferenceField source='deviceTypeId' reference='device-types' link={ConditionalLink('device-types')}>
                                    <TextField source='name' />
                                </ReferenceField>
                            </Labeled>
                            <Labeled label={translate('device.active')}>
                                <BooleanField source='active' />
                            </Labeled>
                            <Labeled label={translate('device.deviceKey')}>
                                <TextField source='deviceKey' />
                            </Labeled>
                            {
                                !isOrg &&
                                <Labeled label={translate('resource.organization')}>
                                    <ReferenceField source='orgId' reference='organizations' link={ConditionalLink('organizations')}>
                                        <TextField source='name' />
                                    </ReferenceField>
                                </Labeled>
                            }
                        </TiaDiv>
                        <TiaDiv className={classes.column}>
                            <Labeled label={translate('device.status')}>
                                <FunctionField
                                    render={(record: any) => <DeviceOnlineTag record={record} />}
                                />
                            </Labeled>
                            <Labeled label={translate('device.lastSeen')}>
                                <FunctionField
                                    render={(record: any) => <DeviceShowLastSeenTime lastMessageReceivedAt={record.lastMessageReceivedAt} />}
                                />
                            </Labeled>
                            <Labeled label={translate('device.avgNumMessages')}>
                                <FunctionField
                                    render={(record: any) => <DeviceShowGetAvgNrOfMsgPerDay deviceId={record.id} />}
                                />
                            </Labeled>
                            <Labeled label={translate('device.uptime')}>
                                <FunctionField
                                    render={(record: any) => <DeviceShowFirstMessageTime deviceId={record.id} />}
                                />
                            </Labeled>
                            <Labeled label={translate('device.battery')}>
                                <FunctionField
                                    render={(record: any) => <DeviceBatteryStatus record={record} />}
                                />
                            </Labeled>
                        </TiaDiv>
                        <TiaDiv className={classes.geoMap}>
                            {!pin && <FunctionField
                                addLabel={false}
                                render={(record: any) => {
                                    updateShowMap(record);
                                    return null;
                                }}
                            />
                            }
                            {pin && <Pins pins={pin} zoomLvl={15} />}
                        </TiaDiv>
                    </TiaDiv>
                    <FunctionField
                        addLabel={false}
                        render={(record: any) => (
                            <DeviceShowCharts device={record} />
                        )}
                    />
                </Tab>
                <Tab label={translate('device.attributes')}>
                    <FunctionField
                        addLabel={false}
                        render={(record: any) => (
                            <AttributesTableRaField
                                source='attributes'
                                record={record}
                                showEdit={writeRights}
                                onChange={(attributes: any) => saveAttributes(attributes, record)}
                                className={classes.attributesTable}
                            />
                        )}
                    />
                </Tab>
                <Tab label={translate('device.latestTelemetry')}>
                    <FunctionField
                        addLabel={false}
                        render={
                            (record: any) => (
                                <DeviceShowLatestTelemetry device={record} />
                            )
                        }
                    />
                </Tab>
                <Tab label={translate('device.telemetryHistory')}>
                    <DeviceShowTelemetryHistory />
                </Tab>
                <Tab label={translate('resource.alarms')}>
                    <ReferenceManyField
                        addLabel={false}
                        reference="alarms"
                        target="deviceId"
                        pagination={<PaginationProps />}
                        perPage={10}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                    >
                        <AlarmDatagrid />
                    </ReferenceManyField>
                </Tab>
                {
                    writeRights &&
                    <Tab label={translate('device.downlink')}>
                        <FunctionField addLabel={false} render={({ id }: any) => <DeviceDownlinkMessage deviceId={id} />} />
                    </Tab>
                }

            </TabbedShowLayout>
        </Show>
    );
};
