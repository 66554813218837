import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { OverviewCard } from './components/OverviewCard';

const useStyles = makeStyles((theme: Theme) => {
    return {
        sectionTitle: {
            fontSize: '1.3rem',
            fontWeight: 200,
            textTransform: 'uppercase',
            marginBlockStart: '0.83em',
            marginBlockEnd: '0.83em',
        },
    };
});

export const BasicDashboard = () => {
    const classes = useStyles();
    return (
        <div style={{color: 'white'}}>
            <div className={classes.sectionTitle}>Consumption</div>
            <OverviewCard stats={{'total':35952.46,'average':35952.46,'minimum':1784.509,'maximum':34167.951,'trend':-3.624536377277883}}/>
            <div className={classes.sectionTitle}>Alarms</div>
            <OverviewCard stats={{'total':195,'average':0.5342465753424658,'minimum':1,'maximum':12,'trend':null as any}}/>
        </div>
    );
};