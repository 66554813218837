import {default as momentType} from 'moment';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {API_URL} from "./_config";

declare let moment: typeof momentType;

export function createTranslationProvider(initialTranslations: { [key: string]: string }, platformSettings: any) {
    const {defaultLanguageId} = platformSettings;
    return polyglotI18nProvider(locale => {
        moment.locale(locale.toLowerCase());

        if (locale === defaultLanguageId) {
            return initialTranslations;
        }

        return fetch(`${API_URL}/api/multilinguality/translations/${locale}`).then(res => res.json()) as any;
    }, defaultLanguageId);
}
