import { Button } from '@material-ui/core';
import Queue from '@material-ui/icons/Queue';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import { Record } from 'ra-core';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Redirect } from 'react-router-dom';

/**
 * Copied from reac-admin CloneButton source and modified to render faster.
 */

export const TiaCloneButton: FC<Props> = ({
    basePath = '',
    label = 'ra.action.clone',
    record,
    icon = defaultIcon,
    cloneFn = defaultCloneFn,
    ...rest
}) => {
    const translate = useTranslate();
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Redirect to={
            record
                ? {
                    pathname: `${basePath}/create`,
                    search: stringify({
                        source: JSON.stringify(cloneFn(record)),
                    }),
                }
                : `${basePath}/create`
            }
        />;
    }
    
    return (
        <Button
            color="primary"
            label={label}
            size="small"
            onClick={(e: any) => {
                e.stopPropagation();
                setRedirect(true);
            }}
            {...sanitizeRestProps(rest)}
        >
            {icon}
            <span style={{marginLeft: '5px'}}>
                { translate(label) }
            </span>
        </Button>
    );
};

const defaultIcon = <Queue fontSize="small" />;

const defaultCloneFn = ({ id, ...rest }: Record) => rest;

const sanitizeRestProps = ({
    // the next 6 props are injected by Toolbar
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    saving,
    submitOnEnter,
    ...rest
}: any) => rest;

interface Props {
    cloneFn?: (record: any) => any;
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
    label?: string;
}

TiaCloneButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};