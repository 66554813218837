import React from 'react';
import { useTranslate } from 'react-admin';
import TimeSeriesChart from '../../../reusable-components/charts/TimeSeriesChart';
import { TimeResolution } from '../../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface SignalHistoryChartProps {
    label: string;
    data: { rssiHistory: any, snrHistory: any };
    locale: string;
    timeResolution?: TimeResolution;
}

const SignalHistoryChart = (props: SignalHistoryChartProps) => {
    const { label, data, timeResolution, locale } = props;
    const translate = useTranslate();
    const theme = useCustomTheme();

    return (<ChartContainer label={label}>
        {data.rssiHistory && data.snrHistory && (
            <TimeSeriesChart
                label={'signals'}
                backgroundColor={theme.settings.primaryColor}
                timeScaleUnit={timeResolution as any}
                datasets={[
                    {
                        label: 'RSSI',
                        borderColor: theme.settings.primaryColor,
                        data: data.rssiHistory
                    },
                    {
                        label: 'SNR',
                        borderColor: 'red',
                        data: data.snrHistory
                    }
                ]}
                legend={true}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default SignalHistoryChart;