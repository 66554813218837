import React from 'react';
import { useTranslate } from 'react-admin';
import BarChart from '../../../reusable-components/charts/BarChart';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface BatteriesLifeChartProps {
	label?: string;
	data: any;
	locale: string;
}

const BatteriesLifeChart = (props: BatteriesLifeChartProps) => {
	const { label, data, locale } = props;
	const translate = useTranslate();
	const theme = useCustomTheme();

	return (
		<ChartContainer label={label}>
			{data && (
				<BarChart
                    label={translate('overview.devicesCount')}
                    labels={[
                        '<5%',
                        '<10%',
                        '<15%',
                        '<20%',
                        '<25%',
                        '<50%',
                        '<75%',
                        '<100%',
                        translate('overview.batteryExternalPower'),
                        translate('overview.unknown')
                    ]}
					backgroundColor={theme.settings.primaryColor}
					data={data}
					locale={locale}
				/>
			)}
		</ChartContainer>
	);
};

export default BatteriesLifeChart;