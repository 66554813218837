import React from 'react';
import { useTranslate } from 'react-admin';
import TimeSeriesChart from '../../../reusable-components/charts/TimeSeriesChart';
import { TimeResolution } from '../../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface TelemetryHistoryChartProps {
    label: string;
    data: any;
    locale: string;
    timeResolution?: TimeResolution;
}


const TelemetryHistoryChart = (props: TelemetryHistoryChartProps) => {
    const { label, data, timeResolution, locale } = props;
    const translate = useTranslate();
    const theme = useCustomTheme();

    return (<ChartContainer>
        {data && (
            <TimeSeriesChart
                label={label}
                backgroundColor={theme.settings.primaryColor}
                timeScaleUnit={timeResolution as any}
                datasets={
                    [{'label': label,
                        data: data,
                        'borderColor': theme.settings.primaryColor,
                        backgroundColor: theme.settings.primaryColor.concat('66'),
                        'pointRadius':0,
                        fill: true,
                        'lineTension':0,
                        'borderWidth':2}]
                }
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default TelemetryHistoryChart;
