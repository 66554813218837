import React, { useState } from 'react';
import { AutocompleteInput, BooleanInput, SaveButton, Toolbar, BulkDeleteButton, RadioButtonGroupInput, Create, Datagrid, DateField, DeleteButton, Edit, EditButton, email, Filter, FormDataConsumer, FormTab, Labeled, List, ReferenceField, ReferenceInput, required, SelectArrayInput, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TabbedForm, TextField, TextInput, useLocale } from 'react-admin';
import { useForm } from 'react-final-form';
import { ApiRoutes } from '../constants';
import { getUserOrgId, getUserRoleId, useHasRights, useIsOrg } from '../reusable-components/permissions-hooks';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { useBlankReferenceFieldHelper } from './helpers/blank-reference-field-helper';
import { PaginationProps } from './helpers/paginationProps';
import { ConditionalLink, OrgField } from './helpers/resource-helper';

/*
TODO:
	* according to the docs `permissions` should be passed as props to these components but they aren't ¯\_(ツ)_/¯
	* also according to the docs `usePermissions` hook should return `permissions` but it doesn't ¯\_(ツ)_/¯
	* create issue or update package later and see is it resolved, in the meantime we will use global authProvider object
	* relevant issue: TIA-18, https://gitlab.com/nzagorec/tia/issues/18
*/

const title = <ResourceTitle translationKey='users' property='email' />;

const UserFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};

const UserList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.User);
	const isOrg = useIsOrg();

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<UserFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid>
				<TextField source='email' />
				{
					!isOrg && <OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<ReferenceField label='resource.userRole' source='userRoleId' reference='user-roles' link={ConditionalLink('user-roles')}>
					<TextField label='general.name' source='name' />
				</ReferenceField>
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};


const OrganizationSpecificRoleInput = ({ formData, ...rest }: any) => {
	const form = useForm();
	const isOrg = useIsOrg();
	const userRoleId = getUserRoleId();
	const { readRights: userRoleReadRights } = useHasRights(ApiRoutes.UserRole);
	const [orgId, setOrgId] = useState(getUserOrgId());

	useBlankReferenceFieldHelper(ApiRoutes.Organization);

	return (
		<>
			{
				!isOrg &&
				<ReferenceInput allowEmpty label='resource.organization' source='orgId' reference='organizations' {...rest}
					filterToQuery={(searchQuery: string) => searchQuery ? { name: searchQuery } : undefined}
					onChange={(orgId: string) => {
						setOrgId(orgId);
						form.change('userRoleId', null);
					}}
					perPage={10000}
				>
					<AutocompleteInput allowEmpty emptyValue={null} emptyText='/' optionText='name' {...rest} />
				</ReferenceInput>
			}
			{
				userRoleReadRights ?
					<ReferenceInput allowEmpty label='resource.userRole' source='userRoleId' reference='user-roles' {...rest}
						filterToQuery={(searchQuery: string) => searchQuery ? { name: searchQuery } : undefined}
						filter={orgId ? { 'orgId||$eq': orgId } : {}}
						validate={required()}
						perPage={10000}
					>
						<SelectInput allowEmpty emptyValue={null} emptyText='/' optionText='name' {...rest} />
					</ReferenceInput> : form.change('userRoleId', userRoleId)
			}
		</>
	);
};

const validateEmail = email();

const UserCreateToolbar = (props: any) => (
	<Toolbar {...props} title={title} >
		<SaveButton label="save" redirect="show" disabled={props.disabled}>
		</SaveButton>
	</Toolbar>
);

const UserCreate = (props: any) => {
	return (
		<Create {...props} title={title} >
			<SimpleForm toolbar={<UserCreateToolbar />}>
				<TextInput source='email' validate={[required(), validateEmail]} />
				<FormDataConsumer>
					{({ formData, ...rest }: any) => (
						<OrganizationSpecificRoleInput formData={formData} {...rest} />
					)}
				</FormDataConsumer>
				<div>
					<RadioButtonGroupInput
						source='sendActivationEmail'
						label='userTable.passwordEntryMethod'
						choices={[
							{ id: 'true', name: 'userTable.activationEmail' },
							{ id: 'false', name: 'userTable.manualPasswordEntry' },
						]}
						defaultValue={'true'}
						options={{
							labelPosition: 'right',
						}}
					/>
				</div>
				<FormDataConsumer>
					{({ formData, ...rest }: any) => (
						!formData.sendActivationEmail &&
						<TextInput
							source='password'
							label='ra.auth.password'
							validate={required()}
						/>
					)}
				</FormDataConsumer>
			</SimpleForm>
		</Create>
	);
};

const UserShow = (props: any) => {
	const locale = useLocale();
	const isOrg = useIsOrg();

	const { readRights: userRoleReadRights } = useHasRights(ApiRoutes.UserRole);

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField source='email' />
				{
					!isOrg &&
					<Labeled label='resource.organization'>
						<OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
					</Labeled>
				}
				{
					userRoleReadRights ?
						<ReferenceField label='resource.userRole' source='userRoleId' reference='user-roles' link={ConditionalLink('user-roles')}>
							<TextField source='name' />
						</ReferenceField> : ''
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
			</SimpleShowLayout>
		</Show>
	);
};

const UserEdit = (props: any) => {
	const { writeRights } = useHasRights(ApiRoutes.User);

	return (
		<Edit {...props} title={title}>
			{
				!writeRights ? (
					<SimpleForm>
						<TextInput source='email' validate={[required(), validateEmail]} />
						<TextInput label='ra.auth.password' source='password' />
					</SimpleForm>
				) : (
						<TabbedForm redirect={false}>
							<FormTab label="userTable.generalSettings">
								<FormDataConsumer>
									{({ formData, ...rest }: any) => (
										<OrganizationSpecificRoleInput formData={formData} {...rest} />
									)}
								</FormDataConsumer>
							</FormTab>
							<FormTab label="userTable.alarmNotifications">
								<SelectArrayInput label="userTable.receiveOver" source="alarmNotifications.receiveOver" choices={[
									{ id: 'email', name: 'Email' },
									//{ id: "sms", name: 'SMS' },
								]} />
								<BooleanInput label="alarm.severity.critical" source="alarmNotifications.critical" />
								<BooleanInput label="alarm.severity.major" source="alarmNotifications.major" />
								<BooleanInput label="alarm.severity.minor" source="alarmNotifications.minor" />
								<BooleanInput label="alarm.severity.info" source="alarmNotifications.info" />
							</FormTab>
						</TabbedForm>
					)
			}
		</Edit>
	);
};

export const userViews = {
	list: UserList,
	show: UserShow,
	create: UserCreate,
	edit: UserEdit
};
