import { Avatar, Button, Card, CardActions, CircularProgress, Link, TextField, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import Axios from './api/axios-client';
import QueryString from 'query-string';
import React, { useState } from 'react';
import { Notification, useLogin, useLogout, useNotify, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { authProvider } from './authProvider';
import usePlatformSettings from './resources/platform-settings/usePlatformSettings';


const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: (palette: any) => palette.background.default,
  },
  card: {
    minWidth: 400,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  lockIcon: {
    backgroundColor: (palette: any) => palette.secondary.main,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
});

const PasswordUpdateTokenQueryParamName = 'password-update-token';
const EmailQueryParamName = 'email';

const UpdatePasswordPage = ({ theme, passwordUpdateToken, email }: { theme: any, passwordUpdateToken: string, email: string }) => {
  const history = useHistory();
  const classes = useStyles(theme.palette);
  const platformSettings = usePlatformSettings();
  const translate = useTranslate();
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const logout = useLogout();

  // React.useEffect(() => {
  //   authProvider.logout();
  // }, [])

  // create new password
  const submit = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    Axios.post(
      '/api/auth/update-password',
      {
        email,
        token: passwordUpdateToken,
        password: newPassword,
      }
    )
      .then(() => {
        notify('auth.createNewPasswordSuccess', 'info', {}, false, 2500);
      })
      .catch(() => {
        notify('auth.createNewPasswordError', 'error', {}, false, 2500);
      })
      .finally(() => {
        setTimeout(() => {
          logout();
          history.push('/login');
        }, 3000);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            {
              platformSettings.loginImage
                ? <img src={platformSettings.loginImage}></img>
                : <Avatar className={classes.lockIcon}>
                  <LockIcon />
                </Avatar>
            }
          </div>
          {
            !email ? <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100px'
            }}>
              <CircularProgress size={25} thickness={2} />
            </div> :
              <form onSubmit={submit}>
                <div className={classes.form}>
                  <TextField
                    label={translate('ra.auth.username')}
                    name='email'
                    type='email'
                    value={email}
                    fullWidth
                    className={classes.input}
                    disabled
                  />
                  <TextField
                    label={translate('auth.newPassword')}
                    name='newPassword'
                    type='password'
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    fullWidth
                    className={classes.input}
                  />
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant='contained'
                    type='submit'
                    color='primary'
                    fullWidth
                    disabled={isLoading}
                  >
                    {isLoading && <CircularProgress size={25} thickness={2} />}
                    {translate('auth.createNewPassword')}
                  </Button>
                </CardActions>
              </form>
          }
          <Notification />
        </Card>
      </div>
    </ThemeProvider>
  );
};

const ResetPasswordTokenQueryParamName = 'reset-password';

const ResetPasswordPage = ({ theme }: { theme: any }) => {
  const history = useHistory();
  const classes = useStyles(theme.palette);
  const platformSettings = usePlatformSettings();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const logout= useLogout();

  // create new password
  const submit = (e: any) => {
    setIsLoading(true);
    e.preventDefault();

    Axios.post(
      '/api/auth/reset-password',
      {
        email,
      }
    )
      .then(() => {
        notify('auth.sendResetPasswordEmailSuccess', 'info', {}, false, 2500);
      })
      .catch(() => {
        notify('auth.sendResetPasswordEmailError', 'error', {}, false, 2500);
      })
      .finally(() => {
        setTimeout(() => {
          logout();
          history.push('/login');
        }, 3000);
      });
  };

  const [email, setEmail] = React.useState('');

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            {
              platformSettings.loginImage
                ? <img src={platformSettings.loginImage}></img>
                : <Avatar className={classes.lockIcon}>
                  <LockIcon />
                </Avatar>
            }
          </div>
          <Typography>
            <p style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px'
            }}>
              {translate('auth.resetPassword')}
            </p>
          </Typography>
          <form onSubmit={submit}>
            <div className={classes.form}>
              <TextField
                label={translate('auth.email')}
                name='email'
                type='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                autoFocus
                className={classes.input}
              />
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                fullWidth
                disabled={isLoading}
              >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('auth.sendResetPasswordEmail')}
              </Button>
            </CardActions>
            <Typography>
              <Link href='/public#/login' style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}>
                {translate('auth.backToLogin')}
              </Link>
            </Typography>
          </form>
          <Notification />
        </Card>
      </div>
    </ThemeProvider>
  );
};

const LoginPage = ({ theme }: any) => {
  const history = useHistory();
  const queryParams = QueryString.parse(history.location.search);

  const classes = useStyles(theme.palette);
  const platformSettings = usePlatformSettings();
  const translate = useTranslate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    login({ email, password })
      .catch(() => notify('login.invalidEmailOrPassword', 'error'))
      .then(() => setIsLoading(false));
  };

  React.useEffect(() => {
    const loggedIn = !!authProvider.getAccessToken();
    if (loggedIn) history.push('/');
  }, []);

  // react admin public routing hack... these query params should be strings
  const tokenQ = queryParams[PasswordUpdateTokenQueryParamName] as string;
  const emailQ = queryParams[EmailQueryParamName] as string;
  if (tokenQ && emailQ) {
    return <UpdatePasswordPage theme={theme} passwordUpdateToken={tokenQ} email={emailQ} />;
  }
  if (ResetPasswordTokenQueryParamName in queryParams) {
    return <ResetPasswordPage theme={theme} />;
  }


  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            {
              platformSettings.loginImage
                ? <img src={platformSettings.loginImage}></img>
                : <Avatar className={classes.lockIcon}>
                  <LockIcon />
                </Avatar>
            }
          </div>
          <form onSubmit={submit}>
            <div className={classes.form}>
              <TextField
                label={translate('ra.auth.username')}
                name='email'
                type='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                autoFocus
                className={classes.input}
              />
              <TextField
                label={translate('ra.auth.password')}
                name='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
                className={classes.input}
              />
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                fullWidth
                disabled={isLoading}
              >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('ra.auth.sign_in')}
              </Button>
            </CardActions>
            <Typography>
              <Link href={`/#/login?${ResetPasswordTokenQueryParamName}`} style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}>
                {translate('auth.resetPassword')}
              </Link>
            </Typography>
          </form>
          <Notification />
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;
