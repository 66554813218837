import React from 'react';
import { BooleanField, DateField, FunctionField, ReferenceField, TextField, useLocale } from 'react-admin';
import { ApiRoutes } from '../../constants';
import { DeviceBatteryStatus, DeviceOnlineTag } from '../../resources/device/device-fields';
import { ConditionalLink } from '../../resources/helpers/resource-helper';
import { createExporter, ExporterJoin, ExporterMapperItem } from '../../reusable-components/export/exporter';
import { ExportColumns, getSmartMeteringColumnLabel, SmartMeteringColumn } from './smart-metering-export-columns';

// TODO: handle telemetryLabel better because for now it must be passed to every function

export const SmartMeteringRaColumn = {
    'id': {
        ...ExportColumns['id'],
        source: 'id',
        cell: (props: any) => {
            return (
                <TextField {...props} source='id' />
            );
        },
    },
    'deviceKey': {
        ...ExportColumns['deviceKey'],
        source: 'deviceKey',
        cell: (props: any) => {
            return (
                <TextField {...props} source='deviceKey' />
            );
        },
    },
    'active': {
        ...ExportColumns['active'],
        source: 'active',
        cell: (props: any) => {
            return (
                <BooleanField {...props} source='active' />
            );
        },
    },
    'name': {
        ...ExportColumns['name'],
        source: 'name',
        cell: (props: any) => {
            return (
                <TextField {...props} source='name' />
            );
        },
    },
    'integration': {
        ...ExportColumns['integration'],
        source: 'integrationId',
        cell: (props: any) => {
            return (
                <ReferenceField {...props} source='integrationId' reference='integrations' link={ConditionalLink('integrations')}>
                    <TextField source='name' />
                </ReferenceField>
            );
        },
    },
    'deviceType': {
        ...ExportColumns['deviceType'],
        source: 'deviceTypeId',
        cell: (props: any) => {
            return (
                <ReferenceField {...props} source='deviceTypeId' reference='device-types' link={ConditionalLink('device-types')}>
                    <TextField source='name' />
                </ReferenceField>
            );
        },
    },
    'status': {
        ...ExportColumns['status'],
        source: 'online',
        cell: (props: any) => {
            return (
                <FunctionField
                    {...props}
                    render={(record: any) => <DeviceOnlineTag record={record} />}
                />
            );
        },
    },
    'battery': {
        ...ExportColumns['battery'],
        source: 'battery',
        cell: (props: any) => {
            return (
                <FunctionField
                    {...props}
                    render={(record: any) => <DeviceBatteryStatus record={record} />}
                />
            );
        },
    },
    'telemetryValue': {
        ...ExportColumns['telemetryValue'],
        source: 'telemetry.value',
        cell: (props: any) => {
            return (
                <TextField {...props} source='telemetry.value' />
            );
        },
    },
    'telemetryLastChange': {
        ...ExportColumns['telemetryLastChange'],
        source: 'telemetry.createdAt',
        cell: (props: any) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const locale = useLocale();
            return (
                <DateField {...props} source='telemetry.createdAt' showTime locales={locale} />
            );
        },
    },
};

const SmartMeteringAttributeCell = ({ column, ...props}: { column: string }) => {
    return (
        <TextField {...props} source={column} />
    );
};

export const SmartMeteringCell = ({column, attributeDefinitions, telemetryLabel, ...props}: { column: SmartMeteringColumn, attributeDefinitions?: any[], telemetryLabel: string, label?: string }) => {
    if (SmartMeteringRaColumn[column]) {
        const CellComponent = SmartMeteringRaColumn[column].cell;
        return <CellComponent column={column} {...props} />;
    } else {
        return <SmartMeteringAttributeCell column={column} {...props} />;
    }
};

export const createSmartMeteringExporter = (columns: SmartMeteringColumn[], telemetryLabel: string, attributeDefinitions: any[], translate: (a: string) => string) => {
    const joinSettings: ExporterJoin[] = [
        { foreignKey: 'integrationId', resource: ApiRoutes.Integration, property: 'integration' },
        { foreignKey: 'deviceTypeId', resource: ApiRoutes.DeviceType, property: 'deviceType' },
    ];
    const mapperSettings: ExporterMapperItem[] = columns
        .map((column: SmartMeteringColumn) => {
            if (column in ExportColumns) {
                return  {
                    field: ExportColumns[column].exportField,
                    name: translate(getSmartMeteringColumnLabel(column, attributeDefinitions, telemetryLabel)),
                };
            } else {
                // attributes.something
                return  {
                    field: column,
                    name: getSmartMeteringColumnLabel(column, attributeDefinitions, telemetryLabel),
                };
            }
        });
    return createExporter(joinSettings, mapperSettings);
};