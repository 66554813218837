import React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './AppBar';
import { CustomMenu } from './Menu';

export const CustomLayout = (props: any) => <Layout
	{...props}
	menu={CustomMenu}
	appBar={CustomAppBar}
/>;
