import React from 'react';
import { useCustomTheme } from '../../theme';
import BasicChart from './BasicChart';

interface PieChartProps {
    label: string;
    labels: string[];
    backgroundColor: string | string[];
    data: number[];
    locale: string;
}

const PieChart = ({
    label,
    labels,
    backgroundColor,
    data,
    locale,
}: PieChartProps) => {
    const theme = useCustomTheme();
    return (
        <BasicChart
            {...{
                type: 'pie',
                data: {
                    labels,
                    datasets: [
                        {
                            label,
                            borderColor: '#ECEEF1',
                            pointBorderColor: '#ECEEF1',
                            pointBackgroundColor: '#1E3D60',
                            pointHoverBackgroundColor: '#1E3D60',
                            pointHoverBorderColor: '#ECEEF1',
                            pointBorderWidth: 1,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 2,
                            pointRadius: 5,
                            fill: true,
                            backgroundColor,
                            borderWidth: 2,
                            data
                        }
                    ]
                },
                options: {
                    legend: {
                        display: true,
                        labels: {
                            fontColor: theme.palette.text.primary
                        }
                },
                    tooltips: {
                        backgroundColor: '#fff',
                        titleFontColor: '#333',
                        bodyFontColor: '#666',
                        bodySpacing: 4,
                        xPadding: 12,
                        mode: 'nearest',
                        intersect: false,
                        position: 'nearest'
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    }
                },
                locale
            }}
        />
    );
};

export default PieChart;