import React, { useEffect, useReducer, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ApiRoutes } from '../../constants';

// TODO: better types, maybe shorter property names
// TODO: dedupe attributes with same name

const reducer = (prevState: any, action: { type: string, value: any }) => {
	if (action.type === 'DeviceTypeId') {
		return {
			...prevState,
			deviceTypeId: action.value,
		};
	} else if (action.type === 'AttributeSetDefinitionId') {
		return {
			...prevState,
			attributeSetDefinitionId: action.value,
		};
	} else if (action.type === 'DeviceTypeDefinitions') {
		return {
			...prevState,
			deviceTypeDefinitions: action.value,
		};
	} else if (action.type === 'AttributeSetDefinitions') {
		return {
			...prevState,
			attributeSetDefinitions: action.value,
		};
	}
	throw new Error('Unsupported action type');
};

const useAttributeDefinitionsReducer = (): [any[], React.Dispatch<{ type: 'DeviceTypeId' | 'AttributeSetDefinitionId', value: number }>] => {
	const [state, dispatch] = useReducer(reducer, {});
	const dataProvider = useDataProvider();
	const [attributeDefinitions, setAttributeDefinitions] = useState<any[]>([]);

	useEffect(() => {
		if (!state.deviceTypeId) return;
		dataProvider.getOne(ApiRoutes.DeviceType, { id: state.deviceTypeId })
			.then(({ data }: { data: any }) => {
				const { attributeDefinitions } = data;
				attributeDefinitions
					.forEach((definition: any) => definition.mandatory = true);
				dispatch({ type: 'DeviceTypeDefinitions', value: attributeDefinitions });
			});
	}, [state.deviceTypeId]);

	useEffect(() => {
		if (state.attributeSetDefinitionId === null) {
			dispatch({ type: 'AttributeSetDefinitions', value: [] });
		}
		if (!state.attributeSetDefinitionId) return;
		dataProvider.getOne(ApiRoutes.AttributeSets, { id: state.attributeSetDefinitionId })
			.then(({ data }: { data: any }) => {
				dispatch({ type: 'AttributeSetDefinitions', value: data.attributeDefinitions });
			});
	}, [state.attributeSetDefinitionId]);

	useEffect(() => {
		setAttributeDefinitions([
			...(state.deviceTypeDefinitions || []),
			...(state.attributeSetDefinitions || []),
		]);
	}, [state.deviceTypeDefinitions, state.attributeSetDefinitions]);

	return [attributeDefinitions, dispatch];
};

export default useAttributeDefinitionsReducer;