import { makeStyles, Theme } from '@material-ui/core';

const useOverviewStyles = makeStyles((
	(theme: Theme) => {
		return {
			'@global': {
				'.map-info': {
					padding: '0.375em 0.5em',
					fontSize: '1em',
					background: 'rgba(255,255,255,0.8)',
					boxShadow: '0 0 0.938em rgba(0,0,0,0.2)',
					borderRadius: '0.313em'
				},

				'.map-info h4': {
					margin: '0 0 0.313em',
					color: '#777'
				},

				'.map-legend': {
					lineHeight: '1.5em',
					color: '#555'
				},

				'.map-legend i': {
					width: '1.125em',
					height: '1.125em',
					float: 'left',
					marginRight: '0.5em',
					opacity: '0.7'
				}
			},

			container: {
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				gridColumnGap: '1.25em',
				gridRowGap: '1.25em',

				'@media (max-width:60em)': {
					gridTemplateColumns: 'repeat(1, 1fr)',
					padding: '1.25em'
				}
			},

			cardContent: {
				paddingTop: '0em',
				paddingBottom: '0em'
			},

			gridItemTitle: {
				paddingTop: '0.5em',
				fontWeight: '300',
				fontSize: '1.2em',
				textTransform: 'uppercase'
			},

			gridItemMapTitle: {
				paddingTop: '0.2em',
				fontWeight: '300',
				fontSize: '1.2em',
				textTransform: 'uppercase'
			},

			fetchDate: {
				paddingTop: '1.2em',
			},

			cardTitle: {
				fontWeight: 200,
				fontSize: '1.2em',
				textTransform: 'uppercase'
			},

			cardTitleRight: {},

			cardTitleSecondaryContainer: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between'
			},

			cardTitleSecondary: {
				fontSize: '1.3rem',
				fontWeight: 200,
				textTransform: 'uppercase'
			},

			overviewCard: {
				gridColumnStart: 'span 2'
			},

			overviewCardTitle: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				color: theme.palette.text.primary,

				'&>h2': {
					fontWeight: 400,
					margin: 0,
				}
			},

			graphGrid: {
				paddingTop: '1em',
				display: 'grid',
				gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
				gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
				gridTemplateAreas: '"alarmSeverity alarmSeverity batteryLife batteryLife batteryLife batteryLife" "deviceTypes deviceTypes deviceTypes esp esp esp"',
				gridColumnGap: '1em',
				gridRowGap: '1em',
			},

			alarmSeverityGraph: {
				gridArea: 'alarmSeverity',
			},

			batteryLifeGraph: {
				gridArea: 'batteryLife',
			},

			deviceTypesGraph: {
				gridArea: 'deviceTypes',
			},

			espGraph: {
				gridArea: 'esp',
			},

			overviewCardList: {
				color: 'white',
				marginLeft: '-1.1em',

				'&>*': {
					boxShadow: '0.625em 0.625em 0.313em -0.375em rgba(0,0,0,0.53)',
					backgroundColor: theme.palette.secondary.main,
					padding: '1.25em 0.538em 0.25em 0.538em',
					display: 'inline-grid',
					gridTemplateColumns: 'repeat(3, auto)',
					gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
					gridTemplateAreas: '"topLeft topMidRight topMidRight" "bot bot bot"',
					gridColumnGap: '0.625em',
					gridRowGap: '0.25em',
					width: '100%',

					'&>img': {
						gridArea: 'topLeft',
						width: '3.125em',
						alignSelf: 'center',
						justifySelf: 'center',
						borderRadius: '50%',
						border: '0.25em solid white',
						padding: '0.313em',
					},

					'&>:nth-child(2)': {
						gridArea: 'topMidRight',
						textAlign: 'center',
						alignSelf: 'center',
						justifySelf: 'left',

						'&>h2': {
							margin: 0,
							padding: 0,
							fontSize: '1rem',
							fontWeight: 300,
						},
						'&>h3': {
							margin: '-0.125em 0 -0.125em 0',
							paddingTop: '0.15em',
							fontSize: '2rem',
							fontWeight: 'bold'
						}
					},

					'&>:nth-child(3)': {
						gridArea: 'bot',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
						textAlign: 'center',

						'&>*': {
							margin: 0,
							padding: 0,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',

							'&>h3': {
								margin: 0,
								padding: 0,
								fontSize: '1rem',
								fontWeight: 'bold'
							},
							'&>p': {
								margin: '0.125em 0 -0.125em 0',
								padding: 0,
								fontSize: '1rem',
								fontWeight: 300,
							}
						}
					}
				}
			},

			listItemText: {
				fontSize: '1.5em'
			},

			messagesHistoryCard: {
				gridColumnStart: 'span 2'
			},

			deviceTypesCard: {
				gridColumnStart: 'span 1'
			},

			batteriesCard: {
				gridColumnStart: 'span 1'
			},

			map: {},

			chartContainer: {
				backgroundColor: 'white',
				border: '0.063em solid #eee',
				padding: '0.625em',
				paddingTop: '1.875em',
				height: '25em'
			},

			blockLink: {
				display: 'block',
				color: 'white',
				textDecoration: 'none',
				textTransform: 'uppercase'
			},

			link: {
				color: 'white',
				textDecoration: 'none',
				textTransform: 'uppercase'
			},
		};
	}
) as any);

export default useOverviewStyles;