import { Collapse, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(
	{
		sidebarOpen: {
			paddingLeft: 25,
			transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
		},
		sidebarClosed: {
			paddingLeft: 0,
			transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
		}
	}
);

export interface MenuItemLinkProps {
	to: string;
	primaryText: string;
	leftIcon: JSX.Element;
}

interface SubMenuProps extends MenuItemLinkProps {
	items: MenuItemLinkProps[];
}

export const SubMenu = ({ to, primaryText, leftIcon, items }: SubMenuProps) => {
	const classes = useStyles();
	const currentRoute = useLocation().pathname;
	const open = [to, ...items.map(item => item.to)].some(route => currentRoute.startsWith(route));
	const sidebarOpen = useSelector((state: any) => state.admin.ui.sidebarOpen);

	return (
		<>
			<MenuItemLink
				to={to}
				primaryText={primaryText}
				leftIcon={leftIcon}
			/>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List
					component='div'
					disablePadding
					className={sidebarOpen ? classes.sidebarOpen : classes.sidebarClosed}
				>
					{
						items.map(item => {
							return <MenuItemLink key={item.primaryText} {...item}/>;
						})
					}
				</List>
			</Collapse>
		</>
	);
};
