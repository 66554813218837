import React from 'react';
import { Edit, SimpleForm, FormDataConsumer } from 'react-admin';
import ResourceTitle from '../../reusable-components/ResourceTitle';
import DeviceForm from './DeviceForm';

const title = <ResourceTitle translationKey='device' />;

export const DeviceEdit = (props: any) => {
	return (
		<Edit {...props} title={title}>
			<SimpleForm redirect='show'>

                <FormDataConsumer>
                    {(formDataProps: any) => <DeviceForm {...formDataProps} />}
                </FormDataConsumer>
                
			</SimpleForm>
		</Edit>
	);
};