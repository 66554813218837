import React, { useState, useEffect } from 'react';
import axios from '../../api/axios-client';
import Tooltip from '@material-ui/core/Tooltip';
import { default as momentType } from 'moment';

declare let moment: typeof momentType;

export const DeviceShowFirstMessageTime = ({ deviceId }: any) => {
    const [uptimeStr, setUptimeStr] = useState<string>('/');

    useEffect(() => {
        axios.get(`/api/device-stats/first-message-received-at/${deviceId}`)
            .then(({data}: any) => {
                if (!data) return setUptimeStr('/');
                const date = moment(data);
                const str = date.isValid() ? date.fromNow(true) : '/';
                setUptimeStr(str);
            });
    }, []);

    return (
    <div>{uptimeStr}</div>
    );
};

export const DeviceShowLastSeenTime = ({ lastMessageReceivedAt }: any) => {
    return (
        <Tooltip title={lastMessageReceivedAt} placement="top-start">
                <div>
                    {
                        lastMessageReceivedAt ?
                        moment(lastMessageReceivedAt).fromNow() :
                        '/'
                    }
                </div>
        </Tooltip>
    );
};

export const DeviceShowGetAvgNrOfMsgPerDay = ({ deviceId }: any) => {
    const [avgMessagePerDay, setAvgMessagePerDay] = useState<any>('/');

    useEffect(() => {
        axios.get(`/api/device-stats/avg-msgs-per-day/${deviceId}`)
            .then(({data}: any) => {
                if (!data || typeof data !== 'number') return setAvgMessagePerDay('/');
                const num = Math.round((data || 0) * 100) / 100;
                setAvgMessagePerDay(Number.isFinite(num) ? num : '/');
            });
    }, []);

    return (
    <div>{avgMessagePerDay}</div>
    );
};
