import React from 'react';
import { FunctionField, List, Create, Edit, Show, SimpleShowLayout, SimpleForm, Datagrid, Labeled, TextField, TextInput, DateField, ShowButton, EditButton, DeleteButton, BooleanInput, BooleanField, SelectInput, ReferenceInput, FormDataConsumer, ReferenceField, Filter, useLocale, BulkDeleteButton } from 'react-admin';
import { useForm } from 'react-final-form';
import ResourceTitle from '../reusable-components/ResourceTitle';
import { useCustomTheme } from '../theme';
import { JsEditorRa } from '../reusable-components/monaco-editor/JsEditor';
import { PaginationProps } from './helpers/paginationProps';
import { JsonEditorRa, JsonEditorRaField } from '../reusable-components/monaco-editor/JsonEditor';
import { activeRowStyle } from '../reusable-components/grid/rowStyle';
import { useHasRights, useIsOrg } from '../reusable-components/permissions-hooks';
import { ApiRoutes } from '../constants';
import { OrgField, ConditionalLink } from './helpers/resource-helper';
import { TextField as MuiTextField } from '@material-ui/core';

const title = <ResourceTitle translationKey='integrations' />;

const IntegrationFilter = (props: any) => {
	const isOrg = useIsOrg();
	return (
		<Filter {...props}>
			<TextInput label={'ra.action.search'} source="q" alwaysOn />
			{
				!isOrg &&
				<ReferenceInput
					source='orgId||$eq'
					label='resource.organization'
					reference='organizations'
					perPage={10000}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
			}
		</Filter>
	);
};


const IntegrationList = (props: any) => {
	const locale = useLocale();
	const { writeRights } = useHasRights(ApiRoutes.Integration);
	const isOrg = useIsOrg();

	return (
		<List
			{...props}
			pagination={<PaginationProps />}
			perPage={25}
			filters={<IntegrationFilter />}
			title={title}
			sort={{ field: 'updatedAt', order: 'DESC' }}
			bulkActionButtons={writeRights ? <BulkDeleteButton undoable={false} /> : false}
		>
			<Datagrid rowStyle={activeRowStyle()}>
				<TextField label='general.name' source='name' />
				<ReferenceField label='integration.type' source='typeId' reference='integration-types' link={ConditionalLink('integration-types')}>
					<TextField source='name' />
				</ReferenceField>
				{
					!isOrg &&
					<OrgField label='resource.organization' source='orgId' displayFn={(record: any) => record.orgId !== null} />
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />
				<ShowButton />
				{writeRights && <EditButton />}
				{writeRights && <DeleteButton undoable={false} />}
			</Datagrid>
		</List>
	);
};

// TODO: code editor should support JS, fix width of type selection, etc.
const TypeOrCustomDecoderInput = ({ formData }: any) => {
	const form = useForm();

	const typeSelection = <ReferenceInput allowEmpty label='integration.type' source='typeId' reference='integration-types'>
		<SelectInput emptyText='/' optionText='name' allowEmpty emptyValue={null} />
	</ReferenceInput>;

	if (formData.typeId) {
		form.change('decoder', null);
		return typeSelection;
	};

	return <>
		{typeSelection}
		<JsEditorRa label='integration.decoder' source='decoder' />
		<JsEditorRa label='integration.encoder' source='encoder' />
	</>;
};

const IntegrationCreate = (props: any) => {
	const isOrg = useIsOrg();

	return (
		<Create {...props} title={title}>
			<SimpleForm redirect='show'>
				<TextInput label='general.name' source='name' />

				<BooleanInput source='active' defaultValue={false} />

				{
					!isOrg &&
					<ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}

				<FormDataConsumer>
					{(formDataProps: any) => (
						<TypeOrCustomDecoderInput {...formDataProps} />
					)}
				</FormDataConsumer>

				<JsonEditorRa label='integration.uplinkSettings' source='uplinkSettings' />
				<JsonEditorRa label='integration.downlinkSettings' source='downlinkSettings' />
				<JsonEditorRa label='integration.autoImportSettings' source='autoImportSettings' />
			</SimpleForm>
		</Create>
	);
};

export const IntegrationShow = (props: any) => {
	const locale = useLocale();
	const isOrg = useIsOrg();

	return (
		<Show {...props} title={title}>
			<SimpleShowLayout>
				<TextField label='general.name' source='name' />
				<BooleanField label='integration.active' source='active' />
				<ReferenceField label='integration.type' source='typeId' reference='integration-types' link={ConditionalLink('integration-types')}>
					<TextField label='general.name' source='name' />
				</ReferenceField>
				<TextField label='general.accessKey' source='accessKey' />
				<FunctionField
					label='integration.uplinkUrl'
					render={(record: any) => `https://${window.location.hostname}/uplink?integrationAccessKey=${record.accessKey}`}
				/>
				{
					!isOrg &&
					<Labeled label='resource.organization'>
						<OrgField label='resource.organization' displayFn={(record: any) => record.orgId !== null} />
					</Labeled>
				}
				<DateField label='general.createdAt' source='createdAt' showTime locales={locale} />
				<DateField label='general.updatedAt' source='updatedAt' showTime locales={locale} />

				<JsonEditorRaField label='integration.uplinkSettings' source='uplinkSettings' />
				<JsonEditorRaField label='integration.downlinkSettings' source='downlinkSettings' />
				<JsonEditorRaField label='integration.autoImportSettings' source='autoImportSettings' />
			</SimpleShowLayout>
		</Show>
	);
};

const IntegrationEdit = (props: any) => {
	const isOrg = useIsOrg();

	return (
		<Edit {...props} title={title}>
			<SimpleForm>
				<TextInput label='general.name' source='name' />

				<BooleanInput label='integration.active' source='active' defaultValue={false} />

				{
					!isOrg &&
					<ReferenceInput
						source='orgId'
						reference='organizations'
						label={'resource.organization'}
						perPage={10000}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				}

				<FormDataConsumer>
					{(formDataProps: any) => (
						<TypeOrCustomDecoderInput {...formDataProps} />
					)}
				</FormDataConsumer>

				<JsonEditorRa label='integration.uplinkSettings' source='uplinkSettings' />
				<JsonEditorRa label='integration.downlinkSettings' source='downlinkSettings' />
				<JsonEditorRa label='integration.autoImportSettings' source='autoImportSettings' />
			</SimpleForm>
		</Edit>
	);
};

export const integrationViews = {
	list: IntegrationList,
	create: IntegrationCreate,
	show: IntegrationShow,
	edit: IntegrationEdit
};
