import { withTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { changeLeafletMapStyle, getPinIcon, initializeLeafletMap } from './leaflet-helper';
import { GeoLocation } from './types';

L.Icon.Default.imagePath = 'assets/';
L.Icon.Default.prototype.options.iconUrl = 'marker-icon-blue.png';

interface PinInputProps {
	location?: GeoLocation;
	onLocationChange?: (location: GeoLocation) => void;
	width?: number | string;
	height?: number | string;
	theme?: Theme
}

interface PinInputState {
	location?: GeoLocation;
	map?: L.Map;
	layer?: L.FeatureGroup;
	marker?: L.Marker;
	layerAdded: boolean;
}

/**
 * TODO: secure access tokens!
 * TODO: refactor as functional component
 */
class PinInputRaw extends React.Component<PinInputProps, PinInputState> {
	private mapId = 'map-' + Math.random();
	private defaultPinColor = '#00AEEF';

	state: PinInputState = {
		location: this.props.location ? this.props.location : undefined,
		layerAdded: false
	}

	private addLayerToMap() {
		if (this.state.layerAdded) return;

		if (this.state.map && this.state.layer && !this.state.map.hasLayer(this.state.layer)) {
			this.state.layer.addTo(this.state.map);
			this.setState({
				layerAdded: true
			});
		}
	}

	private updateLocation(location?: GeoLocation) {
		if (!location?.lat && !location?.lng) return;
		this.addLayerToMap();
		this.state.marker?.setLatLng(location);
		this.state.map?.panTo(location);
	}

	componentDidMount() {
		// initialize map
		const map = initializeLeafletMap(this.mapId, this.props.theme?.palette.type).setView([51.505, -0.09], 13);
		const layer = L.featureGroup();
		const marker = L.marker([0, 0], { icon: getPinIcon(this.defaultPinColor) });
		marker.addTo(layer);

		this.setState({ map, layer, marker });

		map.on('click', e => {
			this.addLayerToMap();
			const location = (e as any).latlng as GeoLocation;
			marker.setLatLng(location);

			if (this.props.onLocationChange) this.props.onLocationChange(location);
		});

		const location = this.props.location;
		if (location)	{
			marker.setLatLng(location);
			map.panTo(location);
		}
	}

	componentWillReceiveProps(nextProps: PinInputProps) {
		if (this.props.theme?.palette.type !== nextProps.theme?.palette.type && this.state?.map) {
			// TODO: this removes all layers so it might pose a problem because layerAdded state variable might not be in sync
			changeLeafletMapStyle(this.state.map, nextProps?.theme?.palette.type);
		}
		this.updateLocation(nextProps.location);
	}

	componentDidUpdate(prevProps: PinInputProps, prevState: PinInputState) {
		// this is needed because setState is "async" and otherwise init with location already set wouldn't work
		if (!prevState.map && this.state.map) {
			this.updateLocation(this.state.location);
		}
	}

	render() {
		const [width, height] = [
			this.props.width ? this.props.width : '100%',
			this.props.height ? this.props.height : 500
		];

		return (
			<div style={{ position: 'relative', width, height }}>
				<div id={this.mapId} style={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					height: '100%',
					width: '100%',
				}}></div>
			</div>
		);
	}
}

export const PinInput = withTheme(PinInputRaw);