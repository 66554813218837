import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles, TextField } from '@material-ui/core';
import { default as momentType } from 'moment';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import { useLocale } from 'react-admin';
import DateTimePicker from 'react-datetime-picker';
import { useCustomTheme } from '../../theme';

declare let moment: typeof momentType;

const useStyles = makeStyles(theme => {
    return {
        dialog: {
            overflow: 'visible',

            '& .MuiDialog-paper': {
                overflow: 'visible',
            }
        },
        content: {
            overflow: 'visible',
        },
        dateTimePickerDark: {
            color: 'white',

            '& .react-datetime-picker__wrapper': {
                borderColor: 'white',
            },
            '& .react-datetime-picker__inputGroup__input': {
                color: 'white',
            },
            '& .react-datetime-picker__button > svg': {
                stroke: 'white',
            },
        },
    };
});

const roundToEndOfDay = (date: Date) => moment(date).endOf('day').toDate();

interface SmartMeteringDateDialogProps {
    open: boolean;
    onClose: (csvExportParams?: { endDate: Date, csvSeparator: string, decimalComaSeparator: boolean }) => void;
}

const InitialOptions = {
    separator: ';',
    decimalComaSeparator: false
};

export const SmartMeteringExportDialog = (props: SmartMeteringDateDialogProps) => {
    const { open, onClose } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const locale = useLocale();
    const theme = useCustomTheme();
    const [date, setDate] = useState<Date>(roundToEndOfDay(new Date()));
    const [options, setOptions] = useState(InitialOptions);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setOptions(InitialOptions);
            }}
            aria-labelledby='form-dialog-title'
            className={classes.dialog}
        >
            <DialogTitle id='form-dialog-title'>{translate('ra.action.export')}</DialogTitle>
            <DialogContent className={classes.content}>
                <div style={{ marginBottom: '30px' }}>
                    <DateTimePicker
                        value={date}
                        onChange={(newDate: Date) => setDate(roundToEndOfDay(newDate))}
                        clearIcon={null}
                        locale={locale}
                        format={'y-MM-dd'}
                        className={theme.settings.dark ? classes.dateTimePickerDark : ''}
                    />
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <TextField label={translate('csv.separator')} value={options.separator} onChange={x => setOptions({ ...options, separator: x.target.value })} style={{ width: '123px', marginBottom: '20px' }} />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.decimalComaSeparator}
                                onChange={e => setOptions({ ...options, decimalComaSeparator: e.target.checked })}
                            />
                        }
                        label={translate('csv.decimalComaSeparator')}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        setOptions(InitialOptions);
                    }}
                >
                    {translate('ra.action.cancel')}
                </Button>
                <Button
                    onClick={() => {
                        onClose({ endDate: date, csvSeparator: options.separator, decimalComaSeparator: options.decimalComaSeparator });
                        setOptions(InitialOptions);
                    }}
                >
                    {translate('ra.action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};