import React from 'react';
import { useTranslate } from 'react-admin';
import TimeSeriesChart from '../../../reusable-components/charts/TimeSeriesChart';
import { TimeResolution } from '../../../reusable-components/DateRangePicker';
import { useCustomTheme } from '../../../theme';
import ChartContainer from './ChartContainer';

interface AlarmsCountBySeverityHistoryChartProps {
    label: string;
    data: any;
    locale: string;
    timeResolution?: TimeResolution;
}


const AlarmsCountBySeverityHistoryChart = (props: AlarmsCountBySeverityHistoryChartProps) => {
    const { label, data, timeResolution, locale } = props;
    const translate = useTranslate();
    const theme = useCustomTheme();

    return (<ChartContainer label={label}>
        {data && (
            <TimeSeriesChart
                label={translate('alarm.alarmSeverities')}
                backgroundColor={theme.settings.primaryColor}
                timeScaleUnit={timeResolution as any}
                datasets={[
                    {
                        label: translate('alarm.severity.info'),
                        borderColor: '#7F7F7F',
                        data: data.info
                    },
                    {
                        label: translate('alarm.severity.minor'),
                        borderColor: '#53CBCB',
                        data: data.minor
                    },
                    {
                        label: translate('alarm.severity.major'),
                        borderColor: '#F9C46D',
                        data: data.major
                    },
                    {
                        label: translate('alarm.severity.critical'),
                        borderColor: '#EE7D60',
                        data: data.critical
                    }
                ]}
                legend={true}
                locale={locale}
            />
        )}
    </ChartContainer>);
};

export default AlarmsCountBySeverityHistoryChart;