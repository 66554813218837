import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryExternal from '@material-ui/icons/BatteryChargingFull';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryUnknown from '@material-ui/icons/BatteryUnknown';
import React from 'react';
import { useTranslate } from 'react-admin';

// import BatteryAlertRed from "./images/BatteryAlertRed.svg";

export const DeviceOnlineTag = (props: any) => {
    const { record } = props;
    const translate = useTranslate();
    return (
        <span
            style={{
                color: record.online ? '#009688' : '#EE7D60',
                textTransform: 'uppercase'
            }}
        >
            {record.online ? translate('device.online') : translate('device.offline')}
        </span>
    );
};

export const DeviceBatteryStatus = ({ record }: any) => {
    const hasBattery: boolean = record.deviceType?.hasBattery;
    const battery: number | null = record.batteryPercentage;

    if (!hasBattery) {
        return <BatteryExternal />;
    } else if (battery == null) {
        return <BatteryUnknown />;
    } else if (battery < 0) {
        return <img />;
    }
    
    let icon;
    if (battery <= 20) {
        icon = <Battery20Icon />;
    } else if (battery <= 30) {
        icon = <Battery30Icon />;
    } else if (battery <= 50) {
        icon = <Battery50Icon />;
    } else if (battery <= 60) {
        icon = <Battery60Icon />;
    } else if (battery <= 80) {
        icon = <Battery80Icon />;
    } else if (battery <= 90) {
        icon = <Battery90Icon />;
    } else {
        icon = <BatteryFullIcon />;
    }

    return (
        <span style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            {icon} {Math.round(battery)}%
        </span>
    );
};