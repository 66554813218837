import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React from 'react';

export const MessageType: any = ({ record }: any) => {
    const type: string | null = record.type;

    if (type == 'uplink') {
        return <ArrowUpward />;
    } else if (type == 'downlink') {
        return <ArrowDownward />;
    } else {
        return '';
    }
};