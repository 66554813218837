import React from 'react';
import { useCustomTheme } from '../../theme';
import BasicChart from './BasicChart';

interface BarChartProps {
    label: string;
    labels: string[];
    backgroundColor: string | string[];
    data: number[];
    locale: string;
}

const BarChart = ({
    label,
    labels,
    backgroundColor,
    data,
    locale,
}: BarChartProps) => {
    const theme = useCustomTheme();
    return (
        <BasicChart
            {...{
                type: 'bar',
                data: {
                    labels,
                    datasets: [
                        {
                            label,
                            fill: true,
                            backgroundColor,
                            data
                        }
                    ]
                },
                options: {
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                },
                                ticks: {
                                    display: true,
                                    autoSkip: false,
                                    padding: 0,
                                    fontColor: theme.palette.text.primary,
                                    fontStyle: 'bold'
                                }
                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                },
                                ticks: {
                                    display: false,
                                    beginAtZero: true
                                }
                            }
                        ]
                    },
                    tooltips: {
                        backgroundColor: '#333',
                        titleFontColor: '#eee',
                        bodyFontColor: '#fff',
                        bodySpacing: 4,
                        xPadding: 12,
                        mode: 'nearest',
                        intersect: false,
                        position: 'nearest'
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    }
                },
                locale
            }}
        />
    );
};

export default BarChart;