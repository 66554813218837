// TODO: same file on frontend and backend. should be extracted when shared packages are setup

export const ExportColumns = {
	'id': {
		label: 'general.id',
		exportField: 'id',
	},
	'deviceKey': {
		label: 'device.deviceKey',
		exportField: 'deviceKey',
	},
	'active': {
		label: 'device.active',
		exportField: 'active'
	},
	'name': {
		label: 'general.name',
		exportField: 'name',
	},
	'integration': {
		label: 'resource.integration',
		exportField: 'integration.name',
	},
	'deviceType': {
		label: 'resource.deviceType',
		exportField: 'deviceType.name',
	},
	'status': {
		label: 'device.status',
		exportField: 'online',
	},
	'battery': {
		label: 'device.battery',
		exportField: 'battery',
	},
	'telemetryValue': {
		label: '',
		exportField: 'telemetry.value',
	},
	'telemetryLastChange': {
		label: 'telemetry.lastChange',
		exportField: 'telemetry.createdAt',
	},
};

// TODO: this can also be a string (attributes.something), but then we have no type safety
export type SmartMeteringColumn = keyof typeof ExportColumns;

const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSmartMeteringColumnLabel = (column: SmartMeteringColumn, attributeDefinitions: any[], telemetryLabel: string) => {
	if (column === 'telemetryValue') {
		return capitalizeFirstLetter(telemetryLabel);
	} else if (ExportColumns[column]) {
		return ExportColumns[column].label;
	}

	// attribute.something
	const parts = column.split('.');
	if (parts.length > 1) {
		const attributeName: string = parts[1];
		const attributeDefinition: any | undefined = attributeDefinitions
			.find(definition => definition.name === attributeName);
		if (attributeDefinition && attributeDefinition.displayName) {
			return attributeDefinition.displayName;
		} else {
			return capitalizeFirstLetter(attributeName);
		}
	}
	return '';
};