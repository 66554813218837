import Axios from '../api/axios-client';
import React from 'react';
import { useParams } from 'react-router';
import { authProvider } from '../authProvider';
import { SmartMeteringDashboard } from './smart-metering/SmartMeteringDashboard';

export enum DashboardTypeEnum {
	Metering = 'Metering',
	Lighting = 'Lighting',
}

export const DashboardWrapper = () => {
	const { dashboardId } = useParams() as any;

	// TODO: api service which automatically inserts auth token to header
	const [dashboard, setDashboard] = React.useState<any>({});
	const jwt = authProvider.getAccessToken();
	React.useEffect(() => {
		Axios({
			method: 'get',
			url: `/api/dashboards/${dashboardId}?join=dashboardType`,
			headers: { Authorization: `Bearer ${jwt}` }
		})
			.then(({ data }: any) => {
				setDashboard(data);
			});
	}, [dashboardId]);

	return (
		<div
			style={{
				marginTop: 20
			}}
		>
			{
				dashboard?.dashboardType?.id === DashboardTypeEnum.Metering &&
					<SmartMeteringDashboard dashboard={dashboard} />
			}
		</div>
	);
};
