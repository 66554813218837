import React from 'react';
import { useTranslate } from 'react-admin';
import { Severity } from '../../overview/helper';

const getSeverityColor = (severity: Severity) => {
    if (severity === 'critical') {
        return '#EE7D60';
    } else if (severity === 'major') {
        return '#F9C46D';
    } else if (severity === 'minor') {
        return '#53CBCB';
    }
    return '#7F7F7F';
};

export const AlarmSeverityField = ({ record }: any) => {
    const severity: Severity = record.severity;
    const translate = useTranslate();

    return (
        <span
            style={{
                backgroundColor: getSeverityColor(severity),
                color: 'white',
                textTransform: 'uppercase',
                padding: '4px 8px',
                fontWeight: 'bold',
                borderRadius: '4px'
            }}
        >
            {translate(`alarm.severity.${severity}`)}
        </span>
    );
};