import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Axios from '../../api/axios-client';
import _ from 'lodash';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { authProvider } from '../../authProvider';
import { ApiRoutes } from '../../constants';
import { OverviewMapCard } from '../../overview/OverviewMapCard';
import { DeviceInfo } from '../../resources/device/useOverviewStats';
import { Pin } from '../../reusable-components/maps/types';
import { getSmartMeteringColumnLabel, SmartMeteringColumn } from './smart-metering-export-columns';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            color: theme.palette.text.primary,
        },
    };
});

const getLabelRenderer = (columns: SmartMeteringColumn[], attributeDefinitions: any[], telemetryName: string, translate: any) => (pin: Pin): JSX.Element => {
    const deviceInfo: DeviceInfo = pin.details;
    return (
        <div>
        {
            columns
                .map(column => {
                    return (
                        <div>
                            <div style={{display: 'inline-block', fontWeight: 500}}>
                                {translate(getSmartMeteringColumnLabel(column, attributeDefinitions, telemetryName))}:
                            </div>
                            <div style={{display: 'inline-block', marginLeft: '5px'}}>
                                {_.get(deviceInfo, column)}
                            </div>
                        </div>
                    );
                })
        }
        </div>
    );
};

interface SmartMeteringExportTabProps {
    dashboard: any;
}

export const SmartMeteringMapTab = (props: SmartMeteringExportTabProps) => {
    const { dashboard } = props;
    const { tooltipColumns, attributeSetId } = dashboard.settings;
    const classes = useStyles();
    const translate = useTranslate();
    const [pins, setPins] = useState<Pin[]>();
    const [attributeDefinitions, setAttributeDefinitions] = useState<any[] | undefined>();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (!attributeSetId) return;
        dataProvider.getOne(ApiRoutes.AttributeSets, { id: attributeSetId })
            .then(({ data }: any) => {
                if (data && data.attributeDefinitions) {
                    setAttributeDefinitions(data.attributeDefinitions);
                }
            });
    }, [attributeSetId]);

    const createPins = (data: any) => {
        if (!data) return;
        setPins(
            data
                .filter((deviceInfo: DeviceInfo) => deviceInfo.attributes.latitude && deviceInfo.attributes.longitude)
                .map((deviceInfo: DeviceInfo) => {
                    const { name, id, attributes } = deviceInfo;
                    const pin: Pin = {
                        label: name,
                        link: `#/devices/${id}/show`,  // TODO: use getLink
                        location: {
                            lat: attributes.latitude as number,
                            lng: attributes.longitude as number,
                        },
                        details: deviceInfo,
                    };
                    return pin;
                }),
        );
    };

    useEffect(() => {
        if (!dashboard) return;
        Axios({
            method: 'GET',
            url: `/api/dashboard-info/metering/${dashboard.id}/map`,
            headers: {
                Authorization: `Bearer ${authProvider.getAccessToken()}`
            },
        })
            .then(({ data }: any) => {
                createPins(data.data);
            });
    }, [dashboard]);

    if (!pins || !attributeDefinitions) return null;

    const { settings: { telemetryExtractionRules: { general: { telemetryName } } } } = dashboard;

    return (
        <div className={classes.container}>
            <OverviewMapCard
                pins={pins}
                elevation={6}
                labelRenderer={tooltipColumns ? getLabelRenderer(tooltipColumns, attributeDefinitions, telemetryName, translate) : undefined}
                mapHeight='calc(100vh - 255px)'
            />
        </div>
    );
};
