import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { authProvider, UserRights } from '../authProvider';
import { ApiRoutes, RouteWithTranslationKey } from '../constants';
import { useIsOrg } from '../reusable-components/permissions-hooks';
import { ReadOnlyResources } from './user-role-constants';


export enum RuleDef {
	READ = 'read',
	WRITE = 'write'
}

enum SelectType {
	SELECT_ALL,
	DESELECT_ALL
}

enum SelectionState {
	ALL_SELECTED,
	ALL_DESELECTED,
	INDETERMINED
}


export const UserRoleRightsInput = (props: any) => {
	const { input: { value, onChange } }: { input: { value: UserRights, onChange: any } } = useInput(props);
	if (!value) {
		onChange({ blacklist: false, read: {}, write: {} });
	}

	const translate = useTranslate();
	const permissions = authProvider.getPermissionsNow();
	const userReadRights = RouteWithTranslationKey.filter(({ name }: any) => (permissions.hasReadRight(name)));
	const userWriteRights = userReadRights.filter(({ name }: any) => (permissions.hasWriteRight(name)));

	const [readSelectionState, setReadSelectionState] = useState(SelectionState.ALL_DESELECTED);
	const [writeSelectionState, setWriteSelectionState] = useState(SelectionState.ALL_DESELECTED);

	const SelectDeselectAllCheckbox = (props: any) => {
		const onClickHandler = props.onChange;
		const rule = props.rule;
		return (
			<Checkbox
				checked={rule === RuleDef.READ ? readSelectionState === SelectionState.ALL_SELECTED : writeSelectionState === SelectionState.ALL_SELECTED}
				onChange={onClickHandler}
				value="false"
				indeterminate={rule === RuleDef.READ ? readSelectionState === SelectionState.INDETERMINED : writeSelectionState === SelectionState.INDETERMINED}
				color="primary"
			/>
		);
	};

	const setSelectionStateForRule = (rule: RuleDef) => {
		const ruleDefUserRights = rule === RuleDef.READ ? userReadRights : userWriteRights;
		const possibleResourcesForRule = ruleDefUserRights.length;
		let selectedCheckboxes = 0;
		ruleDefUserRights.map(({ name }: { name: ApiRoutes }) => {
			if (value[rule][name]) {
				selectedCheckboxes++;
			}
		});
		const determinedState = selectedCheckboxes === 0 ? (SelectionState.ALL_DESELECTED) : (selectedCheckboxes === possibleResourcesForRule ? SelectionState.ALL_SELECTED : SelectionState.INDETERMINED);
		if (rule === RuleDef.READ) {
			setReadSelectionState(determinedState);
		} else {
			setWriteSelectionState(determinedState);
		}
	};

	const setSelectionStates = () => {
		setSelectionStateForRule(RuleDef.READ);
		setSelectionStateForRule(RuleDef.WRITE);
	};

	const onChangeRule = (rule: RuleDef, resourceTranslationKey: string) => {
		value[rule][resourceTranslationKey] = !value[rule][resourceTranslationKey];

		if (rule === RuleDef.WRITE && value[rule][resourceTranslationKey]) {
			value[RuleDef.READ][resourceTranslationKey] = true;
		}

		if (rule === RuleDef.READ && !value[rule][resourceTranslationKey]) {
			value[RuleDef.WRITE][resourceTranslationKey] = false;
		}

		setSelectionStates();

		onChange({
			...value
		});
	};

	const massSelectionHandler = (ruleDef: RuleDef) => {
		const selectionState = ruleDef === RuleDef.READ ? readSelectionState : writeSelectionState;

		userReadRights
			.map(({ name }: { name: ApiRoutes }) => {
				value[ruleDef][name] = selectionState === SelectionState.ALL_DESELECTED ? true : false;
			});

		if (ruleDef === RuleDef.READ && readSelectionState !== SelectionState.ALL_DESELECTED && writeSelectionState !== SelectionState.ALL_DESELECTED) {
			userWriteRights
				.map(({ name }: { name: ApiRoutes }) => {
					value[RuleDef.WRITE][name] = false;
				});
		} else if (ruleDef === RuleDef.WRITE && writeSelectionState === SelectionState.ALL_DESELECTED && readSelectionState !== SelectionState.ALL_SELECTED) {
			userReadRights
				.map(({ name }: { name: ApiRoutes }) => {
					value[RuleDef.READ][name] = true;
				});
		};

		setSelectionStates();

		onChange({
			...value
		});
	};

	useEffect(() => {
		if (value) {
			setSelectionStates();
		}
	}, [value]);

	const isOrg = useIsOrg();

	return (
		<div>
			{
				!value ? '' :
					<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell key='changeMePls'>{translate('general.resource')}</TableCell>
									<TableCell key={RuleDef.READ}>
										{translate('general.read')}
										<SelectDeselectAllCheckbox rule={RuleDef.READ} onChange={() => massSelectionHandler(RuleDef.READ)} />
									</TableCell>
									<TableCell key={RuleDef.WRITE}>
										{translate('general.write')}
										<SelectDeselectAllCheckbox rule={RuleDef.WRITE} onChange={() => massSelectionHandler(RuleDef.WRITE)} />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{userReadRights
									.map(({ name, translationKey }: any) => {
										return (
											<TableRow key={name} hover={true}>
												<TableCell component="th" scope="row" key=''>
													{translate(translationKey)}
												</TableCell>
												<TableCell key={RuleDef.READ}>
													<Checkbox
														checked={!!value.read[name]}
														onChange={() => onChangeRule(RuleDef.READ, name)}
														color="primary"
													/>
												</TableCell>
												<TableCell key={RuleDef.WRITE}>
													{
														ReadOnlyResources.indexOf(name) === -1 && permissions.hasWriteRight(name) ?
															<Checkbox
																checked={!!value.write[name]}
																onChange={() => onChangeRule(RuleDef.WRITE, name)}
																color="primary"
															/> : ''
													}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
			}
		</div>
	);
};

