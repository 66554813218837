import Chart, { ChartData, ChartOptions, ChartType } from 'chart.js';
import React, { useEffect, useRef } from 'react';

export interface ChartProps {
    type: ChartType;
    data: ChartData;
    options: ChartOptions;
    locale: string;
}

const BasicChart = ({ type, data, options, locale }: any) => {
    const chartCanvasEl = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const canvas = chartCanvasEl.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                let aNewChart: any;
                let canceled = false;

                setTimeout(() => {
                    if (canceled) {
                        return;
                    }

                    aNewChart = new Chart.Chart(ctx, {
                        type,
                        data,
                        options: Object.assign({}, options, {
                            responsive: true,
                            maintainAspectRatio: false,
                            aspectRatio: 1,
                        })
                    });
                }, 250);

                return function cleanup() {
                    if (aNewChart) {
                        aNewChart.destroy();
                    } else {
                        canceled = true;
                    }
                };
            }
        }

        return undefined;
    }, [chartCanvasEl.current, type, JSON.stringify(data), JSON.stringify(options), locale]);

    return <canvas ref={chartCanvasEl} />;
};

export default BasicChart;