import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { fetchUtils } from 'react-admin';
import {API_URL} from "./_config";

export const FILTER_OR_KEY = 'filterOr';

const composeFilter = (paramsFilter: any) => {
	if (paramsFilter === '' || (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')) {
		paramsFilter = {};
	}

	const flatFilter = fetchUtils.flattenObject(paramsFilter);
	const filter = Object.keys(flatFilter)
		.filter(key => key !== FILTER_OR_KEY)
		.map(key => {
			const param = paramsFilter[key];
			const splitKey = key.split('||');
			let ops: string;
			if (typeof param === 'boolean') {
				ops = '$eq';
			} else {
				ops = splitKey[1] ? splitKey[1] : '$contL';
			}
			let field = splitKey[0];

			if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
				field = field.split(/\.(.+)/)[1];
			}
			return { field, operator: ops, value: flatFilter[key] };
		});

	return filter as any;
};

type RequestOptions = {
	method?: string;
	body?: any;
	headers?: Headers;
};

type GetListParams = {
	filter: any;
	sort: any;
	pagination: any;
};

type GetManyReferenceParams = {
	filter: any;
	sort: any;
	pagination: any;
	id: any;
	target: any;
};

const getOptions = (): RequestOptions => ({
	headers: new Headers({
		Authorization: `Bearer ${localStorage.getItem('access_token')}`,
	})
});


export default {

	getList: (resource: string, params: GetListParams): Promise<any> => {
		const options: RequestOptions = getOptions();
		const { page, perPage } = params.pagination;
		const filter = params.filter || {};

		const filterOr: boolean = filter[FILTER_OR_KEY];
		const query = RequestQueryBuilder.create(
			filterOr ?
				{
					or: composeFilter(filter),
				} :
				{
					filter: composeFilter(filter),
				}
		)
			.setLimit(perPage)
			.setPage(page)
			.sortBy(params.sort)
			.setOffset((page - 1) * perPage)
			.query();

		const url = `${API_URL}/api/${resource}?${query}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: json.data,
				total: json.total,
			}));
	},

	getOne: (resource: string, params: { id: string | number }): Promise<any> => {
		const options: RequestOptions = getOptions();

		const url = `${API_URL}/api/${resource}/${params.id}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: json,
			}));
	},

	getMany: (resource: string, params: { ids: (string | number)[] }): Promise<any> => {
		const options: RequestOptions = getOptions();
		const query = RequestQueryBuilder.create()
			.setFilter({
				field: 'id',
				operator: CondOperator.IN,
				value: `${params.ids}`,
			})
			.query();
		const url = `${API_URL}/api/${resource}?${query}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: json,
				total: json.length,
			}));
	},

	getManyReference: (resource: string, params: GetManyReferenceParams): Promise<any> => {
		const options: RequestOptions = getOptions();

		const { page, perPage } = params.pagination;
		const filter = composeFilter(params.filter || {});

		filter.push({
			field: params.target,
			operator: CondOperator.EQUALS,
			value: params.id,
		});

		const query = RequestQueryBuilder.create({
			filter
		})
			.sortBy(params.sort)
			.setLimit(perPage)
			.setOffset((page - 1) * perPage)
			.query();

		const url = `${API_URL}/api/${resource}?${query}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: json.data,
				total: json.total,
			}));
	},

	update: async (resource: string, params: { id: string | number, data: any }): Promise<any> => {
		const data = { ...params.data };

		await Promise.all(
			Object.entries(data)
				.map(async ([key, value]: [string, any]) => {
					if (value && value.rawFile) {
						data[key] = await convertFileToBase64(value.rawFile);
					}
				})
		);

		const options: RequestOptions = getOptions();
		options.method = 'PATCH';
		options.body = JSON.stringify(data);

		const url = `${API_URL}/api/${resource}/${params.id}`;


		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: json,
			}));
	},

	updateMany: (resource: string, params: { ids: (string | number)[], data: any }): Promise<any> => {
		const options: RequestOptions = getOptions();
		options.method = 'PUT';
		options.body = JSON.stringify(params.data);

		return Promise.all(
			params.ids.map(id => {
				const url = `${API_URL}/api/${resource}/${id}`;
				return fetchUtils.fetchJson(url, options);
			})
		)
			.then((responses: any[]) => ({
				data: responses.map(response => response.json),
			}));
	},

	create: (resource: string, params: { id: string | number, data: any }): Promise<any> => {
		const options: RequestOptions = getOptions();
		options.method = 'POST';
		options.body = JSON.stringify(params.data);

		const url = `${API_URL}/api/${resource}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: {
					...params.data,
					id: json.id,
				},
			}));
	},

	delete: (resource: string, params: { id: string | number }): Promise<any> => {
		const options: RequestOptions = getOptions();
		options.method = 'DELETE';

		const url = `${API_URL}/api/${resource}/${params.id}`;

		return fetchUtils.fetchJson(url, options)
			.then(({ json }: any) => ({
				data: {
					id: params.id,
				},
			}));
	},

	deleteMany: (resource: string, params: { ids: (string | number)[] }): Promise<any> => {
		const options: RequestOptions = getOptions();
		options.method = 'DELETE';

		return Promise.all(
			params.ids.map(id => {
				const url = `${API_URL}/api/${resource}/${id}`;
				return fetchUtils.fetchJson(url, options);
			})
		)
			.then((responses: any[]) => ({
				data: responses.map(response => response.json),
			}));
	},

};

function convertFileToBase64(file: Blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
}
